import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elDateIcon from './images/GigDataCard_elDateIcon_190854.png';
import img_elTimeIcon from './images/GigDataCard_elTimeIcon_1018392.png';
import img_elLocationIcon from './images/GigDataCard_elLocationIcon_715557.png';
import img_elFeeIcon from './images/GigDataCard_elFeeIcon_41743.png';
import img_elGenreIcon from './images/GigDataCard_elGenreIcon_668670.png';
import img_elTuneIcon from './images/GigDataCard_elTuneIcon_535040.png';
import img_elMagnifyIcon from './images/GigDataCard_elMagnifyIcon_881060.png';

export default class GigDataCard extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // gig_date, gig_time, gig_location, gig_fee, gig_genre

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  textInputChanged_elInputSearchField = (event) => {
    this.setState({inputSearchField: event.target.value});
  }
  
  getValue_elInputSearchField = () => {
    return this.state.inputSearchField || '';
  }
  
  render() {
    
    const style_elCard = {
      background: 'rgba(255, 255, 255, 1.000)',
      borderRadius: '30.0px',
     };
    
    const style_elInputSearchField = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      color: '#555555',
      textAlign: 'left',
      pointerEvents: 'auto',
      display: 'none',
     };
    
    const style_elSearchFieldBackground = {
      background: 'rgba(0, 0, 0, 0.100)',
      borderRadius: '25.0px',
      display: 'none',
     };
    
    const value_dateText = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.gig_date);
    
    const style_elDateText = {
      color: 'black',
      textAlign: 'left',
     };
    
    const value_locationText = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.gig_location);
    
    const style_elLocationText = {
      color: 'black',
      textAlign: 'left',
     };
    
    const value_feeText = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.gig_fee);
    
    const style_elFeeText = {
      color: 'black',
      textAlign: 'left',
     };
    
    const value_genreOrServiceText = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.gig_genre);
    
    const style_elGenreOrServiceText = {
      color: 'black',
      textAlign: 'left',
     };
    const style_elDateIcon = {
      backgroundImage: 'url('+img_elDateIcon+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elTimeIcon = {
      backgroundImage: 'url('+img_elTimeIcon+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elLocationIcon = {
      backgroundImage: 'url('+img_elLocationIcon+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elFeeIcon = {
      backgroundImage: 'url('+img_elFeeIcon+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elGenreIcon = {
      backgroundImage: 'url('+img_elGenreIcon+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elTuneIcon = {
      backgroundImage: 'url('+img_elTuneIcon+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      display: 'none',
     };
    const style_elMagnifyIcon = {
      backgroundImage: 'url('+img_elMagnifyIcon+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      display: 'none',
     };
    
    const value_time = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.gig_time);
    
    const style_elTime = {
      color: 'black',
      textAlign: 'left',
     };
    
    return (
      <div className="GigDataCard">
        <div className="foreground">
          <div className="elCard" style={style_elCard} />
          <input className="baseFont elInputSearchField" style={style_elInputSearchField} type="text" placeholder={this.context.locStrings.gigdatacard_field_207797} onChange={this.textInputChanged_elInputSearchField} value={this.getValue_elInputSearchField()}  />
          <div className="elSearchFieldBackground" style={style_elSearchFieldBackground} />
          <div className="baseFont elDateText" style={style_elDateText}>
            <div>{value_dateText}</div>
          </div>
          <div className="baseFont elLocationText" style={style_elLocationText}>
            <div>{value_locationText}</div>
          </div>
          <div className="baseFont elFeeText" style={style_elFeeText}>
            <div>{value_feeText}</div>
          </div>
          <div className="baseFont elGenreOrServiceText" style={style_elGenreOrServiceText}>
            <div>{value_genreOrServiceText}</div>
          </div>
          <div className="elDateIcon" style={style_elDateIcon} />
          <div className="elTimeIcon" style={style_elTimeIcon} />
          <div className="elLocationIcon" style={style_elLocationIcon} />
          <div className="elFeeIcon" style={style_elFeeIcon} />
          <div className="elGenreIcon" style={style_elGenreIcon} />
          <div className="elTuneIcon" style={style_elTuneIcon} />
          <div className="elMagnifyIcon" style={style_elMagnifyIcon} />
          <div className="baseFont elTime" style={style_elTime}>
            <div>{value_time}</div>
          </div>
        </div>
      </div>
    )
  }
  
}
