// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';
import datasheet_sheet5_row0_profile_pic from './images/datasheet_sheet5_row0_profile_pic.png';
import datasheet_sheet5_row0_press_photo_3 from './images/datasheet_sheet5_row0_press_photo_3.png';
import datasheet_sheet5_row0_audio_1_photo from './images/datasheet_sheet5_row0_audio_1_photo.png';
import datasheet_sheet5_row0_audio_3_photo from './images/datasheet_sheet5_row0_audio_3_photo.png';
import datasheet_sheet5_row0_press_photo_1 from './images/datasheet_sheet5_row0_press_photo_1.png';
import datasheet_sheet5_row0_audio_2_photo from './images/datasheet_sheet5_row0_audio_2_photo.png';
import datasheet_sheet5_row0_press_photo_2 from './images/datasheet_sheet5_row0_press_photo_2.png';
import datasheet_sheet5_row1_profile_pic from './images/datasheet_sheet5_row1_profile_pic.png';
import datasheet_sheet5_row1_press_photo_1 from './images/datasheet_sheet5_row1_press_photo_1.png';
import datasheet_sheet5_row1_press_photo_2 from './images/datasheet_sheet5_row1_press_photo_2.png';
import datasheet_sheet5_row2_profile_pic from './images/datasheet_sheet5_row2_profile_pic.png';
import datasheet_sheet5_row2_press_photo_3 from './images/datasheet_sheet5_row2_press_photo_3.png';
import datasheet_sheet5_row2_press_photo_1 from './images/datasheet_sheet5_row2_press_photo_1.png';
import datasheet_sheet5_row2_press_photo_2 from './images/datasheet_sheet5_row2_press_photo_2.png';
import datasheet_sheet5_row3_profile_pic from './images/datasheet_sheet5_row3_profile_pic.png';
import datasheet_sheet5_row3_press_photo_3 from './images/datasheet_sheet5_row3_press_photo_3.png';
import datasheet_sheet5_row3_press_photo_1 from './images/datasheet_sheet5_row3_press_photo_1.png';
import datasheet_sheet5_row3_press_photo_2 from './images/datasheet_sheet5_row3_press_photo_2.png';
import datasheet_sheet5_row4_profile_pic from './images/datasheet_sheet5_row4_profile_pic.png';
import datasheet_sheet5_row4_press_photo_1 from './images/datasheet_sheet5_row4_press_photo_1.png';
import datasheet_sheet5_row5_profile_pic from './images/datasheet_sheet5_row5_profile_pic.png';
import datasheet_sheet5_row5_press_photo_1 from './images/datasheet_sheet5_row5_press_photo_1.png';
import datasheet_sheet5_row6_profile_pic from './images/datasheet_sheet5_row6_profile_pic.png';
import datasheet_sheet5_row6_press_photo_1 from './images/datasheet_sheet5_row6_press_photo_1.png';
import datasheet_sheet5_row6_press_photo_2 from './images/datasheet_sheet5_row6_press_photo_2.png';
import datasheet_sheet5_row7_profile_pic from './images/datasheet_sheet5_row7_profile_pic.png';
import datasheet_sheet5_row7_press_photo_1 from './images/datasheet_sheet5_row7_press_photo_1.png';
import datasheet_sheet5_row8_profile_pic from './images/datasheet_sheet5_row8_profile_pic.png';
import datasheet_sheet5_row8_press_photo_1 from './images/datasheet_sheet5_row8_press_photo_1.png';
import datasheet_sheet5_row9_profile_pic from './images/datasheet_sheet5_row9_profile_pic.png';
import datasheet_sheet5_row9_press_photo_3 from './images/datasheet_sheet5_row9_press_photo_3.png';
import datasheet_sheet5_row9_press_photo_1 from './images/datasheet_sheet5_row9_press_photo_1.png';
import datasheet_sheet5_row9_press_photo_2 from './images/datasheet_sheet5_row9_press_photo_2.png';
import datasheet_sheet5_row10_profile_pic from './images/datasheet_sheet5_row10_profile_pic.png';
import datasheet_sheet5_row10_press_photo_1 from './images/datasheet_sheet5_row10_press_photo_1.png';
import datasheet_sheet5_row10_press_photo_2 from './images/datasheet_sheet5_row10_press_photo_2.png';
import datasheet_sheet5_row11_profile_pic from './images/datasheet_sheet5_row11_profile_pic.png';
import datasheet_sheet5_row11_press_photo_1 from './images/datasheet_sheet5_row11_press_photo_1.png';
import datasheet_sheet5_row12_profile_pic from './images/datasheet_sheet5_row12_profile_pic.png';
import datasheet_sheet5_row12_press_photo_3 from './images/datasheet_sheet5_row12_press_photo_3.png';
import datasheet_sheet5_row12_audio_1_photo from './images/datasheet_sheet5_row12_audio_1_photo.png';
import datasheet_sheet5_row12_audio_3_photo from './images/datasheet_sheet5_row12_audio_3_photo.png';
import datasheet_sheet5_row12_press_photo_1 from './images/datasheet_sheet5_row12_press_photo_1.png';
import datasheet_sheet5_row12_audio_2_photo from './images/datasheet_sheet5_row12_audio_2_photo.png';
import datasheet_sheet5_row12_press_photo_2 from './images/datasheet_sheet5_row12_press_photo_2.png';
import datasheet_sheet5_row14_profile_pic from './images/datasheet_sheet5_row14_profile_pic.png';
import datasheet_sheet5_row14_press_photo_3 from './images/datasheet_sheet5_row14_press_photo_3.png';
import datasheet_sheet5_row14_press_photo_1 from './images/datasheet_sheet5_row14_press_photo_1.png';
import datasheet_sheet5_row14_press_photo_2 from './images/datasheet_sheet5_row14_press_photo_2.png';
import datasheet_sheet5_row15_profile_pic from './images/datasheet_sheet5_row15_profile_pic.png';
import datasheet_sheet5_row15_press_photo_3 from './images/datasheet_sheet5_row15_press_photo_3.png';
import datasheet_sheet5_row15_press_photo_1 from './images/datasheet_sheet5_row15_press_photo_1.png';
import datasheet_sheet5_row15_press_photo_2 from './images/datasheet_sheet5_row15_press_photo_2.png';
import datasheet_sheet5_row16_profile_pic from './images/datasheet_sheet5_row16_profile_pic.png';
import datasheet_sheet5_row17_profile_pic from './images/datasheet_sheet5_row17_profile_pic.png';
import datasheet_sheet5_row17_press_photo_3 from './images/datasheet_sheet5_row17_press_photo_3.png';
import datasheet_sheet5_row17_press_photo_1 from './images/datasheet_sheet5_row17_press_photo_1.png';
import datasheet_sheet5_row17_press_photo_2 from './images/datasheet_sheet5_row17_press_photo_2.png';
import datasheet_sheet5_row18_profile_pic from './images/datasheet_sheet5_row18_profile_pic.png';
import datasheet_sheet5_row18_press_photo_3 from './images/datasheet_sheet5_row18_press_photo_3.png';
import datasheet_sheet5_row18_press_photo_1 from './images/datasheet_sheet5_row18_press_photo_1.png';
import datasheet_sheet5_row18_press_photo_2 from './images/datasheet_sheet5_row18_press_photo_2.png';
import datasheet_sheet5_row19_profile_pic from './images/datasheet_sheet5_row19_profile_pic.png';
import datasheet_sheet5_row19_press_photo_1 from './images/datasheet_sheet5_row19_press_photo_1.png';

export default class DataSheet_sheet5 extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Xano'.
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://facebook.com/cervustheband";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/DPzTAsbI0uWs6w0b5rin-SwcOpI/ijNreA../01G8JPADA4RMQXM6R06D23ARQP.jpeg";
    item['tag_2'] = "Doom metal";
    item['Post_Code'] = "";
    item['street'] = "Wibautstraat";
    item['id'] = "337";
    item['country'] = "";
    item['created_at'] = "1658483977618";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['audio_3'] = {"mime":"audio/mpeg","size":22541582,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":320000},"duration":563.54},"path":"/vault/0ZYxcr3W/JwPoBKNvcUxJkLPQH7YTX8RARLM/e3vf6A../Cycles.mp3","type":"audio","name":"Cycles.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/JwPoBKNvcUxJkLPQH7YTX8RARLM/e3vf6A../Cycles.mp3"};
    item['whatsapp'] = "https://wa.me/0681269734";
    item['tele'] = "0681269734";
    item['profile_pic'] = datasheet_sheet5_row0_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/NWtZTWIaPLlFRJDseKVMiOYDe6o/0pxEfQ../01G8JPAXSX2G4FW4BPWEV6SCPP.jpeg";
    item['street_number'] = "";
    item['audio_2'] = {"mime":"audio/mpeg","size":10740508,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":320000},"duration":268.41},"path":"/vault/0ZYxcr3W/OQl9MSbeojkCKL-xQ1SCqQG3zHU/noMrzA../Queen+of+Damnation.mp3","type":"audio","name":"Queen of Damnation.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/OQl9MSbeojkCKL-xQ1SCqQG3zHU/noMrzA../Queen+of+Damnation.mp3"};
    item['perform_acoustic'] = "0";
    item['band_name'] = "Cervus";
    item['type'] = "Musician/Band";
    item['website'] = "https://cervustheband.bandcamp.com";
    item['bands_bio'] = "Cervus speelt een combinatie van zware Stoner, Doom, Psych en 70’s Hardrock. De vele verschillende invloeden van de band creëren een unieke mix van beukende, slepende riffs en keiharde grooves, afgewisseld met psychedelische en melancholische passages.";
    item['press_photo_3'] = datasheet_sheet5_row0_press_photo_3;
    item['audio_1_photo'] = datasheet_sheet5_row0_audio_1_photo;
    item['audio_3_photo'] = datasheet_sheet5_row0_audio_3_photo;
    item['email'] = "cervustheband@gmail.com";
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/1nGPNua8dluSpf2EjmYENpvWw-k/TZNpxA../01G8JPB67KP783CK270KPQB38A.jpeg";
    item['Main_Genre'] = "";
    item['tag_3'] = "Grunge";
    item['Insta_followers'] = "1155";
    item['Facebook_followers'] = "701";
    item['Instagram'] = "https://instagram.com/cervustheband";
    item['audio_1'] = {"mime":"audio/mpeg","size":7911969,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":320000},"duration":197.69},"path":"/vault/0ZYxcr3W/o9RkrBQbIFvom_xFRPF_CjR4M9w/MaB6Cw../Scarecrow.mp3","type":"audio","name":"Scarecrow.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/o9RkrBQbIFvom_xFRPF_CjR4M9w/MaB6Cw../Scarecrow.mp3"};
    item['tag_1'] = "Stoner rock";
    item['press_photo_1'] = datasheet_sheet5_row0_press_photo_1;
    item['city'] = "Amsterdam";
    item['maingenreID'] = "";
    item['members'] = "6 person group";
    item['video'] = {"mime":"video/mp4","size":202626742,"meta":{"video":{"res":"1920x1080","par":false,"postHeight":1080,"codec":"h264 (High) (avc1 / 0x31637661)","dar":1.777777777777778,"fps":25,"postWidth":1920,"bitrate":16203000,"profile":"yuv420p(progressive)"},"duration":98.06999999999999,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":317000}},"path":"/vault/0ZYxcr3W/1Xr5QCi9l9SIFUuiCKJaEHemOOg/2jZtuQ../video.mp4","type":"video","name":"video.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/1Xr5QCi9l9SIFUuiCKJaEHemOOg/2jZtuQ../video.mp4"};
    item['province'] = "Noord-Holland";
    item['you_are_dj'] = "0";
    item['pressphoto3_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/-mmdzBshcWYkm_BpZUs2L-q8WSA/fKLTXQ../01G8JPBFWRKPYT0JMF85DGHKBS.jpeg";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "45";
    item['audio_2_photo'] = datasheet_sheet5_row0_audio_2_photo;
    item['Spotify_link'] = "https://open.spotify.com/artist/2xx3kEldnXaCvfWDvpiKho?si=MdmFzwi7QcWht7R1IJ3Txw&utm_source=copy-link";
    item['press_photo_2'] = datasheet_sheet5_row0_press_photo_2;
    item['30sec_video'] = {"mime":"video/mp4","size":202626742,"meta":{"video":{"res":"1920x1080","par":false,"postHeight":1080,"codec":"h264 (High) (avc1 / 0x31637661)","dar":1.777777777777778,"fps":25,"postWidth":1920,"bitrate":16203000,"profile":"yuv420p"},"duration":98.06999999999999,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":317000}},"path":"/vault/0ZYxcr3W/uKcpJzcAC4h65uWpf66QIv3-Igg/jUIr-w../video.mp4","type":"video","name":"video.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/uKcpJzcAC4h65uWpf66QIv3-Igg/jUIr-w../video.mp4"};
    item['Price_Range'] = "Negotiable";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/deathintheafternoon.band";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/5k1qPEd0tBi6KHFA0-FiK3jlmsU/1iIAsA../275681015_116914587597578_5724336329151557521_n.jpg";
    item['tag_2'] = "Garage";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "368";
    item['country'] = "";
    item['created_at'] = "1662159012033";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['audio_3'] = {"mime":"audio/mpeg","size":5894424,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":320000},"duration":147.36},"path":"/vault/0ZYxcr3W/gULnfPCCbv1I2mseFdwkys-vjPA/gt_rdg../Stiff+Drinks.mp3","type":"audio","name":"Stiff Drinks.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/gULnfPCCbv1I2mseFdwkys-vjPA/gt_rdg../Stiff+Drinks.mp3"};
    item['whatsapp'] = "https://wa.me/";
    item['tele'] = "";
    item['profile_pic'] = datasheet_sheet5_row1_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/ez9xoys9cO35VR26BdD4XjN8_2Y/1K8A7Q../WhatsApp+Image+2022-10-17+at+2.29.16+PM.jpeg";
    item['street_number'] = "";
    item['audio_2'] = {"mime":"audio/mpeg","size":3912912,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":320000},"duration":97.81999999999999},"path":"/vault/0ZYxcr3W/dxCtkSTs7d5DGieTVaDIBW9OZi0/IWMnvw../Deathbed+Sorrows.mp3","type":"audio","name":"Deathbed Sorrows.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/dxCtkSTs7d5DGieTVaDIBW9OZi0/IWMnvw../Deathbed+Sorrows.mp3"};
    item['perform_acoustic'] = "0";
    item['band_name'] = "Death In The Afternoon";
    item['type'] = "Musician/Band";
    item['website'] = "https://www.facebook.com/deathintheafternoon.band";
    item['bands_bio'] = "Collected from regions around Rotterdam, these worn out bodies have gathered themselves to create havoc and chaos. Till it’s time for their nightly cocktail break, a lovely alcohol soaked drink called DITA. As this will ignite their bodies to play some tunes that will perk up those ears and tear out those vocals screaming their frustrations to the world.\n\nThese five loudmouths reign down fast melodic punk rock combined with some form of an opinion about anything. This ragtag crew consists of Wiwa, Rendang, Chris de Verlosser, Robini Martini and Ziggy. Get ready for a guteral punch to the throat, put in your favorite new tunes... that we like to call Death In The Afternoon.";
    item['email'] = "dita.official.band@gmail.com";
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/L01-EsunZDSZ04HcpLHzGy7K26E/WyW1kQ../WhatsApp+Image+2022-10-17+at+2.31.35+PM.jpeg";
    item['Main_Genre'] = "";
    item['tag_3'] = "Skate-punk";
    item['Insta_followers'] = "87";
    item['Facebook_followers'] = "20";
    item['Instagram'] = "https://www.instagram.com/death_in_the_afternoon_band/";
    item['audio_1'] = {"mime":"audio/mpeg","size":6945732,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":320000},"duration":173.64},"path":"/vault/0ZYxcr3W/KZ0lFTFGYvt521UauxhsZl6waEY/B347iA../Self+Sedation.mp3","type":"audio","name":"Self Sedation.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/KZ0lFTFGYvt521UauxhsZl6waEY/B347iA../Self+Sedation.mp3"};
    item['tag_1'] = "Punkrock";
    item['press_photo_1'] = datasheet_sheet5_row1_press_photo_1;
    item['city'] = "Rotterdam";
    item['maingenreID'] = "";
    item['members'] = "5 person group";
    item['video'] = {"mime":"video/mp4","size":15041256,"meta":{"video":{"res":"1280x720","par":false,"postHeight":720,"codec":"h264 (High) (avc1 / 0x31637661)","dar":1.777777777777778,"fps":25,"postWidth":1280,"bitrate":3799000,"profile":"yuv420p(tv"},"duration":30.07,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":44100,"bitrate":189000}},"path":"/vault/0ZYxcr3W/3QQYbmywJSBR65wtSbP9TiGiQW4/dXyUaA../video.mp4","type":"video","name":"video.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/3QQYbmywJSBR65wtSbP9TiGiQW4/dXyUaA../video.mp4"};
    item['province'] = "Zuid-Holland";
    item['you_are_dj'] = "0";
    item['pressphoto3_url'] = "";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "";
    item['Spotify_link'] = "";
    item['press_photo_2'] = datasheet_sheet5_row1_press_photo_2;
    item['30sec_video'] = {"mime":"video/mp4","size":15041256,"meta":{"video":{"res":"1280x720","par":false,"postHeight":720,"codec":"h264 (High) (avc1 / 0x31637661)","dar":1.777777777777778,"fps":25,"postWidth":1280,"bitrate":3799000,"profile":"yuv420p(tv"},"duration":30.07,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":44100,"bitrate":189000}},"path":"/vault/0ZYxcr3W/sF3MgXvoqpPnDYmZy7htp1omkCo/ZAuJQQ../DITA.mp4","type":"video","name":"DITA.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/sF3MgXvoqpPnDYmZy7htp1omkCo/ZAuJQQ../DITA.mp4"};
    item['Price_Range'] = "Negotiable";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/heykarenofficial/";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/7PoL8-QL8sdD-CABxzky3GRAUx0/aVKXFg../01GC5757V10J4WF3C3FBPSVQ73.jpeg";
    item['tag_2'] = "Indie pop";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "370";
    item['country'] = "";
    item['created_at'] = "1662327362780";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['audio_3'] = {"mime":"application/octet-stream","size":13795375,"meta":{},"path":"/vault/0ZYxcr3W/99_gHWL4l-Z9JkID7zarvLytsuo/Ulc_GA../01GC57PYH8KGWZ55A7EN5NYFS0.mp3","type":"","name":".mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/99_gHWL4l-Z9JkID7zarvLytsuo/Ulc_GA../01GC57PYH8KGWZ55A7EN5NYFS0.mp3"};
    item['whatsapp'] = "https://wa.me/+31629008799";
    item['tele'] = "+31629008799";
    item['profile_pic'] = datasheet_sheet5_row2_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/XtHLTCkRXJOIroI_I_rkNJH_8PQ/_HURRQ../01GC57Q9EMN33SCCCXJVEDSEM9.jpeg";
    item['street_number'] = "";
    item['audio_2'] = {"mime":"application/octet-stream","size":18711201,"meta":{},"path":"/vault/0ZYxcr3W/hP3EGi7H8a1ur1elbIHiAVQLoTs/9qsXYQ../01GC57PQ5MF1K1KAAXTH4X8R2S.mp3","type":"","name":".mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/hP3EGi7H8a1ur1elbIHiAVQLoTs/9qsXYQ../01GC57PQ5MF1K1KAAXTH4X8R2S.mp3"};
    item['perform_acoustic'] = "0";
    item['band_name'] = "Hey Karen";
    item['type'] = "Musician/Band";
    item['website'] = "https://linktr.ee/heykaren";
    item['bands_bio'] = "Hey Karen is a Rotterdam-based indietronica outlet by a Polish expat, Bartosz Urban (he/they), looking to reinvent himself after moving out of his homeland. After self-releasing 4 full-length albums in Poland, it’s time to start over. It’s time to create something beautiful. He describes his music as indie synthpop with an alternative sensibility.";
    item['press_photo_3'] = datasheet_sheet5_row2_press_photo_3;
    item['email'] = "bartek@heykaren.net";
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/ubo14coHgcmsJgdqMwYT3pzYyDw/X5dpUg../01GC57QD4QKEGB3372H575FJA9.jpeg";
    item['Main_Genre'] = "";
    item['tag_3'] = "Singer/Songwriter";
    item['Insta_followers'] = "337";
    item['Facebook_followers'] = "484";
    item['Instagram'] = "https://instagram.com/hej_bartosz";
    item['audio_1'] = {"mime":"application/octet-stream","size":10493333,"meta":{},"path":"/vault/0ZYxcr3W/Xc4i729kwtjgESYA9gEchmsMfDc/P8_Adg../01GC57P9WKSRTKK52QP86ZA57V.mp3","type":"","name":".mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Xc4i729kwtjgESYA9gEchmsMfDc/P8_Adg../01GC57P9WKSRTKK52QP86ZA57V.mp3"};
    item['tag_1'] = "Electronic";
    item['press_photo_1'] = datasheet_sheet5_row2_press_photo_1;
    item['city'] = "Rotterdam";
    item['maingenreID'] = "";
    item['members'] = "I’m a solo artist";
    item['video'] = {"mime":"video/mp4","size":50504409,"meta":{"video":{"res":"1280x720","par":false,"postHeight":720,"codec":"h264 (High) (avc1 / 0x31637661)","dar":1.777777777777778,"fps":23.98,"postWidth":1280,"bitrate":1253000,"profile":"yuv420p(tv"},"duration":278.96,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":44100,"bitrate":191000}},"path":"/vault/0ZYxcr3W/CZ9MHUOvXGLjVyqp8YjQ25EsP3A/7QcHBQ../video.mp4","type":"video","name":"video.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/CZ9MHUOvXGLjVyqp8YjQ25EsP3A/7QcHBQ../video.mp4"};
    item['province'] = "Zuid-Holland";
    item['you_are_dj'] = "0";
    item['pressphoto3_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/rDnCgys3c_bs3NER5fQlMdjHXmU/7LZsOA../01GC57QGGBP3MKRJZ7F0YFFE7A.png";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "11";
    item['Spotify_link'] = "https://open.spotify.com/artist/1umvSjtIJFfEnGUGvWkwlz";
    item['press_photo_2'] = datasheet_sheet5_row2_press_photo_2;
    item['30sec_video'] = {"mime":"video/mp4","size":50504409,"meta":{"video":{"res":"1280x720","par":false,"postHeight":720,"codec":"h264 (High) (avc1 / 0x31637661)","dar":1.777777777777778,"fps":23.98,"postWidth":1280,"bitrate":1253000,"profile":"yuv420p(tv"},"duration":278.96,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":44100,"bitrate":191000}},"path":"/vault/0ZYxcr3W/Do-AyYdcCo-pDsYur1f4d-VZf34/--UI0Q../video.mp4","type":"video","name":"video.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Do-AyYdcCo-pDsYur1f4d-VZf34/--UI0Q../video.mp4"};
    item['Price_Range'] = "Negotiable";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/vonveh";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Oh7jj3RRmToTgUxdl2SbXNNXO0w/C7PtcA../01GC7AAGGAYW1JMGJY1VN81KYW.jpeg";
    item['tag_2'] = "Pop rock";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "373";
    item['country'] = "";
    item['created_at'] = "1662397985053";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['whatsapp'] = "https://wa.me/+31619935371";
    item['tele'] = "+31619935371";
    item['profile_pic'] = datasheet_sheet5_row3_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/1k_fk-WSJC0Xre5p4st83edSdRI/tejtfg../01GC7BAAAPANVA6B2JPN9X841F.jpeg";
    item['street_number'] = "";
    item['audio_2'] = {"mime":"application/octet-stream","size":9857484,"meta":{},"path":"/vault/0ZYxcr3W/EZGvONlN43Yx4Oaz8tS5ym9zYbE/eHXcGw../01GESFWT9CZZG2QWT1CQ7N96T6.mp3.mp3","type":"","name":"Friction.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/EZGvONlN43Yx4Oaz8tS5ym9zYbE/eHXcGw../01GESFWT9CZZG2QWT1CQ7N96T6.mp3.mp3"};
    item['perform_acoustic'] = "0";
    item['band_name'] = "Von Veh";
    item['type'] = "Musician/Band";
    item['website'] = "https://vonveh.bandcamp.com/";
    item['bands_bio'] = "Von Veh is An Amsterdam based Alternative Rockband and previously selfreleased two EPs: 'No Tremossos' in 2019 and 'Rubbing Makes it Worse' in 2021, which didn’t go unnoticed. 'Openly Remember' is the most streamed song by Von Veh, and is also in over 140 playlists on Spotify  including in those of Never Mind The Hype, IndieXL, Metal130DB, and Free40.  \n\nVon Veh is no longer a stranger to many radio stations (including Pinguin Radio, Alternative FM, and Indie XL) and EP 'Rubbing Makes it Worse' was declared 'Album of the Month' in 2021 by Radio Spannenburg. The release party in 2021 in Amsterdam was sold out.  \n\nAfter the release of the first single Turmoil! Turmoil! of their third EP (on the way) titled Undecided' is a taste of what is to come.  Within 2 weeks it hit the first place on IndieXL and third place in Belgium, Thistle Chart by Muziscene.\n\nTheir new song FRICTION is An uptempo rocksong. The video has a lot of panic but also a few liveshow shots. Von Veh still uses synthsounds, vocal effects And hypnotizing soundscapes. It lies on a bed of alternative rock. The guitars rub against each other along with the imperturbable drums. That, in combination with the characteristic voice of singer Mariska who gets all the space she needs. Mentioning this, Von Veh might be the love baby of The Pixies and PJ Harvey.\n\nVON VEH are:  \n\nIvan Lie-A-Ling                 Guitar \nRon Broekhuizen             Bass \nMichiel Omtzigt               Guitar \nDennis van Houten         Drums  \nMariska Lie A Ling           Vocals, SFX \n\nGigs:\nOctober 1- De Roze Tanker Amsterdam Releaseparty \nOctober 2- Q Factory Q&A Festival \nOctober 29- Women in Rock Festival Tholen";
    item['press_photo_3'] = datasheet_sheet5_row3_press_photo_3;
    item['email'] = "mliealing@icloud.com";
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/OkFQa3WYr9IXLoyJ-w0Wxsl8ZoA/I8KtvQ../01GC7B5SKT9R9E5MNSDGWEGHG3.jpeg";
    item['Main_Genre'] = "";
    item['tag_3'] = "Noise";
    item['Insta_followers'] = "489";
    item['Facebook_followers'] = "203";
    item['Instagram'] = "https://www.Instagram.com/vonveh_music";
    item['audio_1'] = {"mime":"application/octet-stream","size":11876227,"meta":{},"path":"/vault/0ZYxcr3W/OatbXeXAppS63ufhWDwnTiguFJY/otHSDQ../01GC7BBMC9X3MYC5JHPG485TRH.mp3.mp3.mp3","type":"","name":"Turmoil! Turmoil!.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/OatbXeXAppS63ufhWDwnTiguFJY/otHSDQ../01GC7BBMC9X3MYC5JHPG485TRH.mp3.mp3.mp3"};
    item['tag_1'] = "Indie rock";
    item['press_photo_1'] = datasheet_sheet5_row3_press_photo_1;
    item['city'] = "Amsterdam";
    item['maingenreID'] = "";
    item['members'] = "5 person group";
    item['you_are_dj'] = "0";
    item['province'] = "Noord-Holland";
    item['pressphoto3_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Qs5rzDno15iSHsdHje6TeqkVeXw/r9njiA../01GC7B67KR4F1TXQCYMBN0HEN7.jpeg";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "126";
    item['Spotify_link'] = "https://open.spotify.com/artist/3pBJfHcWYg5u8CPKAOpcfh?si=62_90qB3TVu6Tsu3Hp9S2g";
    item['press_photo_2'] = datasheet_sheet5_row3_press_photo_2;
    item['Price_Range'] = "Negotiable";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/eternalauroraofficial/";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/00RuF0tLgKMrjRWlrd3JQShjrMs/bPCjSw../01GC7BBD2MKYWGSBFAVEFMPTRF.jpeg";
    item['tag_2'] = "Metal";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "374";
    item['country'] = "";
    item['created_at'] = "1662398887475";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['whatsapp'] = "https://wa.me/+31652277766";
    item['tele'] = "+31652277766";
    item['profile_pic'] = datasheet_sheet5_row4_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/ETHRPukRJjsczuam1VC0CKI1qNY/UqHmjw../Eternal+Aurora+Bandfoto2.JPG";
    item['street_number'] = "";
    item['audio_2'] = {"mime":"audio/mpeg","size":5403002,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":128000},"duration":337.66},"path":"/vault/0ZYxcr3W/p8-0EKMFmRTXM7hV9n5Pxu_9_lA/2I8xGg../Divine+Darkness.mp3","type":"audio","name":"Divine Darkness.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/p8-0EKMFmRTXM7hV9n5Pxu_9_lA/2I8xGg../Divine+Darkness.mp3"};
    item['perform_acoustic'] = "0";
    item['band_name'] = "Eternal Aurora";
    item['type'] = "Musician/Band";
    item['website'] = "";
    item['bands_bio'] = "Eternal Aurora is an alternative metal band from Tilburg. Their sound is defined by a progressive edge. Lyrics are inspired by psychology. By asking questions that men do not dare to answer they try to get a better understanding of the human mind.";
    item['email'] = "eternal-aurora@hotmail.com";
    item['pressphoto2_url'] = "";
    item['Main_Genre'] = "";
    item['tag_3'] = "";
    item['Insta_followers'] = "87";
    item['Facebook_followers'] = "271";
    item['Instagram'] = "https://www.instagram.com/eternalauroraofficial/";
    item['audio_1'] = {"mime":"audio/mpeg","size":4172112,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":128000},"duration":260.73},"path":"/vault/0ZYxcr3W/N5Y_zmB1lrhAnXaEAa6XoC8S7X8/GIl75A../Spiderweb.mp3","type":"audio","name":"Spiderweb.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/N5Y_zmB1lrhAnXaEAa6XoC8S7X8/GIl75A../Spiderweb.mp3"};
    item['tag_1'] = "Prog metal";
    item['press_photo_1'] = datasheet_sheet5_row4_press_photo_1;
    item['city'] = "Tilburg";
    item['maingenreID'] = "";
    item['members'] = "";
    item['video'] = {"mime":"video/mp4","size":27925123,"meta":{"video":{"res":"1280x720","par":false,"postHeight":720,"codec":"h264 (Main) (avc1 / 0x31637661)","dar":1.777777777777778,"fps":25,"postWidth":1280,"bitrate":7112000,"profile":"yuv420p(tv"},"duration":30.04,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":317000}},"path":"/vault/0ZYxcr3W/U6T7PmqK5Z2obF0i0X2Y_C4NI8Y/ykF0Kw../Eternal+Aurora.mp4","type":"video","name":"Eternal Aurora.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/U6T7PmqK5Z2obF0i0X2Y_C4NI8Y/ykF0Kw../Eternal+Aurora.mp4"};
    item['province'] = "Noord-Brabant";
    item['you_are_dj'] = "0";
    item['pressphoto3_url'] = "";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "";
    item['Spotify_link'] = "";
    item['Price_Range'] = "";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/7BlackSides/";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/iHF7roZQmo1ZVKUbs9z2XwTl8H0/r51vJg../IMG-20220504-WA0007.jpg";
    item['tag_2'] = "Progressive";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "375";
    item['country'] = "";
    item['created_at'] = "1662399761254";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['audio_3'] = {"mime":"audio/mpeg","size":1123675,"meta":{"audio":{"codec":"mp3","freq":48000,"bitrate":128000},"duration":69.95999999999999},"path":"/vault/0ZYxcr3W/tf1FDxlvHARUZyNNLAozt6IzE30/uMgWjA../Uitgeloot.mp3","type":"audio","name":"Uitgeloot.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/tf1FDxlvHARUZyNNLAozt6IzE30/uMgWjA../Uitgeloot.mp3"};
    item['whatsapp'] = "https://wa.me/+31685890996";
    item['tele'] = "0685890996";
    item['profile_pic'] = datasheet_sheet5_row5_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/sCm9_TB8oo4fE736Dil-auq9IZg/LkGNSQ../280450947_3210607479177099_6655781163992184677_n.jpg";
    item['street_number'] = "";
    item['audio_2'] = {"mime":"audio/mpeg","size":20406774,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":320000},"duration":510.07},"path":"/vault/0ZYxcr3W/tVpFO_ZylYi2BT-zMUSY08SrdhA/BqsOMA../You%27re+lost.mp3","type":"audio","name":"You're lost.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/tVpFO_ZylYi2BT-zMUSY08SrdhA/BqsOMA../You%27re+lost.mp3"};
    item['perform_acoustic'] = "0";
    item['band_name'] = "7 Black Sides";
    item['type'] = "Musician/Band";
    item['website'] = "https://www.facebook.com/7blacksides";
    item['bands_bio'] = "7 Black sides infuses harmonic guitar riffs with myriad genres to creates a sound that's as dynamic as it can get. The sound can be firm and forceful, but also soft and gentle at other times. All of this together makes for a band that is super quirky, fun and energetic live.";
    item['email'] = "7blacksides@libero.it";
    item['pressphoto2_url'] = "";
    item['Main_Genre'] = "";
    item['tag_3'] = "";
    item['Insta_followers'] = "119";
    item['Facebook_followers'] = "442";
    item['Instagram'] = "https://instagram.com/7.black.sides";
    item['audio_1'] = {"mime":"audio/mpeg","size":10239634,"meta":{"audio":{"codec":"mp3","freq":48000,"bitrate":128000},"duration":639.96},"path":"/vault/0ZYxcr3W/iBJpe7TfUYOq4SyRDNTG0c18m-w/8dXDGw../The+Awakening+Invaders.mp3","type":"audio","name":"The Awakening Invaders.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/iBJpe7TfUYOq4SyRDNTG0c18m-w/8dXDGw../The+Awakening+Invaders.mp3"};
    item['tag_1'] = "Metal";
    item['press_photo_1'] = datasheet_sheet5_row5_press_photo_1;
    item['city'] = "Groningen";
    item['maingenreID'] = "";
    item['members'] = "4 person group";
    item['video'] = {"mime":"video/mp4","size":33721350,"meta":{"video":{"res":"1280x720","par":false,"postHeight":720,"codec":"h264 (Main) (avc1 / 0x31637661)","dar":1.777777777777778,"fps":30,"postWidth":1280,"bitrate":8638000,"profile":"yuv420p(tv"},"duration":30.1,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":317000}},"path":"/vault/0ZYxcr3W/jJAMeeTLQ_9oCm2XQRlnUqmLCqA/P8xD5Q../video.mp4","type":"video","name":"video.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/jJAMeeTLQ_9oCm2XQRlnUqmLCqA/P8xD5Q../video.mp4"};
    item['province'] = "Groningen";
    item['you_are_dj'] = "0";
    item['pressphoto3_url'] = "";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "10";
    item['Spotify_link'] = "https://open.spotify.com/artist/3QDVd7t0CW319wigX4Tn15?si=nJeTljzVRfiPUGsdzmc3fA&utm_source=copy-link";
    item['30sec_video'] = {"mime":"video/mp4","size":33721350,"meta":{"video":{"res":"1280x720","par":false,"postHeight":720,"codec":"h264 (Main) (avc1 / 0x31637661)","dar":1.777777777777778,"fps":30,"postWidth":1280,"bitrate":8638000,"profile":"yuv420p(tv"},"duration":30.1,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":317000}},"path":"/vault/0ZYxcr3W/_1fFufrsjC-Xi0tgDZNoi8NJub8/wCRzcA../Sequence+01.mp4","type":"video","name":"Sequence 01.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/_1fFufrsjC-Xi0tgDZNoi8NJub8/wCRzcA../Sequence+01.mp4"};
    item['Price_Range'] = "Negotiable";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/demonicbloodofficial";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/xosx6VpXdjmI1w-k5cJ8yLYNOEk/eSqMtw../01GD0VD89B14YSJPPXXDK44E9B.jpeg";
    item['tag_2'] = "Death metal";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "379";
    item['country'] = "";
    item['created_at'] = "1663250133962";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['audio_3'] = {"mime":"audio/mpeg","size":10198580,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":258000},"duration":315.82},"path":"/vault/0ZYxcr3W/SNj7a5CwnVoOiKQozJSvgY9sxT4/1e_6fw../The+Reaper.mp3","type":"audio","name":"The Reaper.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/SNj7a5CwnVoOiKQozJSvgY9sxT4/1e_6fw../The+Reaper.mp3"};
    item['whatsapp'] = "https://wa.me/+31653857148";
    item['tele'] = "(06) 53 85 71 48";
    item['profile_pic'] = datasheet_sheet5_row6_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/RhHEJtt2Zl1GrU6SgVxJL0YUVm0/nG6Kfg../01GD0VJDSG1DRM16PDQZ3EQJ2X.jpeg";
    item['street_number'] = "";
    item['audio_2'] = {"mime":"audio/mpeg","size":12016803,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":259000},"duration":370.08},"path":"/vault/0ZYxcr3W/Xa0Y9tLT75p9Cd8V_niNuzN8Fuo/FniXug../Living+Hell.mp3","type":"audio","name":"Living Hell.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Xa0Y9tLT75p9Cd8V_niNuzN8Fuo/FniXug../Living+Hell.mp3"};
    item['perform_acoustic'] = "0";
    item['band_name'] = "Demonic Blood";
    item['type'] = "Musician/Band";
    item['website'] = "";
    item['audio_2_genre'] = "Metal";
    item['bands_bio'] = "Demonic Blood is a 5 man formation with Reeuwijk as home base. The band members come from the areas of Woerden, Gouda and Rotterdam. The band was formed in 2016 and they only make their own work in the Symphonic Dark Metal genre. On 06-05-2022 the EP called Living Hell was released on all known online streaming services and on 17-09-2022 the physical EP Living Hell was released. Here you can listen to the band's most famous songs:\n- The Ripper\n- Living Hell\n- The Kraken\n- Set Me Free\nBand Formation:\nDrums: Alex Schuilenburg\nBass: Kevin Nieuwland\nVocals: Kim Sangers\nKeys: Michael Timmers\nGuitar: Niels Wakker";
    item['email'] = "alexanderschuilenburg@gmail.com";
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Vr7mYtEHjdtxlbAgPqsE_B3aPdE/9P2s5g../01GD0VJV3SMWA3TMJ6Z7MA3JEP.jpeg";
    item['Main_Genre'] = "";
    item['tag_3'] = "Symphonic rock";
    item['Insta_followers'] = "57";
    item['Facebook_followers'] = "87";
    item['Instagram'] = "https://instagram.com/demonicbloodofficial?igshid=YmMyMTA2M2Y=";
    item['audio_1'] = {"mime":"audio/mpeg","size":7641012,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":259000},"duration":235.57},"path":"/vault/0ZYxcr3W/c_YeAizl4W9VPz3jdqYiRVGCLH0/u2Ki1Q../Set+Me+Free.mp3","type":"audio","name":"Set Me Free.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/c_YeAizl4W9VPz3jdqYiRVGCLH0/u2Ki1Q../Set+Me+Free.mp3"};
    item['tag_1'] = "Metal";
    item['press_photo_1'] = datasheet_sheet5_row6_press_photo_1;
    item['city'] = "Reeuwijk";
    item['maingenreID'] = "";
    item['members'] = "5 person group";
    item['video'] = {"mime":"video/mp4","size":9982594,"meta":{"video":{"res":"1280x720","par":false,"postHeight":720,"codec":"h264 (Main) (avc1 / 0x31637661)","dar":1.777777777777778,"fps":30,"postWidth":1280,"bitrate":2325000,"profile":"yuv420p(tv"},"duration":30.06,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":317000}},"path":"/vault/0ZYxcr3W/bnTtSAus_VQz4WUT4J77CjXrLoc/m_5vFg../Demonic+Blood.mp4","type":"video","name":"Demonic Blood.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/bnTtSAus_VQz4WUT4J77CjXrLoc/m_5vFg../Demonic+Blood.mp4"};
    item['province'] = "Zuid-Holland";
    item['you_are_dj'] = "0";
    item['pressphoto3_url'] = "";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "37";
    item['Spotify_link'] = "https://open.spotify.com/artist/4i4NfgCtPXjT3M8X9FGAGO?si=m5FY4KRcT0CS4mL_-JUHQQ";
    item['press_photo_2'] = datasheet_sheet5_row6_press_photo_2;
    item['Price_Range'] = "€50 - €450";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/suffixnl";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/nmoJErKrA7kyat3Culuzzpib9z4/m8_FgQ../01GD2WWQB99ZEG9ASS1Z99JN15.jpeg";
    item['tag_2'] = "Nu-metal";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "380";
    item['country'] = "";
    item['created_at'] = "1663256412348";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['audio_3'] = {"mime":"audio/mpeg","size":13117447,"meta":{"video":{"res":"1400x1400","par":false,"postHeight":1400,"codec":"mjpeg (Baseline)","dar":1,"fps":0,"postWidth":1400,"bitrate":0,"profile":"yuvj420p(pc"},"duration":314.23,"audio":{"codec":"mp3","freq":44100,"bitrate":320000}},"path":"/vault/0ZYxcr3W/mGOuL4umc5TrQM1MC69H2Cr0_dU/a5xM7Q../Over+The+Edge.mp3","type":"audio","name":"Over The Edge.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/mGOuL4umc5TrQM1MC69H2Cr0_dU/a5xM7Q../Over+The+Edge.mp3"};
    item['whatsapp'] = "https://wa.me/+31654623123";
    item['tele'] = "0654623123";
    item['profile_pic'] = datasheet_sheet5_row7_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/PivmAG0X7g4QlkJTodF2oymSFYk/i--zjA../01GD3ZNEP0C789K85FW6MFE142.jpeg";
    item['street_number'] = "";
    item['audio_2'] = {"mime":"audio/mpeg","size":10258609,"meta":{"video":{"res":"1400x1400","par":false,"postHeight":1400,"codec":"mjpeg (Baseline)","dar":1,"fps":0,"postWidth":1400,"bitrate":0,"profile":"yuvj420p(pc"},"duration":242.76,"audio":{"codec":"mp3","freq":44100,"bitrate":320000}},"path":"/vault/0ZYxcr3W/hnjJlRmrOVospIUG7EtZK7iwK28/6MCxyw../Flames+That+Roar.mp3","type":"audio","name":"Flames That Roar.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/hnjJlRmrOVospIUG7EtZK7iwK28/6MCxyw../Flames+That+Roar.mp3"};
    item['perform_acoustic'] = "0";
    item['band_name'] = "SuffiX";
    item['type'] = "Musician/Band";
    item['website'] = "www.suffixband.com";
    item['bands_bio'] = "We play HouseGardenandKitchenMetal!\n\nYou can listen to our cd 'Your Turn' (2011) or 'Proof of Concept' (2014) on Spotify!\n\nNovember 2022 we will be releasing or new cd 'Cage of Infinity'!";
    item['email'] = "robinvanderzon@hotmail.com";
    item['pressphoto2_url'] = "";
    item['Main_Genre'] = "";
    item['tag_3'] = "Metalcore";
    item['Insta_followers'] = "132";
    item['Facebook_followers'] = "382";
    item['Instagram'] = "https://www.instagram.com/__suffix__";
    item['audio_1'] = {"mime":"audio/mpeg","size":13341056,"meta":{"video":{"res":"1400x1400","par":false,"postHeight":1400,"codec":"mjpeg (Baseline)","dar":1,"fps":0,"postWidth":1400,"bitrate":0,"profile":"yuvj420p(pc"},"duration":319.82,"audio":{"codec":"mp3","freq":44100,"bitrate":320000}},"path":"/vault/0ZYxcr3W/7Q5UNzJcb0aI_vqpV7yqkq4-Cn4/3cu54Q../Stella%C2%B4s+Song.mp3","type":"audio","name":"Stella´s Song.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/7Q5UNzJcb0aI_vqpV7yqkq4-Cn4/3cu54Q../Stella%C2%B4s+Song.mp3"};
    item['tag_1'] = "Metal";
    item['press_photo_1'] = datasheet_sheet5_row7_press_photo_1;
    item['city'] = "Leiden";
    item['maingenreID'] = "";
    item['members'] = "5 person group";
    item['video'] = {"mime":"video/mp4","size":21467910,"meta":{"video":{"res":"640x352","par":false,"postHeight":352,"codec":"h264 (High) (avc1 / 0x31637661)","dar":false,"fps":60,"postWidth":640,"bitrate":1788000,"profile":"yuv420p(tv"},"duration":83.7,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":256000}},"path":"/vault/0ZYxcr3W/YApJc04rgV-gqpoFKazr1Qn7Eaw/DJuaWQ../video.mp4","type":"video","name":"video.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/YApJc04rgV-gqpoFKazr1Qn7Eaw/DJuaWQ../video.mp4"};
    item['province'] = "Zuid-Holland";
    item['you_are_dj'] = "0";
    item['pressphoto3_url'] = "";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "8";
    item['Spotify_link'] = "https://open.spotify.com/artist/1vlRXhCYogItnT5J8If3jH?si=gvTpMhU4R7ezdgY1zwE9PA&utm_source=copy-link";
    item['30sec_video'] = {"mime":"video/mp4","size":21467910,"meta":{"video":{"res":"640x352","par":false,"postHeight":352,"codec":"h264 (High) (avc1 / 0x31637661)","dar":false,"fps":60,"postWidth":640,"bitrate":1788000,"profile":"yuv420p(tv"},"duration":83.7,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":256000}},"path":"/vault/0ZYxcr3W/uDPvDHTj_s4HNQ0PEojf6wxe7eE/XEMclQ../video.mp4","type":"video","name":"video.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/uDPvDHTj_s4HNQ0PEojf6wxe7eE/XEMclQ../video.mp4"};
    item['Price_Range'] = "";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/CheckmateZutphen/";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/OGnJdljgzzsbKmMMgGR_HEx0SHQ/pQ-pjA../01GD1BYFPB8SHM2WN2FTBFKM61.jpeg";
    item['tag_2'] = "Hardcore";
    item['Post_Code'] = "7201 HD";
    item['street'] = "Ijsselkade";
    item['id'] = "381";
    item['country'] = "";
    item['created_at'] = "1663271296086";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['whatsapp'] = "https://wa.me/+31648450520";
    item['tele'] = "+31648450520";
    item['profile_pic'] = datasheet_sheet5_row8_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/2fPDK9TBDodISGpZKOJph2nMnbk/SoQ-iA../01GD1CQA6F1PYZW5ZMAADHE8T8.png";
    item['street_number'] = "65";
    item['perform_acoustic'] = "0";
    item['band_name'] = "Checkmate";
    item['type'] = "Musician/Band";
    item['website'] = "https://youtube.com/user/CheckmateZutphen";
    item['bands_bio'] = "Going strong since 2001";
    item['email'] = "mddboer@hotmail.com";
    item['pressphoto2_url'] = "";
    item['Main_Genre'] = "";
    item['tag_3'] = "Heavy metal";
    item['Insta_followers'] = "163";
    item['Facebook_followers'] = "205";
    item['Instagram'] = "https://instagram.com/checkmate_hardcore?igshid=YmMyMTA2M2Y=";
    item['press_photo_1'] = datasheet_sheet5_row8_press_photo_1;
    item['tag_1'] = "Metal/Punk crossover";
    item['city'] = "Eefde";
    item['maingenreID'] = "";
    item['members'] = "4 person group";
    item['video'] = {"mime":"video/mp4","size":61834960,"meta":{"video":{"res":"640x352","par":false,"postHeight":352,"codec":"h264 (High) (avc1 / 0x31637661)","dar":false,"fps":27.39,"postWidth":640,"bitrate":1175000,"profile":"yuv420p(tv"},"duration":344.68,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":256000}},"path":"/vault/0ZYxcr3W/z7KOdtw0sNn0qmjoUKt9WDq8eC8/4_vkqw../video.mp4","type":"video","name":"video.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/z7KOdtw0sNn0qmjoUKt9WDq8eC8/4_vkqw../video.mp4"};
    item['province'] = "Gelderland";
    item['you_are_dj'] = "0";
    item['pressphoto3_url'] = "";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "1079";
    item['Spotify_link'] = "https://open.spotify.com/album/6fQxo0SNtBwK6f9zhevIDA?si=JSC5bi7hSW6KETsc6MVX2A&utm_source=whatsapp";
    item['30sec_video'] = {"mime":"video/mp4","size":61834960,"meta":{"video":{"res":"640x352","par":false,"postHeight":352,"codec":"h264 (High) (avc1 / 0x31637661)","dar":false,"fps":27.39,"postWidth":640,"bitrate":1175000,"profile":"yuv420p(tv"},"duration":344.68,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":256000}},"path":"/vault/0ZYxcr3W/kOyNO01IouK0VIuZHMkbAgkow-U/W0SSPQ../video.mp4","type":"video","name":"video.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/kOyNO01IouK0VIuZHMkbAgkow-U/W0SSPQ../video.mp4"};
    item['Price_Range'] = "€50 - €450";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/chasinghillsmusic";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/y7IK1apyNkKOFWgXzc4prdLBV6w/pUOyEQ../01GD1G6SQQA9JEDXZZZD39VEMC.jpeg";
    item['tag_2'] = "Indie rock";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "382";
    item['country'] = "";
    item['created_at'] = "1663276375699";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['audio_3'] = {"mime":"audio/mpeg","size":4503916,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":192000},"duration":187.32},"path":"/vault/0ZYxcr3W/fdHreaIFpWcMAORwPkoRCBmZmqo/_H8PjQ../Oh+My.mp3","type":"audio","name":"Oh My.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/fdHreaIFpWcMAORwPkoRCBmZmqo/_H8PjQ../Oh+My.mp3"};
    item['whatsapp'] = "https://wa.me/";
    item['tele'] = "";
    item['profile_pic'] = datasheet_sheet5_row9_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/dTbbvQtfRIqeT5pBxhTo3jMGOIM/XjiFVg../01GD1JQXE6RZTJFXJPDZ3MZA5B.jpeg";
    item['street_number'] = "";
    item['audio_2'] = {"mime":"audio/mpeg","size":4402101,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":179000},"duration":195.76},"path":"/vault/0ZYxcr3W/7Eogvfj93j3MRVImPuhhQafKC-g/lAh6Og../Someone+Else.mp3","type":"audio","name":"Someone Else.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/7Eogvfj93j3MRVImPuhhQafKC-g/lAh6Og../Someone+Else.mp3"};
    item['perform_acoustic'] = "0";
    item['band_name'] = "Chasing Hills";
    item['type'] = "Musician/Band";
    item['website'] = "https://linktr.ee/chasinghills";
    item['bands_bio'] = "CHASING HILLS is een vierkoppige Nederlandse indie poprock band die je wegblaast met hun energieke sound en levendige aanwezigheid op het podium. Samen schrijft de band melodieuze, dynamische en catchy liedjes die je vanaf het begin grijpen met stevige gitaren, strakke baslijnen en pakkende lyrics. Dit alles is geïnspireerd door artiesten als Red Hot Chili Peppers, Arctic Monkeys, Coldplay en The Kooks. Liedjes over liefde vinden, vasthouden, loslaten en verliezen, een knipoog naar de coming-of-age popcultuur van hun jonge jaren. Wat begon als een jamsessie op een dampende zolderkamer tussen twee middelbare scholieren, groeide uit tot de band CHASING HILLS die tegenwoordig hard aan de weg timmert om toe te treden tot de Nederlandse muziekwereld.";
    item['press_photo_3'] = datasheet_sheet5_row9_press_photo_3;
    item['email'] = "chasinghills@gmail.com";
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/zrIROCi8dGG7FHnBRN2vAkJ-FBg/X-Er6g../01GD1JR5FJPQTRD44ZGK2Y06X7.jpeg";
    item['Main_Genre'] = "";
    item['tag_3'] = "Pop rock";
    item['Insta_followers'] = "679";
    item['Facebook_followers'] = "298";
    item['Instagram'] = "https://instagram.com/chasinghillsband?igshid=YmMyMTA2M2Y=";
    item['audio_1'] = {"mime":"audio/mpeg","size":4678653,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":199000},"duration":187.4},"path":"/vault/0ZYxcr3W/s7ztTO6kFZs5VFbMI6eZJKdOM98/2nB-Ng../Man+on+Fire.mp3","type":"audio","name":"Man on Fire.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/s7ztTO6kFZs5VFbMI6eZJKdOM98/2nB-Ng../Man+on+Fire.mp3"};
    item['tag_1'] = "Indie pop";
    item['press_photo_1'] = datasheet_sheet5_row9_press_photo_1;
    item['city'] = "Rotterdam";
    item['maingenreID'] = "";
    item['you_are_dj'] = "0";
    item['members'] = "4 person group";
    item['province'] = "Zuid-Holland";
    item['pressphoto3_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/PqB6DYZs3xPy4raa-Quc-VJUScM/eCqmHQ../01GD1JRB6GSXZ3BM88PXDC53GX.jpeg";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "89";
    item['Spotify_link'] = "https://open.spotify.com/artist/3m1kiHjWZ1Xk3JPuqs2kOX?si=JPNZte3XQdOw0-vooyiJ1A&utm_source=copy-link";
    item['press_photo_2'] = datasheet_sheet5_row9_press_photo_2;
    item['Price_Range'] = "Negotiable";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/Edsattic/";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/KMDjp_nddPI9TL3umhzBhl5ac88/rCx_yw../01GD3QKXV5PZF6W127FBYJEEDM.jpeg";
    item['tag_2'] = "-";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "384";
    item['country'] = "";
    item['created_at'] = "1663351399598";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['audio_3'] = {"mime":"audio/mpeg","size":11751949,"meta":{"video":{"res":"500x500","par":false,"postHeight":500,"codec":"mjpeg (Progressive)","dar":1,"fps":0,"postWidth":500,"bitrate":0,"profile":"yuvj444p(pc"},"duration":487.58,"audio":{"codec":"mp3","freq":44100,"bitrate":192000}},"path":"/vault/0ZYxcr3W/mRC-w26qnyc_M8OB0Pykv0Kbybk/08qqHw../The+ebola+experience.mp3","type":"audio","name":"The ebola experience.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/mRC-w26qnyc_M8OB0Pykv0Kbybk/08qqHw../The+ebola+experience.mp3"};
    item['whatsapp'] = "https://wa.me/";
    item['tele'] = "";
    item['profile_pic'] = datasheet_sheet5_row10_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/57ZjDt2rlaZEQxCQ8iCWnVQT_Sc/IVo-QA../01GD3QZS38THZEMQRXRDH29W8T.jpeg";
    item['street_number'] = "";
    item['audio_2'] = {"mime":"audio/mpeg","size":9498584,"meta":{"video":{"res":"500x500","par":false,"postHeight":500,"codec":"mjpeg (Progressive)","dar":1,"fps":0,"postWidth":500,"bitrate":0,"profile":"yuvj444p(pc"},"duration":393.79,"audio":{"codec":"mp3","freq":44100,"bitrate":192000}},"path":"/vault/0ZYxcr3W/gzPH-0aVMJ2mhQUZHavkyhQ5HwY/nsDqzw../Mission+51L.mp3","type":"audio","name":"Mission 51L.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/gzPH-0aVMJ2mhQUZHavkyhQ5HwY/nsDqzw../Mission+51L.mp3"};
    item['perform_acoustic'] = "0";
    item['band_name'] = "Edsattic";
    item['type'] = "Musician/Band";
    item['website'] = "http://edsattic.nl";
    item['bands_bio'] = "Edstattic creates heavy rockin' metal in the vein of great examples like Iron Maiden, Kiss, Metallica, Yngwie Malmsteen, Slayer and many more!";
    item['email'] = "dianthe24@outlook.com";
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/_jAYAAAPF_goF1XxB2j0S1YzAk0/1N_Cjg../01GD3R120V27QDM2YMGAPZV1R6.jpeg";
    item['Main_Genre'] = "";
    item['tag_3'] = "-";
    item['Insta_followers'] = "50";
    item['Facebook_followers'] = "140";
    item['Instagram'] = "https://www.instagram.com/edsatticband";
    item['audio_1'] = {"mime":"audio/mpeg","size":7305393,"meta":{"video":{"res":"500x500","par":false,"postHeight":500,"codec":"mjpeg (Progressive)","dar":1,"fps":0,"postWidth":500,"bitrate":0,"profile":"yuvj444p(pc"},"duration":302.49,"audio":{"codec":"mp3","freq":44100,"bitrate":192000}},"path":"/vault/0ZYxcr3W/SzFhCEIG-X1fkrQp3pN633xD0VM/3z_2dQ../On+Edsattic.mp3","type":"audio","name":"On Edsattic.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/SzFhCEIG-X1fkrQp3pN633xD0VM/3z_2dQ../On+Edsattic.mp3"};
    item['tag_1'] = "Metal";
    item['press_photo_1'] = datasheet_sheet5_row10_press_photo_1;
    item['city'] = "Den-Haag";
    item['maingenreID'] = "";
    item['members'] = "4 person group";
    item['video'] = {"mime":"video/mp4","size":33200939,"meta":{"video":{"res":"1280x720","par":false,"postHeight":720,"codec":"h264 (Main) (avc1 / 0x31637661)","dar":1.777777777777778,"fps":30,"postWidth":1280,"bitrate":8509000,"profile":"yuv420p(tv"},"duration":30.06,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":317000}},"path":"/vault/0ZYxcr3W/6JQSAFJr9mpkcjYCRaoDUQ2Wf6s/8PX3fQ../video.mp4","type":"video","name":"video.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/6JQSAFJr9mpkcjYCRaoDUQ2Wf6s/8PX3fQ../video.mp4"};
    item['province'] = "Zuid-Holland";
    item['you_are_dj'] = "0";
    item['pressphoto3_url'] = "";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "";
    item['Spotify_link'] = "";
    item['press_photo_2'] = datasheet_sheet5_row10_press_photo_2;
    item['30sec_video'] = {"mime":"video/mp4","size":33200939,"meta":{"video":{"res":"1280x720","par":false,"postHeight":720,"codec":"h264 (Main) (avc1 / 0x31637661)","dar":1.777777777777778,"fps":30,"postWidth":1280,"bitrate":8509000,"profile":"yuv420p(tv"},"duration":30.06,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":317000}},"path":"/vault/0ZYxcr3W/h3XEHJBt_zoD4ENX0TQBWmd6uCA/SzzaMg../Edsattic.mp4","type":"video","name":"Edsattic.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/h3XEHJBt_zoD4ENX0TQBWmd6uCA/SzzaMg../Edsattic.mp4"};
    item['Price_Range'] = "Negotiable";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/thedeafdollars";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/YrGFTiEbh6ZA8CzjRhQwMKq3yLE/qH2XiQ../01GD47M6CGTNRKRMJ7VHFN9P2A.jpeg";
    item['tag_2'] = "Indie rock";
    item['Post_Code'] = "4205KB";
    item['street'] = "Dokter Bauerstraat";
    item['id'] = "385";
    item['country'] = "";
    item['created_at'] = "1663367654847";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['audio_3'] = {"mime":"application/octet-stream","size":5022351,"meta":{},"path":"/vault/0ZYxcr3W/1ruNI4AlUoS2EvOJJ34qW3aAqCo/spPi9A../01GD5FCQ20JVXYGV6NQZQXYV01.mp3.mp3.mp3.mp3","type":"","name":"Electrify.mp3.mp3.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/1ruNI4AlUoS2EvOJJ34qW3aAqCo/spPi9A../01GD5FCQ20JVXYGV6NQZQXYV01.mp3.mp3.mp3.mp3"};
    item['whatsapp'] = "https://wa.me/+31628457555";
    item['tele'] = "0628457555";
    item['profile_pic'] = datasheet_sheet5_row11_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/gkRrkbAyhLAJRHu2KhmQp-atehA/ZItLJQ../01GD47YWEZEKZHA8T3WPJVQS4F.jpeg";
    item['street_number'] = "46";
    item['audio_2'] = {"mime":"application/octet-stream","size":5223917,"meta":{},"path":"/vault/0ZYxcr3W/xd4xc1UQMQwRYiwItpsgW6KqsWg/Z6nflA../01GD5FC7CRE441NRKE365TDTR0.mp3.mp3.mp3.mp3","type":"","name":"Soul Education.mp3.mp3.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/xd4xc1UQMQwRYiwItpsgW6KqsWg/Z6nflA../01GD5FC7CRE441NRKE365TDTR0.mp3.mp3.mp3.mp3"};
    item['perform_acoustic'] = "0";
    item['band_name'] = "The Deaf Dollars";
    item['type'] = "Musician/Band";
    item['website'] = "https://www.thedeafdollars.com";
    item['bands_bio'] = "The Deaf Dollars is a rockband invented by three lifetime friends: Pascal Molenaar, Erik Sterk and Kevin Tsie.With their enormous suitcases of experience they find now is the time to take everything to the max and full throttle on their musical capabilities and keep pushing.\nCommunication with the audience and passion and love for creating music is key to making their music and pushing this around the world. Rock combined with theatrical emotional melodies and the sounds of the 80's is why The Deaf Dollars stick out. With legends like Prince, David Bowie, Queen and Lenny Kravitz as their teachers you will get drawn into a professional live performance you won't forget.\n \n \nThe Deaf Dollars are not narrow minded and believe in progression. So they keep developing their music an easily keep up with the NOW.\nPascal, Kevin and Erik describe their songs as art, mixed with the love of making music loaded with emotion, and the passion of bringing this to their fans, the people.\nExperience they received from playing as much as they can. Playing [support] shows for bands like Triggerfinger, Royal Republic, on stages like 013, the Melkweg and international festivals like Sziget. And obtaining a Topsong on one of the biggest national radiostations in Holland NPORadio2\n\nYep, Rock with the capital R,..";
    item['email'] = "bandungboysince1942@gmail.com";
    item['pressphoto2_url'] = "";
    item['Main_Genre'] = "";
    item['tag_3'] = "Guitar rock";
    item['Insta_followers'] = "1730";
    item['Facebook_followers'] = "3400";
    item['Instagram'] = "https://www.instagram.com/thedeafdollars";
    item['audio_1'] = {"mime":"application/octet-stream","size":4764250,"meta":{},"path":"/vault/0ZYxcr3W/RCuFNDXcTBU_DnhXwjCqSmurr20/x21rQQ../01GD5FP1YTMQB323NY1SZGEBP5.mp3.mp3.mp3","type":"","name":"High Expectations.mp3.mp3.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/RCuFNDXcTBU_DnhXwjCqSmurr20/x21rQQ../01GD5FP1YTMQB323NY1SZGEBP5.mp3.mp3.mp3"};
    item['tag_1'] = "Pop rock";
    item['press_photo_1'] = datasheet_sheet5_row11_press_photo_1;
    item['city'] = "Gorinchem";
    item['maingenreID'] = "";
    item['members'] = "4 person group";
    item['video'] = {"mime":"video/mp4","size":9299933,"meta":{"video":{"res":"426x214","par":false,"postHeight":214,"codec":"h264 (Main) (avc1 / 0x31637661)","dar":1.990654205607477,"fps":29.97,"postWidth":426,"bitrate":208000,"profile":"yuv420p(tv"},"duration":216.99,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":44100,"bitrate":127000}},"path":"/vault/0ZYxcr3W/-Lg_jnKONjuGt8d_C5YwodeqYVU/0AY_xw../video.mp4","type":"video","name":"video.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/-Lg_jnKONjuGt8d_C5YwodeqYVU/0AY_xw../video.mp4"};
    item['province'] = "Zuid-Holland";
    item['you_are_dj'] = "0";
    item['pressphoto3_url'] = "";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "150";
    item['Spotify_link'] = "https://open.spotify.com/artist/0vZyQkJMMmHmTs5dw4Fqwo?si=QD2dJYULRFG4G9eLopDLBA";
    item['30sec_video'] = {"mime":"video/mp4","size":9299933,"meta":{"video":{"res":"426x214","par":false,"postHeight":214,"codec":"h264 (Main) (avc1 / 0x31637661)","dar":1.990654205607477,"fps":29.97,"postWidth":426,"bitrate":208000,"profile":"yuv420p(tv"},"duration":216.99,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":44100,"bitrate":127000}},"path":"/vault/0ZYxcr3W/bbjzhYF0-vm8sRHFHD77Nk481vo/r6NYBw../The+Deaf+Dollars++LA+Rehearsal_v240P.mp4","type":"video","name":"The Deaf Dollars  LA Rehearsal_v240P.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/bbjzhYF0-vm8sRHFHD77Nk481vo/r6NYBw../The+Deaf+Dollars++LA+Rehearsal_v240P.mp4"};
    item['Price_Range'] = "Negotiable";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/1AgzgbuwGnxnf7l5ZZBfhAAyO9s/IH-x5w../01GD61WV1MNX6ZR1YFJBE6HC3M.jpeg";
    item['tag_2'] = "Pop rock";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "388";
    item['country'] = "";
    item['created_at'] = "1663423954498";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['audio_3'] = {"mime":"audio/mpeg","size":7663050,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":272000},"duration":224.63},"path":"/vault/0ZYxcr3W/sftSBDbtZncqyLEfqQIgOHHlcco/g7xbEw../Yesterday%27s+Paper.mp3","type":"audio","name":"Yesterday's Paper.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/sftSBDbtZncqyLEfqQIgOHHlcco/g7xbEw../Yesterday%27s+Paper.mp3"};
    item['whatsapp'] = "https://wa.me/+31646756276";
    item['tele'] = "0646756276";
    item['profile_pic'] = datasheet_sheet5_row12_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/vw0qQIUh2H9lX7D-bymP9BSbq2Q/X6OEpQ../01GD61X2SKATAK42XEYFNBBQMB.jpeg";
    item['street_number'] = "";
    item['audio_2'] = {"mime":"audio/mpeg","size":5026940,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":260000},"duration":154.41},"path":"/vault/0ZYxcr3W/JJCpvchWrC1r70LeZ3gY-JMddLY/rdJlXA../His+Love+For+Colors.mp3","type":"audio","name":"His Love For Colors.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/JJCpvchWrC1r70LeZ3gY-JMddLY/rdJlXA../His+Love+For+Colors.mp3"};
    item['perform_acoustic'] = "0";
    item['band_name'] = "Herman de Kok";
    item['type'] = "Musician/Band";
    item['website'] = "";
    item['bands_bio'] = "Herman de Kok is de zanger, gitarist en tekstschrijven van de Rockband My Own Army die sinds 2007 enkele albums uitbrachten en Nederland en Belgie rond touren. De band stond al op grote podia met bekende internationale bands en heeft recent hun 4e album uitgebracht genaamd These Gentle Day’s onder het label RVP Records en zitten op het moment in de studio voor hun 5e album die later dit jaar gaat uitkomen.\n\nJe kunt hem ook kennen als frontman van de Pearl Jam coverband Pearl Ten die in 2016 in de finale stonden van de Clash of the Coverbands en al op veel festivals de 90’s hebben doen herleven.\n\nMaar naast al dit gitaargeweld is Herman ook singer/songwriter en bracht in mei 2020 zijn sterke solodebuut uit genaamd Interview Part I. Als eerste deel van een tweeluik dat nu bijna een jaar later gecompleteerd is met het album Interview Part II.";
    item['press_photo_3'] = datasheet_sheet5_row12_press_photo_3;
    item['audio_1_photo'] = datasheet_sheet5_row12_audio_1_photo;
    item['audio_3_photo'] = datasheet_sheet5_row12_audio_3_photo;
    item['email'] = "herdesmad@hotmail.com";
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/r_a9ED9kn--ge9X98yaUIhyGBBM/h20p7Q../01GD61X87BPYTBM4EWWAX27B1N.jpeg";
    item['Main_Genre'] = "";
    item['tag_3'] = "";
    item['Insta_followers'] = "";
    item['Facebook_followers'] = "";
    item['Instagram'] = "";
    item['audio_1'] = {"mime":"audio/mpeg","size":6370437,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":233000},"duration":218.23},"path":"/vault/0ZYxcr3W/jKSa33oEjyhQ6Buq3fRG0MfiCmM/cnjHXg../Unfamiliar.mp3","type":"audio","name":"Unfamiliar.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/jKSa33oEjyhQ6Buq3fRG0MfiCmM/cnjHXg../Unfamiliar.mp3"};
    item['tag_1'] = "Singer/Songwriter";
    item['press_photo_1'] = datasheet_sheet5_row12_press_photo_1;
    item['city'] = "";
    item['maingenreID'] = "";
    item['members'] = "I’m a solo artist";
    item['video'] = {"mime":"video/mp4","size":34281433,"meta":{"video":{"res":"1280x720","par":false,"postHeight":720,"codec":"h264 (Main) (avc1 / 0x31637661)","dar":1.777777777777778,"fps":50,"postWidth":1280,"bitrate":8795000,"profile":"yuv420p(tv"},"duration":30.08,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":317000}},"path":"/vault/0ZYxcr3W/yJOMukA5dt-4q3bggNksMWK6hTk/e-Yj9A../Herrie.mp4","type":"video","name":"Herrie.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/yJOMukA5dt-4q3bggNksMWK6hTk/e-Yj9A../Herrie.mp4"};
    item['province'] = "Zuid-Holland";
    item['you_are_dj'] = "0";
    item['pressphoto3_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/v5XblIuGLKZdZVHUFh-SzMFAne8/CCD0qw../01GD61XCAWVFJXYQER193XERSF.jpeg";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "";
    item['audio_2_photo'] = datasheet_sheet5_row12_audio_2_photo;
    item['Spotify_link'] = "https://open.spotify.com/artist/090sEOBwyUtb2iEEJK9yxC?si=gcYc27lDRV6-zZ75aOVBtA&utm_source=copy-link";
    item['press_photo_2'] = datasheet_sheet5_row12_press_photo_2;
    item['30sec_video'] = {"mime":"video/mp4","size":34281433,"meta":{"video":{"res":"1280x720","par":false,"postHeight":720,"codec":"h264 (Main) (avc1 / 0x31637661)","dar":1.777777777777778,"fps":50,"postWidth":1280,"bitrate":8795000,"profile":"yuv420p(tv"},"duration":30.08,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":317000}},"path":"/vault/0ZYxcr3W/Y-ZCJzIf7ve4xaviFikhq6WFTwk/CWUp5g../Herrie.mp4","type":"video","name":"Herrie.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Y-ZCJzIf7ve4xaviFikhq6WFTwk/CWUp5g../Herrie.mp4"};
    item['Price_Range'] = "Negotiable";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "";
    item['profilepic_url'] = "";
    item['tag_2'] = "";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "393";
    item['country'] = "";
    item['created_at'] = "1663513174143";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['whatsapp'] = "";
    item['tele'] = "";
    item['pressphoto1_url'] = "";
    item['street_number'] = "";
    item['perform_acoustic'] = "0";
    item['band_name'] = "";
    item['type'] = "Musician/Band";
    item['bands_bio'] = "";
    item['email'] = "buks.kemp@gmail.com";
    item['pressphoto2_url'] = "";
    item['Main_Genre'] = "";
    item['tag_3'] = "";
    item['Insta_followers'] = "";
    item['Facebook_followers'] = "";
    item['Instagram'] = "";
    item['tag_1'] = "";
    item['city'] = "";
    item['maingenreID'] = "";
    item['members'] = "";
    item['pressphoto3_url'] = "";
    item['province'] = "";
    item['you_are_dj'] = "0";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "";
    item['Spotify_link'] = "";
    item['Price_Range'] = "";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/graftakband/";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Lr62dzV4IHL32qlp11gopOvdo6U/ubR64w../01GFK37EV8P057HK5Q10HR5EN5.jpeg";
    item['tag_2'] = "";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "394";
    item['country'] = "";
    item['created_at'] = "1663570772468";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['audio_3'] = {"mime":"audio/mpeg","size":15,"meta":{},"path":"/vault/0ZYxcr3W/cS39xDou2hLSFmpG3ZcD9hqUlzg/lOL-8g../var_4.mp3.mp3","type":"audio","name":".mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/cS39xDou2hLSFmpG3ZcD9hqUlzg/lOL-8g../var_4.mp3.mp3"};
    item['whatsapp'] = "https://wa.me/";
    item['tele'] = "";
    item['profile_pic'] = datasheet_sheet5_row14_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/cWS7SwBislicrLEbErK2_2bD3cc/8fgxNA../01GG4W1NS9BP8YD8KCR1DKHK1K.jpeg";
    item['street_number'] = "";
    item['audio_2'] = {"mime":"audio/mpeg","size":15,"meta":{},"path":"/vault/0ZYxcr3W/-SZ1LEyrC_NBAQFil7xELYpKh3E/EaswkA../var_4.mp3.mp3","type":"audio","name":".mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/-SZ1LEyrC_NBAQFil7xELYpKh3E/EaswkA../var_4.mp3.mp3"};
    item['perform_acoustic'] = "0";
    item['band_name'] = "Graftak";
    item['type'] = "Musician/Band";
    item['website'] = "";
    item['bands_bio'] = "Melodic punkrock crossover from Rotterdam.";
    item['press_photo_3'] = datasheet_sheet5_row14_press_photo_3;
    item['email'] = "graftakband@gmail.com";
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/oOk80M2IhsyELwIDsX24l-pA4R4/UNGswg../01GG4VR1R79DBF31SAY4DZ9HSX.jpeg";
    item['Main_Genre'] = "";
    item['tag_3'] = "";
    item['Insta_followers'] = "332";
    item['Facebook_followers'] = "545";
    item['Instagram'] = "https://www.instagram.com/graftakband/";
    item['audio_1'] = {"mime":"audio/mpeg","size":15,"meta":{},"path":"/vault/0ZYxcr3W/AOw3Q5-DAmkal_Z97hjxYf5W244/xHDSqQ../var_4.mp3.mp3","type":"audio","name":".mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/AOw3Q5-DAmkal_Z97hjxYf5W244/xHDSqQ../var_4.mp3.mp3"};
    item['tag_1'] = "Punkrock";
    item['press_photo_1'] = datasheet_sheet5_row14_press_photo_1;
    item['city'] = "Rotterdam";
    item['maingenreID'] = "";
    item['members'] = "3 person group";
    item['video'] = {"mime":"video/mp4","size":3936642,"meta":{"video":{"res":"480x854","par":false,"postHeight":854,"codec":"h264 (Baseline) (avc1 / 0x31637661)","dar":false,"fps":24.14,"postWidth":480,"bitrate":2041000,"profile":"yuv420p(tv"},"duration":15,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":44100,"bitrate":62000}},"path":"/vault/0ZYxcr3W/2gQ_dfg6Ns9j79i96uCtvvF7r60/yaoJGw../video.mp4","type":"video","name":"video.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/2gQ_dfg6Ns9j79i96uCtvvF7r60/yaoJGw../video.mp4"};
    item['province'] = "Zuid-Holland";
    item['you_are_dj'] = "0";
    item['pressphoto3_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/8EDa_SJMNwXOfnpVuVOiVjCGpWU/xJnEEw../01GG4VV44GGYF5H93663VG9C6N.jpeg";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "30";
    item['Spotify_link'] = "https://open.spotify.com/artist/3brpKMk3FGwKnX9u9osyQA";
    item['press_photo_2'] = datasheet_sheet5_row14_press_photo_2;
    item['30sec_video'] = {"mime":"video/mp4","size":3936642,"meta":{"video":{"res":"480x854","par":false,"postHeight":854,"codec":"h264 (Baseline) (avc1 / 0x31637661)","dar":false,"fps":24.14,"postWidth":480,"bitrate":2041000,"profile":"yuv420p(tv"},"duration":15,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":44100,"bitrate":62000}},"path":"/vault/0ZYxcr3W/yqYDORwFlOZl-s9kV0ajKk8DiUo/rw_Shg../video.mp4","type":"video","name":"video.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/yqYDORwFlOZl-s9kV0ajKk8DiUo/rw_Shg../video.mp4"};
    item['Price_Range'] = "";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://m.facebook.com/profile.php?id=100078822462153&eav=Afbi9HPoZmZTU-EnGwFCpDwQlzw05q0rSWUeb2rN6lzGk9XfBuqbwfcbN_FS1yor8zw&tsid=0.4762347015440742&source=result&paipv=0&_rdr";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/bYHtGqa5pJqA1I-kdrb9yARIZ_c/VtOYfQ../01GEPGXVS0VNEX2KBA55Z29VTQ.jpeg";
    item['tag_2'] = "Metal";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "408";
    item['country'] = "";
    item['created_at'] = "1664176745123";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['audio_3'] = {"mime":"audio/mpeg","size":4963809,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":197000},"duration":200.57},"path":"/vault/0ZYxcr3W/Gb9iOSRkf8Xqlbf0GbVTNuR_UIg/j8on2A../Realm+of+Death.mp3","type":"audio","name":"Realm of Death.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Gb9iOSRkf8Xqlbf0GbVTNuR_UIg/j8on2A../Realm+of+Death.mp3"};
    item['whatsapp'] = "https://wa.me/+31614792068";
    item['tele'] = "0614792068";
    item['profile_pic'] = datasheet_sheet5_row15_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/bWjBvj3qeTUM1Cqua824FrQEVb4/Hx76dw../01GEPG8V0CDPZGM7YHVCKSDCY4.jpeg";
    item['street_number'] = "";
    item['audio_2'] = {"mime":"audio/mpeg","size":4858144,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":201000},"duration":193.28},"path":"/vault/0ZYxcr3W/rrhpsRzzdG6exm1uqWLc7I2PvqU/YM_u6A../Blasphemy.mp3","type":"audio","name":"Blasphemy.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/rrhpsRzzdG6exm1uqWLc7I2PvqU/YM_u6A../Blasphemy.mp3"};
    item['perform_acoustic'] = "0";
    item['band_name'] = "Impure";
    item['type'] = "Musician/Band";
    item['website'] = "https://linktr.ee/impure_band";
    item['bands_bio'] = "Impure is a Dutch black/death metal band based in Breda, Formed in 2021.\nThe band consists of five members: Jesse Jansen on vocals, Enzo van der Sterren on \nlead guitar, Tim Posthumus on rhythm guitar, Abe Stadhouders on drums and Percy \nJoosen on bass. We are inspired by lots of old school black and death metal bands \n(like Marduk, Dissection, Asphyx, Dismember) and combine that with our own sound.\nWith the creative storytelling guitar solos, memorable riffs, harsh vocals, \ndevastating bass and loud fast paced drums, we create Impure. We make our music \nunique.";
    item['press_photo_3'] = datasheet_sheet5_row15_press_photo_3;
    item['email'] = "jesse2002jansen@live.nl";
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/sk-oeihBt35VZlmjkABdjqn37vw/xAXvpw../01GEPGAMN8PK178G0RVH8BCYSB.jpeg";
    item['Main_Genre'] = "";
    item['tag_3'] = "";
    item['Insta_followers'] = "351";
    item['Facebook_followers'] = "52";
    item['Instagram'] = "https://www.instagram.com/impure_band/";
    item['audio_1'] = {"mime":"audio/mpeg","size":6032714,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":198000},"duration":242.99},"path":"/vault/0ZYxcr3W/rBh5JT8nK9cDpYJc48b5IV18Uk0/8kuMfg../Eternal+Darkness.mp3","type":"audio","name":"Eternal Darkness.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/rBh5JT8nK9cDpYJc48b5IV18Uk0/8kuMfg../Eternal+Darkness.mp3"};
    item['tag_1'] = "Death metal";
    item['press_photo_1'] = datasheet_sheet5_row15_press_photo_1;
    item['city'] = "Breda";
    item['maingenreID'] = "";
    item['members'] = "5 person group";
    item['video'] = {"mime":"video/mp4","size":6169102,"meta":{"video":{"res":"640x352","par":false,"postHeight":352,"codec":"h264 (Baseline) (avc1 / 0x31637661)","dar":false,"fps":30.06,"postWidth":640,"bitrate":1989000,"profile":"yuv420p(tv"},"duration":21.38,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":320000}},"path":"/vault/0ZYxcr3W/djRXqcjtEkeKmH3taivpZYjp2Dw/oTxVFA../video.mp4","type":"video","name":"video.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/djRXqcjtEkeKmH3taivpZYjp2Dw/oTxVFA../video.mp4"};
    item['province'] = "Noord-Brabant";
    item['you_are_dj'] = "0";
    item['pressphoto3_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/W1J8-VyLf0-uemVekcQJ4C4z8_0/6Ne3Pg../01GEPGAWZMP09WTHMCVK6FHYSW.jpeg";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "39";
    item['Spotify_link'] = "https://open.spotify.com/artist/13fOTVDit2D6wF46VVJmI0?si=GHIs0EkxRgShbmf1CAwj9g";
    item['press_photo_2'] = datasheet_sheet5_row15_press_photo_2;
    item['30sec_video'] = {"mime":"video/mp4","size":6169102,"meta":{"video":{"res":"640x352","par":false,"postHeight":352,"codec":"h264 (Baseline) (avc1 / 0x31637661)","dar":false,"fps":30.06,"postWidth":640,"bitrate":1989000,"profile":"yuv420p(tv"},"duration":21.38,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":320000}},"path":"/vault/0ZYxcr3W/UAI2gi9sqgvAbKKQ2aDGSIAX43s/GciCuQ../video.mp4","type":"video","name":"video.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/UAI2gi9sqgvAbKKQ2aDGSIAX43s/GciCuQ../video.mp4"};
    item['Price_Range'] = "€50 - €450";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/TheScurfies";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/GXEBD0OnbqYcnK2mpMpbY5hqBXc/VoER4Q../DOC-20221009-WA0000.jpg";
    item['tag_2'] = "Rock crossover";
    item['Post_Code'] = "3812 RN";
    item['street'] = "Kroonenburg";
    item['id'] = "410";
    item['country'] = "";
    item['created_at'] = "1664179874628";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['whatsapp'] = "https://wa.me/+31631389059";
    item['tele'] = "+31 6 31389059";
    item['profile_pic'] = datasheet_sheet5_row16_profile_pic;
    item['pressphoto1_url'] = "";
    item['street_number'] = "12";
    item['audio_2'] = {"mime":"application/octet-stream","size":6959296,"meta":{"audio":{"codec":"mp3","freq":48000,"bitrate":320000},"duration":173.88},"path":"/vault/0ZYxcr3W/DtzPEP2KLVglx5xfhF8vglrYcYg/0o5zHw../Can%27t+Get+Enough.MP3","type":"audio","name":"Can't Get Enough.MP3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/DtzPEP2KLVglx5xfhF8vglrYcYg/0o5zHw../Can%27t+Get+Enough.MP3"};
    item['perform_acoustic'] = "0";
    item['band_name'] = "The Scurfies";
    item['type'] = "Musician/Band";
    item['website'] = "https://instagram.com/thescurfies?igshid=YmMyMTA2M2Y=";
    item['bands_bio'] = "\"The Scurfies: je neemt één deel ska, één deel pop, één deel punk, topt dat af met wat rock, gooit het in de blender en je krijgt een cocktail waarmee je de voeten van duizend man van de vloer krijgt. Feest dus!\" - 3 voor 12\n\nEnergiek, speels en met een ruw randje: Sinds 2019 zijn The Scurfies een feit. Deze 6-koppige band uit Amersfoort zijn bij elkaar gebracht door een gedeelde plaag van schurftmijten waarna het binnen de band begon te jeuken om de podia in Nederland plat te spelen. Met hun energieke mix van ska, rock en pop maken The Scurfies van elke show een groot feest.";
    item['email'] = "thescurfies@gmail.com";
    item['pressphoto2_url'] = "";
    item['Main_Genre'] = "";
    item['tag_3'] = "Pop rock";
    item['Insta_followers'] = "458";
    item['Facebook_followers'] = "353";
    item['Instagram'] = "https://instagram.com/thescurfies?igshid=YmMyMTA2M2Y=";
    item['audio_1'] = {"mime":"application/octet-stream","size":7840576,"meta":{"audio":{"codec":"mp3","freq":48000,"bitrate":320000},"duration":195.91},"path":"/vault/0ZYxcr3W/ydvYktqUp_wtEeLoUSCfXC5L4Pc/cUz6XQ../Doesn%27t+Bother+Me.MP3","type":"audio","name":"Doesn't Bother Me.MP3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/ydvYktqUp_wtEeLoUSCfXC5L4Pc/cUz6XQ../Doesn%27t+Bother+Me.MP3"};
    item['tag_1'] = "Authentic-ska";
    item['city'] = "Amersfoort";
    item['maingenreID'] = "";
    item['members'] = "6 person group";
    item['video'] = {"mime":"video/mp4","size":9207699,"meta":{"video":{"res":"1280x720","par":false,"postHeight":720,"codec":"h264 (High) (avc1 / 0x31637661)","dar":1.777777777777778,"fps":25,"postWidth":1280,"bitrate":2000000,"profile":"yuv420p(tv"},"duration":34.57,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":44100,"bitrate":127000}},"path":"/vault/0ZYxcr3W/176m_FfwlXhl1S1Y2XGGGf50CmY/xsCUdA../Demo1.mp4","type":"video","name":"Demo1.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/176m_FfwlXhl1S1Y2XGGGf50CmY/xsCUdA../Demo1.mp4"};
    item['province'] = "Utrecht";
    item['you_are_dj'] = "0";
    item['pressphoto3_url'] = "";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "31";
    item['Spotify_link'] = "https://open.spotify.com/artist/7GF2wuouq8c7M5AV1v59oO?si=qkgOE5gnSU2klLsh5OCTkA&utm_source=copy-link";
    item['Price_Range'] = "Negotiable";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/greenpolicy/";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/s9m35kffDQQ6eLMyjKtvOXYw1LM/7EXPRg../01GDWYMS9830QPDSDCQHZGVGQM.jpeg";
    item['tag_2'] = "Guitar rock";
    item['Post_Code'] = "3533HG";
    item['street'] = "Kanaalstraat";
    item['id'] = "414";
    item['country'] = "";
    item['created_at'] = "1664191353988";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['whatsapp'] = "https://wa.me/+31646072016";
    item['tele'] = "0646072016";
    item['profile_pic'] = datasheet_sheet5_row17_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/TnFUarpLtva0DN8ggcustgu57C8/K-L2wg../01GDWYVE2F8TJBYCTCVYN8CRCY.jpeg";
    item['street_number'] = "86b";
    item['audio_2'] = {"mime":"application/octet-stream","size":11011217,"meta":{},"path":"/vault/0ZYxcr3W/vmekXlHeCgGPBE0r_MERzzKsWaU/_ORniw../01GDWZHJRX7HRCG8F1J75YWQYY.mp3.mp3","type":"","name":"Killerbug.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/vmekXlHeCgGPBE0r_MERzzKsWaU/_ORniw../01GDWZHJRX7HRCG8F1J75YWQYY.mp3.mp3"};
    item['perform_acoustic'] = "0";
    item['band_name'] = "Green Policy";
    item['type'] = "Musician/Band";
    item['website'] = "https://www.thegreenpolicy.com/";
    item['bands_bio'] = "From somewhere behind curtains of acid rain,\na new name in the Dutch stoner rock scene has emerged.\nWith gnarly riffs and messages of the post apocalyptic decay of the world, Green Policy turns every venue into a tornado. Led by frontman Dave Bouquet they thread silently through the haze of the Dutch underground until it’s time to rain down on the crowd. The rest of the band’s misfits consists out of the ever stoned Roan Grasmeijer on bass, The riff-machine aka Dan Green and an absolute madlad of a drummer, Milan Massar.";
    item['press_photo_3'] = datasheet_sheet5_row17_press_photo_3;
    item['email'] = "greenpolicy42o@gmail.com";
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/035Chq1UA1nkiupf8uRM-9HEkBI/bleHhQ../01GDWYW9YXTSPJE2P61ETKKMS9.jpeg";
    item['Main_Genre'] = "";
    item['tag_3'] = "-";
    item['Insta_followers'] = "444";
    item['Facebook_followers'] = "257";
    item['Instagram'] = "https://instagram.com/thegreenpolicy?igshid=YmMyMTA2M2Y=";
    item['audio_1'] = {"mime":"application/octet-stream","size":59285886,"meta":{},"path":"/vault/0ZYxcr3W/LiUY9NMsm8-gCQPiS_IiErJZfNU/pA0jfQ../01GDWZ1XJRE03JFFXS21TCVT4F.binary.mp3.mp3.mp3","type":"","name":"Pyromaniacs.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/LiUY9NMsm8-gCQPiS_IiErJZfNU/pA0jfQ../01GDWZ1XJRE03JFFXS21TCVT4F.binary.mp3.mp3.mp3"};
    item['tag_1'] = "Stoner rock";
    item['press_photo_1'] = datasheet_sheet5_row17_press_photo_1;
    item['city'] = "Utrecht";
    item['maingenreID'] = "";
    item['members'] = "4 person group";
    item['video'] = {"mime":"video/mp4","size":72548339,"meta":{"video":{"res":"1920x1080","par":false,"postHeight":1080,"codec":"hevc (Main) (hvc1 / 0x31637668)","dar":false,"fps":25.01,"postWidth":1920,"bitrate":10309000,"profile":"yuvj420p(pc"},"duration":54.93,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":251000}},"path":"/vault/0ZYxcr3W/4I3hFYB4rUxry3uDYI9Vv-3ub2s/VWQyxA../video.mp4","type":"video","name":"video.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/4I3hFYB4rUxry3uDYI9Vv-3ub2s/VWQyxA../video.mp4"};
    item['province'] = "Utrecht";
    item['you_are_dj'] = "0";
    item['pressphoto3_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/U33qqeBY0OoZoRoA3qCx7jLhVwI/LWrNSw../01GDWYWXR4FZ7JVE2BHX3FWZTG.jpeg";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "34";
    item['Spotify_link'] = "https://open.spotify.com/artist/2w0dXDPDX0HxBgame5D4F4?si=uEzV0p-ERzuJlNTP2e790Q";
    item['press_photo_2'] = datasheet_sheet5_row17_press_photo_2;
    item['30sec_video'] = {"mime":"video/mp4","size":72548339,"meta":{"video":{"res":"1920x1080","par":false,"postHeight":1080,"codec":"hevc (Main) (hvc1 / 0x31637668)","dar":false,"fps":25.01,"postWidth":1920,"bitrate":10309000,"profile":"yuvj420p(pc"},"duration":54.93,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":251000}},"path":"/vault/0ZYxcr3W/JZJbtoNWuilv3ttw5OLrZqrVcIU/nKuF1w../video.mp4","type":"video","name":"video.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/JZJbtoNWuilv3ttw5OLrZqrVcIU/nKuF1w../video.mp4"};
    item['Price_Range'] = "Negotiable";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/KatCarsonSoprano/";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Ho9k_KHRKKl0ySwa0m5EL2o1M78/xzaoTg../01GDZADHRZ4R92N0QDJXRDSA7X.jpeg";
    item['tag_2'] = "Singer/Songwriter";
    item['Post_Code'] = "2317hh";
    item['street'] = "Ambrosiadal";
    item['id'] = "419";
    item['country'] = "";
    item['created_at'] = "1664207558823";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['audio_3'] = {"mime":"application/octet-stream","size":56930342,"meta":{},"path":"/vault/0ZYxcr3W/SWX5h8KsUZKc3XTd97oW-N6cRHo/Zdif0A../01GEPJ04APB6A4Y07FQ134G7BT.binary.mp3.mp3","type":"","name":"Far From Home.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/SWX5h8KsUZKc3XTd97oW-N6cRHo/Zdif0A../01GEPJ04APB6A4Y07FQ134G7BT.binary.mp3.mp3"};
    item['whatsapp'] = "https://wa.me/+31642180957";
    item['tele'] = "+31642180957";
    item['profile_pic'] = datasheet_sheet5_row18_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Q6eL4ltjLjnB0biWJLPTGvpWfuU/7oH1uw../01GGTG0GZ0V8DQVWGNDRNGFW7A.jpeg";
    item['street_number'] = "16";
    item['audio_2'] = {"mime":"application/octet-stream","size":11535590,"meta":{},"path":"/vault/0ZYxcr3W/r5MpH9IbDYrLj6iX9NuxUtjej-o/9bB1MQ../01GEPHXT69TDNXF1SYBTSNV3CQ.mp3.mp3","type":"","name":"Hope.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/r5MpH9IbDYrLj6iX9NuxUtjej-o/9bB1MQ../01GEPHXT69TDNXF1SYBTSNV3CQ.mp3.mp3"};
    item['perform_acoustic'] = "0";
    item['band_name'] = "Kat Carson";
    item['type'] = "Musician/Band";
    item['website'] = "Katcarson.com";
    item['bands_bio'] = "Trained as a Classical and Early Music performer, Kat's work is an experimentation delving into the soundscapes of folk, classical and indie music. She combines traditional folk tunes, Classical repertoire and her own songwriting and poetry with the intriguing sound of her Appalachian dulcimer.";
    item['press_photo_3'] = datasheet_sheet5_row18_press_photo_3;
    item['email'] = "katcarsonsoprano@gmail.com";
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/4zVxwohLAj08e13jP0RtPQCwNgA/a4qk1w../01GGTG0WV3M4W19YAN5YV5QV92.jpeg";
    item['Main_Genre'] = "";
    item['tag_3'] = "Folk music";
    item['Insta_followers'] = "392";
    item['Facebook_followers'] = "336";
    item['Instagram'] = "Instagram.com/katcarsonmusic";
    item['audio_1'] = {"mime":"application/octet-stream","size":12154170,"meta":{},"path":"/vault/0ZYxcr3W/oaOJed33wl5rIXBz-VC46yb6xEA/O94UtA../01GEPHW5TFQ3Y9XQ71HZJBSG0F.mp3.mp3","type":"","name":"Love you.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/oaOJed33wl5rIXBz-VC46yb6xEA/O94UtA../01GEPHW5TFQ3Y9XQ71HZJBSG0F.mp3.mp3"};
    item['tag_1'] = "Folk music";
    item['press_photo_1'] = datasheet_sheet5_row18_press_photo_1;
    item['city'] = "Leiden";
    item['maingenreID'] = "";
    item['you_are_dj'] = "0";
    item['members'] = "I’m a solo artist";
    item['province'] = "Zuid-Holland";
    item['pressphoto3_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/8cIHCd0Gk2YnusqFAYRes7TEz0U/iijhZg../01GGTG1PNJGZPB384NSQ0ZDWQ2.jpeg";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "31";
    item['Spotify_link'] = "https://open.spotify.com/artist/2s3d9npzqLUIPTCInbxXOs?si=HYZpZ9EYS-KOwXuK0qPJmg";
    item['press_photo_2'] = datasheet_sheet5_row18_press_photo_2;
    item['Price_Range'] = "Negotiable";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/HDforDP";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/GikZ6yg3ImtbcbQuRyPGDArtQ8M/tULXtw../01GDXE0XSFSM97D2QD7HSMR4W2.jpeg";
    item['tag_2'] = "";
    item['Post_Code'] = "3024BJ";
    item['street'] = "Pieter de Hoochweg";
    item['id'] = "421";
    item['country'] = "";
    item['created_at'] = "1664213622828";
    item['facebook_oauth'] = {"id":0,"email":"","name":""};
    item['whatsapp'] = "https://wa.me/+31640350614";
    item['tele'] = "0640350614";
    item['profile_pic'] = datasheet_sheet5_row19_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/RiV3T9uQ1rZB3TW4wqd4HQTZvLU/zCS1TA../01GDXEABR4TGQHP0RZB2KQZ4S3.jpeg";
    item['street_number'] = "114B";
    item['audio_2'] = {"mime":"audio/mpeg","size":4111601,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":128000},"duration":256.94},"path":"/vault/0ZYxcr3W/O_9ylViwNJjkJVOMFoMWBtOb5jc/qRaS6Q../DIY.mp3","type":"audio","name":"DIY.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/O_9ylViwNJjkJVOMFoMWBtOb5jc/qRaS6Q../DIY.mp3"};
    item['perform_acoustic'] = "0";
    item['band_name'] = "Hearing Dogs For Deaf People";
    item['type'] = "Musician/Band";
    item['website'] = "https://www.facebook.com/HDforDP";
    item['bands_bio'] = "Gooi de muzikale invloeden van The Beatles, The Cure, Pixies, Ramones, Nirvana, Blur en David Bowie in een blender. Voeg een mooi stevig laagje Sonic Youth en Smashing Pumpkins toe en je hebt het geluid van de Hearing Dogs for Deaf People vastgelegd.\nHun sound is een muzikale reis van 3 decennia van de Grunge-sound van de jaren 90 via de New Wave van de jaren 80 tot de Punk-sound aan het eind van de jaren 70. Maak je veiligheidsgordels vast en geniet van de  rit!";
    item['email'] = "thomas_vermeij76@hotmail.com";
    item['pressphoto2_url'] = "";
    item['Main_Genre'] = "";
    item['tag_3'] = "";
    item['Insta_followers'] = "616";
    item['Facebook_followers'] = "668";
    item['Instagram'] = "https://www.instagram.com/thehearingdogs/";
    item['audio_1'] = {"mime":"audio/mpeg","size":3083421,"meta":{"audio":{"codec":"mp3","freq":44100,"bitrate":128000},"duration":192.68},"path":"/vault/0ZYxcr3W/HKb8gpE_AO688hcUsLNi8efZxFI/v1icFw../HAIKU.mp3","type":"audio","name":"HAIKU.mp3","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/HKb8gpE_AO688hcUsLNi8efZxFI/v1icFw../HAIKU.mp3"};
    item['tag_1'] = "Indie rock";
    item['press_photo_1'] = datasheet_sheet5_row19_press_photo_1;
    item['city'] = "Rotterdam";
    item['maingenreID'] = "";
    item['members'] = "";
    item['video'] = {"mime":"video/mp4","size":32520196,"meta":{"video":{"res":"1280x720","par":false,"postHeight":720,"codec":"h264 (Main) (avc1 / 0x31637661)","dar":1.777777777777778,"fps":29.97,"postWidth":1280,"bitrate":8300000,"profile":"yuv420p(tv"},"duration":30.17,"audio":{"codec":"aac (LC) (mp4a / 0x6134706D)","freq":48000,"bitrate":317000}},"path":"/vault/0ZYxcr3W/XX6Hxo77V8vHxrc9kJogvIuNYg0/nDEscA../HD4DP.mp4","type":"video","name":"HD4DP.mp4","url":"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/XX6Hxo77V8vHxrc9kJogvIuNYg0/nDEscA../HD4DP.mp4"};
    item['province'] = "Zuid-Holland";
    item['you_are_dj'] = "0";
    item['pressphoto3_url'] = "";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "56";
    item['Spotify_link'] = "https://open.spotify.com/artist/5w0auP24ifwVWtCymEvPNo?si=6uPZtducQVmlcDBRJp-AYQ&utm_source=copy-link";
    item['Price_Range'] = "";
    item.key = key++;
  }

  
  urlFromOptions(options) {
    const baseUrl = "https://xaf3-miy8-pkoo.f2.xano.io/api:lnQlUfUs";
    
    let path = options.servicePath || '';
    if (path.length > 0 && path.substr(0, 1) !== '/' && baseUrl.substr(baseUrl.length - 1, 1) !== '/')
      path = '/'+path;
  
    let query = options.query || '';
    if (query.length > 0) {
      let dataSlots = options.dataSlots || {};
      query = "?" + this.expandSlotTemplateString(query, dataSlots);
    }
  
    return baseUrl + path + query; 
  }
  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  addItem(item, options) {
    super.addItem(item, options);
    
    const url = this.urlFromOptions(options);
    
    let headers = {};
    headers['Content-Type'] = 'application/json';
    let authToken = localStorage.getItem('xano_authToken');
    if (authToken != null) {
      headers['Authorization'] = 'Bearer ' + authToken;
    }
    
    const fetchOpts = {
      method: 'POST',
    	headers: headers,
    	body: JSON.stringify(item),
    };
  
    return fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          console.log("** Error sending %s to %s, response: ", fetchOpts.method, url, response);
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        this._fetchComplete(null, options);
      })
      .catch((exc) => {
        this._fetchComplete(exc, options);
      });  
  }
  
  removeItem(item, options) {
    super.removeItem(item, options);
    
    let url = this.urlFromOptions(options);
    let itemPathComp = "/" + item.id;
    if ( !url.endsWith(itemPathComp)) {
      url += itemPathComp;
    }
  
    let headers = {};
    headers['Content-Type'] = 'application/json';
    let authToken = localStorage.getItem('xano_authToken');
    if (authToken != null) {
      headers['Authorization'] = 'Bearer ' + authToken;
    }
  
    const fetchOpts = {
      method: 'DELETE',
    	headers: headers,
    	body: JSON.stringify(item),
    };
  
    return fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          console.log("** Error sending %s to %s, response: ", fetchOpts.method, url, response);
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        this._fetchComplete(null, options);
      })
      .catch((exc) => {
        this._fetchComplete(exc, options);
      });  
  }
  
  replaceItemByRowIndex(idx, item, options) {
    super.replaceItemByRowIndex(idx, item, options);
    
    let url = this.urlFromOptions(options);
    let itemPathComp = "/" + item.id;
    if ( !url.endsWith(itemPathComp)) {
      url += itemPathComp;
    }
    
    let headers = {};
    headers['Content-Type'] = 'application/json';
    let authToken = localStorage.getItem('xano_authToken');
    if (authToken != null) {
      headers['Authorization'] = 'Bearer ' + authToken;
    }
  
    const fetchOpts = {
      method: 'POST',
    	headers: headers,
    	body: JSON.stringify(item),
    };
  
    return fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          console.log("** Error sending %s to %s, response: ", fetchOpts.method, url, response);
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        this._fetchComplete(null, options);
      })
      .catch((exc) => {
        this._fetchComplete(exc, options);
      });  
  }
  
  loadFromJson(json) {
    super.loadFromJson(json);
  
    /*for (let row of this.items) {
      for (let key of Object.keys(row)) {
        let val = row[key];
        if (val instanceof Object && val.type === 'image') {
          row[key] = "https://xaf3-miy8-pkoo.f2.xano.io" + val.path;
        }
      }
    }*/
  }
}
