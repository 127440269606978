import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elBackground from './images/LoginGateScreen_elBackground_470624.jpg';
import Colofon from './Colofon';
import img_elTapsodyLogo2x from './images/GigApplicantsScreen_elTapsodyLogo2x_518992.png';
import img_elInstagram from './images/GigApplicantsScreen_elInstagram_770201.png';
import img_elFacebook from './images/GigApplicantsScreen_elFacebook_791336.png';
import img_elWhatsapp from './images/GigApplicantsScreen_elWhatsapp_1039580.png';
import img_elYoutube from './images/GigApplicantsScreen_elYoutube_415351.png';
import img_elEyeClosed from './images/LoginGateScreen_elEyeClosed_912267.png';
import img_elEyeOpen from './images/LoginGateScreen_elEyeOpen_714836.png';
import img_elLogInButton from './images/LoginGateScreen_elLogInButton_731639.png';

// UI framework component imports
import Checkbox from 'muicss/lib/react/checkbox';

export default class LoginGateScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elTapsodyLogo2x = async () => {
    window.location = 'https://www.tapsody.app';
  
  }
  
  
  onClick_elInstagram = async () => {
    window.open('https://www.instagram.com/tapsody.app/', '_blank');
  
  }
  
  
  onClick_elFacebook = async () => {
    window.open('https://www.facebook.com/tapsodyapp', '_blank');
  
  }
  
  
  onClick_elWhatsapp = async () => {
    window.open('https://wa.me/31638243187', '_blank');
  
  }
  
  
  onClick_elYoutube = async () => {
    window.open('https://www.youtube.com/channel/UCQVklfCib-ZA6qlinVC1Wzg', '_blank');
  
  }
  
  
  onClick_elLogInCardCopy = async () => {
    // 'Unlock gate' action.
    const dataFromElements = {  // This object contains the data collected from the UI
      checkbox: this.getValue_elCheckbox(),
      pleaseLogInTitle: this.context.locStrings.logingate_pleaselogintitle_223606,
      textBlock: this.context.locStrings.logingate_textblock_941242,
      emailInputField: this.getValue_elEmailInputField(),
      passwordInputField: this.getValue_elPasswordInputField(),
    };
    
    this.sendLogin(dataFromElements, (err) => {
      if (err) {
        console.log('** login failed: ', err);
        sessionStorage.setItem('loginStatus_loginGate', 'failed');
        this.context.appActions.goToScreen('loginGate', this.context.baseScreenId, { errorText: ''+err });
      } else {
        sessionStorage.setItem('loginStatus_loginGate', 'active');
        this.context.appActions.goToScreen('gigApplicants', this.context.baseScreenId);
      }
    });
  
  }
  
  
  onClick_elEyeClosed = async () => {
  
  }
  
  
  onClick_elEyeOpen = async () => {
  
  }
  
  
  textInputChanged_elPasswordInputField = (event) => {
    this.setState({passwordInputField: event.target.value});
  }
  
  getValue_elPasswordInputField = () => {
    return this.state.passwordInputField || '';
  }
  
  textInputChanged_elEmailInputField = (event) => {
    this.setState({emailInputField: event.target.value});
  }
  
  getValue_elEmailInputField = () => {
    return this.state.emailInputField || '';
  }
  
  
  getValue_elCheckbox = () => {
    return this.state.checkbox !== undefined ? this.state.checkbox : 'true';
  }
  
  checkboxChanged_elCheckbox = (event) => {
    this.setState({checkbox: (event.target.checked ? 'true' : 'false')});
  }
  
  onClick_elLogInButton = async () => {
    // 'Unlock gate' action.
    const dataFromElements = {  // This object contains the data collected from the UI
      checkbox: this.getValue_elCheckbox(),
      pleaseLogInTitle: this.context.locStrings.logingate_pleaselogintitle_223606,
      textBlock: this.context.locStrings.logingate_textblock_941242,
      emailInputField: this.getValue_elEmailInputField(),
      passwordInputField: this.getValue_elPasswordInputField(),
    };
    
    this.sendLogin(dataFromElements, (err) => {
      if (err) {
        console.log('** login failed: ', err);
        sessionStorage.setItem('loginStatus_loginGate', 'failed');
        this.context.appActions.goToScreen('loginGate', this.context.baseScreenId, { errorText: ''+err });
      } else {
        sessionStorage.setItem('loginStatus_loginGate', 'active');
        this.context.appActions.goToScreen('gigApplicants', this.context.baseScreenId);
      }
    });
  
  }
  
  
  
  sendLogin = (dataFromElements, finishedCallback) => {
    this.context.appActions.setState({loading: true});
  
    const url = "https://xaf3-miy8-pkoo.f2.xano.io/api:lnQlUfUs/gigsprovidersauth/login";
    
    const fetchOpts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "email": this.getValue_elEmailInputField(),
        "password": this.getValue_elPasswordInputField(),
      }),
    };
    
    fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        let authToken = json.authToken;
        localStorage.setItem('xano_authToken', authToken);
        let time = (new Date()).getTime();
        localStorage.setItem('xano_loginTimestamp', time);
  
        
        fetch("https://xaf3-miy8-pkoo.f2.xano.io/api:lnQlUfUs/gigsprovidersauth/me", {method: 'GET', headers: { 'Authorization': 'Bearer '+authToken }})
          .then((response) => {
            if (response.status >= 400) {
              throw new Error("Server error: "+response.status);
            }
            return response.json();
        })
        .then((json) => {
          let userId = json.gigs_id.toString();
          ((v) => { this.context.appActions.updateDataSlot("ds_SlotUserID", v) })(userId);
          localStorage.setItem('xano_userId', userId);
          this.context.appActions.setState({loading: false});
          finishedCallback(null);
        })
      
      })
      .catch((exc) => {
        this.context.appActions.setState({loading: false});
        finishedCallback(exc.message);
      });
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      backgroundImage: 'url('+img_elBackground+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elCard = {
      borderRadius: '30.0px',
     };
    const style_elCard_outer = {
      boxSizing: 'border-box',
      backgroundColor: 'white',
      borderRadius: '30.0px',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    const style_elTapsodyLogo2x = {
      height: 'auto',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elInstagram = {
      backgroundImage: 'url('+img_elInstagram+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elFacebook = {
      backgroundImage: 'url('+img_elFacebook+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elWhatsapp = {
      backgroundImage: 'url('+img_elWhatsapp+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elYoutube = {
      backgroundImage: 'url('+img_elYoutube+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elLogInCardCopy = {
      cursor: 'pointer',
      pointerEvents: 'auto',
      display: 'none',
     };
    
    const style_elInputFieldBackground = {
      background: 'rgba(0, 0, 0, 0.100)',
      borderRadius: '10.0px',
     };
    
    const style_elInputFieldBackgroundCopy2 = {
      background: 'rgba(0, 0, 0, 0.100)',
      borderRadius: '10.0px',
     };
    const style_elEyeClosed = {
      backgroundImage: 'url('+img_elEyeClosed+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
      display: 'none',
     };
    const style_elEyeOpen = {
      backgroundImage: 'url('+img_elEyeOpen+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
      display: 'none',
     };
    
    const style_elPasswordInputField = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_elEmailInputField = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elTextBlock = {
      color: '#555555',
      textAlign: 'right',
      display: 'none',
     };
    const style_elPleaseLogInTitle = {
      fontSize: 32.0,
      color: 'black',
      textAlign: 'left',
     };
    
    let checked_checkbox = this.getValue_elCheckbox();
    
    const style_elCheckbox = {
      color: '#555555',
      cursor: 'pointer',
      pointerEvents: 'auto',
      display: 'none',
     };
    const style_elLogInButton = {
      backgroundImage: 'url('+img_elLogInButton+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen LoginGateScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground} />
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elCard" style={style_elCard_outer}>
            <div className="cardBg" style={style_elCard} />
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps elColofon">
              <Colofon ref={(el)=> this._elColofon = el} />
            </div>
            <img className="elTapsodyLogo2x" style={style_elTapsodyLogo2x} src={img_elTapsodyLogo2x} alt="" onClick={this.onClick_elTapsodyLogo2x}  />
            <div className="elInstagram" style={style_elInstagram} onClick={this.onClick_elInstagram}  />
            <div className="elFacebook" style={style_elFacebook} onClick={this.onClick_elFacebook}  />
            <div className="elWhatsapp" style={style_elWhatsapp} onClick={this.onClick_elWhatsapp}  />
            <div className="elYoutube" style={style_elYoutube} onClick={this.onClick_elYoutube}  />
            <div className="hasNestedComps elLogInCardCopy" style={style_elLogInCardCopy} onClick={this.onClick_elLogInCardCopy} >
              {/* WARNING: element 'Log in card copy' in 'Login gate' has no component set */}
            </div>
            <div className="elInputFieldBackground" style={style_elInputFieldBackground} />
            <div className="elInputFieldBackgroundCopy2" style={style_elInputFieldBackgroundCopy2} />
            <div className="elEyeClosed" style={style_elEyeClosed} onClick={this.onClick_elEyeClosed}  />
            <div className="elEyeOpen" style={style_elEyeOpen} onClick={this.onClick_elEyeOpen}  />
            <input className="baseFont elPasswordInputField" style={style_elPasswordInputField} type="password" placeholder={this.context.locStrings.logingate_passwordinputfield_817478} onChange={this.textInputChanged_elPasswordInputField} value={this.getValue_elPasswordInputField()}  />
            <input className="baseFont elEmailInputField" style={style_elEmailInputField} type="email" placeholder={this.context.locStrings.logingate_emailinputfield_8276} onChange={this.textInputChanged_elEmailInputField} value={this.getValue_elEmailInputField()}  />
            <div className="actionFont elTextBlock" style={style_elTextBlock}>
              <div>{this.context.locStrings.logingate_textblock_941242}</div>
            </div>
            <div className="font-SFProTextBold  elPleaseLogInTitle" style={style_elPleaseLogInTitle}>
              <div>{this.context.locStrings.logingate_pleaselogintitle_223606}</div>
            </div>
            <Checkbox className="actionFont elCheckbox" style={style_elCheckbox}  defaultChecked label={this.context.locStrings.logingate_checkbox_146863} checked={checked_checkbox === 'true' || checked_checkbox === true || ''+checked_checkbox === '1'}  onChange={this.checkboxChanged_elCheckbox} />
            <div className="elLogInButton" style={style_elLogInButton} onClick={this.onClick_elLogInButton}  />
          </div>
        </div>
      </div>
    )
  }
  
}
