import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elBackground2 from './images/GigApplicantsScreen_elBackground2_826796.jpg';
import GigDataCard from './GigDataCard';
import BandDataCard from './BandDataCard';
import Colofon from './Colofon';
import img_elYoutube from './images/GigApplicantsScreen_elYoutube_415351.png';
import img_elWhatsapp from './images/GigApplicantsScreen_elWhatsapp_1039580.png';
import img_elFacebook from './images/GigApplicantsScreen_elFacebook_791336.png';
import img_elInstagram from './images/GigApplicantsScreen_elInstagram_770201.png';
import img_elTapsodyLogo2x from './images/GigApplicantsScreen_elTapsodyLogo2x_518992.png';
import img_elLogOutButton from './images/GigApplicantsScreen_elLogOutButton_380207.png';

export default class GigApplicantsScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.context.appActions.dataSheets['sheet5'];
      let serviceOptions = this.context.appActions.serviceOptions_sheet5;
      if ( !this.context.appActions.dataSheetLoaded['sheet5']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("/gig_applicants_list/$slot('ds_SlotUserID')", this.context.appActions.dataSlots);
        this.context.appActions.loadData_xano1(dataSheet, serviceOptions, true);
        this.context.appActions.dataSheetLoaded['sheet5'] = true;
      }
      this._dataSheetLoadTimer_sheet5 = setInterval(() => {  // Reload data regularly
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("/gig_applicants_list/$slot('ds_SlotUserID')", this.context.appActions.dataSlots);
        this.context.appActions.loadData_xano1(dataSheet, serviceOptions, false);
      }, 10000);
    }
    {
      let dataSheet = this.context.appActions.dataSheets['sheet6'];
      let serviceOptions = this.context.appActions.serviceOptions_sheet6;
      if ( !this.context.appActions.dataSheetLoaded['sheet6']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("/gigs/$slot('ds_SlotUserID')", this.context.appActions.dataSlots);
        this.context.appActions.loadData_xano1(dataSheet, serviceOptions, true);
        this.context.appActions.dataSheetLoaded['sheet6'] = true;
      }
      this._dataSheetLoadTimer_sheet6 = setInterval(() => {  // Reload data regularly
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("/gigs/$slot('ds_SlotUserID')", this.context.appActions.dataSlots);
        this.context.appActions.loadData_xano1(dataSheet, serviceOptions, false);
      }, 10000);
    }
  }

  componentWillUnmount() {
    clearInterval(this._dataSheetLoadTimer_sheet5);
    clearInterval(this._dataSheetLoadTimer_sheet6);
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  
  onClick_elYoutube = async () => {
    window.open('https://www.youtube.com/channel/UCQVklfCib-ZA6qlinVC1Wzg', '_blank');
  
  }
  
  
  onClick_elWhatsapp = async () => {
    window.open('https://wa.me/31638243187', '_blank');
  
  }
  
  
  onClick_elFacebook = async () => {
    window.open('https://www.facebook.com/tapsodyapp', '_blank');
  
  }
  
  
  onClick_elInstagram = async () => {
    window.open('https://www.instagram.com/tapsody.app/', '_blank');
  
  }
  
  
  onClick_elTapsodyLogo2x = async () => {
    window.location = 'https://www.tapsody.app';
  
  }
  
  
  onClick_elLogOutButton = async () => {
    // 'Sign out (lock gate)' action.
    this.clearSavedLogin();
    sessionStorage.setItem('loginStatus_loginGate', 'inactive');
    this.context.appActions.goToScreen('loginGate', this.context.baseScreenId);
  
  }
  
  
  
  clearSavedLogin = () => {
    localStorage.removeItem('xano_authToken');
    localStorage.removeItem('xano_loginTimestamp');
    localStorage.removeItem('xano_userId');
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_sheet5 = this.context.dataSheets['sheet5'];
    // eslint-disable-next-line
    const dataSheet_sheet6 = this.context.dataSheets['sheet6'];
    const style_elBackground2 = {
      backgroundImage: 'url('+img_elBackground2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elWelcomeText = {
      color: 'white',
      textAlign: 'center',
     };
    const style_elYourGigInformationText = {
      color: 'white',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'list 2'.
    let items_list2 = [];
    let listComps_list2 = {};
    items_list2 = items_list2.concat(this.context.appActions.getDataSheet('sheet6').items);
    this._elList2_items = [];
    
    const style_elList2 = {
      height: 'auto',  // This element is in scroll flow
     };
    const style_elGigDataCard_outer = {
      display: 'none',
     };
    const style_elYourGigApplicantsTexts = {
      color: 'white',
      textAlign: 'left',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.context.appActions.getDataSheet('sheet5').items);
    this._elList_items = [];
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    const style_elBandDataCard_outer = {
      display: 'none',
     };
    
    const style_elRect = {
      background: 'rgba(0, 0, 0, 0.500)',
     };
    const style_elYoutube = {
      backgroundImage: 'url('+img_elYoutube+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elWhatsapp = {
      backgroundImage: 'url('+img_elWhatsapp+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elFacebook = {
      backgroundImage: 'url('+img_elFacebook+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elInstagram = {
      backgroundImage: 'url('+img_elInstagram+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTapsodyLogo2x = {
      backgroundImage: 'url('+img_elTapsodyLogo2x+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elLogOutButton = {
      backgroundImage: 'url('+img_elLogOutButton+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen GigApplicantsScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground2" style={style_elBackground2} />
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elWelcomeText">
            <div className="headlineFont" style={style_elWelcomeText}>
              <div>{this.context.locStrings.start_textblock_890520}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_GigApplicantsScreen_elYourGigInformationText_257792">
          <div className="elYourGigInformationText">
            <div className="baseFont" style={style_elYourGigInformationText}>
              <div>{this.context.locStrings.start_textblockcopy_257792}</div>
            </div>
          </div>
          
          </div>
          <div className="hasNestedComps elList2">
            <div style={style_elList2}>
              {items_list2.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list2[row._componentId]
                    : <GigDataCard dataSheetId={'sheet6'} dataSheetRow={row} {...{ 'gig_date': row['gig_date'], 'gig_time': row['gig_time'], 'gig_location': row['gig_location'], 'gig_fee': row['gig_fee'], 'gig_genre': row['gig_genre'], }} ref={(el) => {if (el) this._elList2_items.push(el)}} />;
                return (<div key={row.key || index}>
                    {itemComp}
                  </div>);
              })}
              <div className="marker" ref={(el)=> this._elList2_endMarker = el} />
            </div>
          </div>
          
          <div className="flowRow flowRow_GigApplicantsScreen_elGigDataCard_1036532">
          <div className="hasNestedComps elGigDataCard" style={style_elGigDataCard_outer}>
            <div>
              <GigDataCard ref={(el)=> this._elGigDataCard = el} />
            </div>
          </div>
          
          <div className="elYourGigApplicantsTexts">
            <div className="baseFont" style={style_elYourGigApplicantsTexts}>
              <div>{this.context.locStrings.gigapplicants_yourgiginformationtextcopy_834696}</div>
            </div>
          </div>
          
          </div>
          <div className="hasNestedComps elList">
            <div style={style_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <BandDataCard dataSheetId={'sheet5'} dataSheetRow={row} {...{ 'profilepic_url': row['profilepic_url'], 'band_name': row['band_name'], 'city': row['city'], 'Spotify_followers': row['Spotify_followers'], 'Insta_followers': row['Insta_followers'], 'Facebook_followers': row['Facebook_followers'], 'whatsapp': row['whatsapp'], 'email': row['email'], 'Spotify_link': row['Spotify_link'], 'Instagram': row['Instagram'], 'FB_link': row['FB_link'], 'pressphoto1_url': row['pressphoto1_url'], 'pressphoto2_url': row['pressphoto2_url'], 'pressphoto3_url': row['pressphoto3_url'], 'audio_1': row['audio_1'], 'audio_1_photo': row['audio_1_photo'], 'profile_pic': row['profile_pic'], 'audio_2_photo': row['audio_2_photo'], 'audio_2': row['audio_2'], 'audio_3_photo': row['audio_3_photo'], 'audio_3': row['audio_3'], 'video': row['video'], 'members': row['members'], 'tele': row['tele'], 'Price_Range': row['Price_Range'], 'tag_1': row['tag_1'], 'tag_2': row['tag_2'], 'tag_3': row['tag_3'], 'bands_bio': row['bands_bio'], 'website': row['website'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
                return (<div key={row.key || index}>
                    {itemComp}
                  </div>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </div>
          </div>
          
          <div className="flowRow flowRow_GigApplicantsScreen_elBandDataCard_346636">
          <div className="hasNestedComps elBandDataCard" style={style_elBandDataCard_outer}>
            <div>
              <BandDataCard ref={(el)=> this._elBandDataCard = el} />
            </div>
          </div>
          
          <div className="hasNestedComps elColofon">
            <div>
              <Colofon ref={(el)=> this._elColofon = el} />
            </div>
          </div>
          
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elRect" style={style_elRect} />
            <div className="elYoutube" style={style_elYoutube} onClick={this.onClick_elYoutube}  />
            <div className="elWhatsapp" style={style_elWhatsapp} onClick={this.onClick_elWhatsapp}  />
            <div className="elFacebook" style={style_elFacebook} onClick={this.onClick_elFacebook}  />
            <div className="elInstagram" style={style_elInstagram} onClick={this.onClick_elInstagram}  />
            <div className="elTapsodyLogo2x" style={style_elTapsodyLogo2x} onClick={this.onClick_elTapsodyLogo2x}  />
            <div className="elLogOutButton" style={style_elLogOutButton} onClick={this.onClick_elLogOutButton}  />
          </div>
        </div>
      </div>
    )
  }
  
}
