// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';
import datasheet_bands_row1_profile_pic from './images/datasheet_bands_row1_profile_pic.png';
import datasheet_bands_row1_press_photo_1 from './images/datasheet_bands_row1_press_photo_1.png';
import datasheet_bands_row2_profile_pic from './images/datasheet_bands_row2_profile_pic.png';
import datasheet_bands_row3_profile_pic from './images/datasheet_bands_row3_profile_pic.png';
import datasheet_bands_row6_profile_pic from './images/datasheet_bands_row6_profile_pic.png';
import datasheet_bands_row6_press_photo_1 from './images/datasheet_bands_row6_press_photo_1.png';
import datasheet_bands_row7_press_photo_2 from './images/datasheet_bands_row7_press_photo_2.png';
import datasheet_bands_row7_profile_pic from './images/datasheet_bands_row7_profile_pic.png';
import datasheet_bands_row7_press_photo_1 from './images/datasheet_bands_row7_press_photo_1.png';
import datasheet_bands_row7_press_photo_3 from './images/datasheet_bands_row7_press_photo_3.png';
import datasheet_bands_row8_audio_2_photo from './images/datasheet_bands_row8_audio_2_photo.png';
import datasheet_bands_row8_press_photo_2 from './images/datasheet_bands_row8_press_photo_2.png';
import datasheet_bands_row8_profile_pic from './images/datasheet_bands_row8_profile_pic.png';
import datasheet_bands_row8_audio_3_photo from './images/datasheet_bands_row8_audio_3_photo.png';
import datasheet_bands_row8_press_photo_1 from './images/datasheet_bands_row8_press_photo_1.png';
import datasheet_bands_row8_press_photo_3 from './images/datasheet_bands_row8_press_photo_3.png';
import datasheet_bands_row8_audio_1_photo from './images/datasheet_bands_row8_audio_1_photo.png';
import datasheet_bands_row9_profile_pic from './images/datasheet_bands_row9_profile_pic.png';
import datasheet_bands_row9_press_photo_3 from './images/datasheet_bands_row9_press_photo_3.png';
import datasheet_bands_row9_press_photo_1 from './images/datasheet_bands_row9_press_photo_1.png';
import datasheet_bands_row9_press_photo_2 from './images/datasheet_bands_row9_press_photo_2.png';
import datasheet_bands_row10_press_photo_3 from './images/datasheet_bands_row10_press_photo_3.png';
import datasheet_bands_row10_press_photo_1 from './images/datasheet_bands_row10_press_photo_1.png';
import datasheet_bands_row10_press_photo_2 from './images/datasheet_bands_row10_press_photo_2.png';
import datasheet_bands_row11_profile_pic from './images/datasheet_bands_row11_profile_pic.png';
import datasheet_bands_row11_press_photo_3 from './images/datasheet_bands_row11_press_photo_3.png';
import datasheet_bands_row11_press_photo_1 from './images/datasheet_bands_row11_press_photo_1.png';
import datasheet_bands_row11_press_photo_2 from './images/datasheet_bands_row11_press_photo_2.png';
import datasheet_bands_row12_press_photo_2 from './images/datasheet_bands_row12_press_photo_2.png';
import datasheet_bands_row12_profile_pic from './images/datasheet_bands_row12_profile_pic.png';
import datasheet_bands_row12_press_photo_1 from './images/datasheet_bands_row12_press_photo_1.png';
import datasheet_bands_row12_press_photo_3 from './images/datasheet_bands_row12_press_photo_3.png';
import datasheet_bands_row14_press_photo_2 from './images/datasheet_bands_row14_press_photo_2.png';
import datasheet_bands_row14_profile_pic from './images/datasheet_bands_row14_profile_pic.png';
import datasheet_bands_row14_press_photo_1 from './images/datasheet_bands_row14_press_photo_1.png';
import datasheet_bands_row16_audio_2_photo from './images/datasheet_bands_row16_audio_2_photo.png';
import datasheet_bands_row16_press_photo_2 from './images/datasheet_bands_row16_press_photo_2.png';
import datasheet_bands_row16_profile_pic from './images/datasheet_bands_row16_profile_pic.png';
import datasheet_bands_row16_audio_3_photo from './images/datasheet_bands_row16_audio_3_photo.png';
import datasheet_bands_row16_press_photo_1 from './images/datasheet_bands_row16_press_photo_1.png';
import datasheet_bands_row16_audio_1_photo from './images/datasheet_bands_row16_audio_1_photo.png';
import datasheet_bands_row17_profile_pic from './images/datasheet_bands_row17_profile_pic.png';
import datasheet_bands_row17_press_photo_1 from './images/datasheet_bands_row17_press_photo_1.png';
import datasheet_bands_row17_press_photo_2 from './images/datasheet_bands_row17_press_photo_2.png';
import datasheet_bands_row18_press_photo_2 from './images/datasheet_bands_row18_press_photo_2.png';
import datasheet_bands_row18_profile_pic from './images/datasheet_bands_row18_profile_pic.png';
import datasheet_bands_row18_press_photo_1 from './images/datasheet_bands_row18_press_photo_1.png';
import datasheet_bands_row18_press_photo_3 from './images/datasheet_bands_row18_press_photo_3.png';
import datasheet_bands_row19_audio_2_photo from './images/datasheet_bands_row19_audio_2_photo.png';
import datasheet_bands_row19_press_photo_2 from './images/datasheet_bands_row19_press_photo_2.png';
import datasheet_bands_row19_profile_pic from './images/datasheet_bands_row19_profile_pic.png';
import datasheet_bands_row19_audio_3_photo from './images/datasheet_bands_row19_audio_3_photo.png';
import datasheet_bands_row19_press_photo_1 from './images/datasheet_bands_row19_press_photo_1.png';
import datasheet_bands_row19_press_photo_3 from './images/datasheet_bands_row19_press_photo_3.png';
import datasheet_bands_row19_audio_1_photo from './images/datasheet_bands_row19_audio_1_photo.png';

export default class DataSheet_bands extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Xano'.
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "";
    item['profilepic_url'] = "";
    item['tag_2'] = "";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "498";
    item['country'] = "";
    item['fans'] = "";
    item['created_at'] = "1666112823719";
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['whatsapp'] = "";
    item['tele'] = "";
    item['pressphoto1_url'] = "";
    item['street_number'] = "";
    item['Band_rating'] = "";
    item['perform_acoustic'] = "0";
    item['band_name'] = "";
    item['type'] = "Musician/Band";
    item['bands_bio'] = "";
    item['notifications'] = "";
    item['email'] = "vnsmnqp@gmail.com";
    item['Gigs'] = "";
    item['Main_Genre'] = "";
    item['tag_3'] = "";
    item['Insta_followers'] = "";
    item['Facebook_followers'] = "";
    item['pressphoto2_url'] = "";
    item['Instagram'] = "";
    item['tag_1'] = "";
    item['city'] = "";
    item['maingenreID'] = "";
    item['members'] = "";
    item['you_are_dj'] = "0";
    item['province'] = "";
    item['pressphoto3_url'] = "";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "";
    item['Spotify_link'] = "";
    item['password'] = "2c91c8e144d74acc.39765a85ba5a43871fbf02bf990487acfc4667e82aae86e7015c56e25541cecb";
    item['tag_4'] = "";
    item['Price_Range'] = "";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://Facebook.com/ONHOUBAND";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/FjFA_owL/zQeIPA../01FYQ7852QCHS33B6S23VSCA34.jpeg";
    item['fans'] = "";
    item['id'] = "277";
    item['created_at'] = "1647898022279";
    item['country'] = "NL";
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['profile_pic'] = datasheet_bands_row1_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/leFg3yub/0DZatQ../01FYQ7882D00H7Y056CCDM1WZ5.jpeg";
    item['Band_rating'] = "";
    item['perform_acoustic'] = "0";
    item['band_name'] = "Onhou";
    item['type'] = "Musician/Band";
    item['bands_bio'] = "Onhou was founded in 2016 and consists of members of Ravenwiek and Ortega. Rooted in Groningen, Onhou embodies their northern heritage by mixing desolate sludgy\nDoom riffs with miserable Post-metal atmospheres, embracing the sinister\nhistory of the soil that made them";
    item['email'] = "onhouband@gmail.com";
    item['Gigs'] = "";
    item['Main_Genre'] = "Loud Music (Metal/Punk)";
    item['Insta_followers'] = "239";
    item['Facebook_followers'] = "598";
    item['pressphoto2_url'] = "";
    item['Instagram'] = "https://www.instagram.com/onhouband/";
    item['subgenres_3'] = "Doom metal";
    item['press_photo_1'] = datasheet_bands_row1_press_photo_1;
    item['city'] = "Groningen";
    item['subgenres_2'] = "Doom metal";
    item['maingenreID'] = "1";
    item['members'] = "4 person group";
    item['video'] = "{\"mime\":\"video/mp4\",\"size\":15,\"meta\":{},\"path\":\"/vault/0ZYxcr3W/awbUhmOTsfa7NYMYC3uR4BMXswk/skBbHQ../video.mp4\",\"type\":\"video\",\"name\":\"video.mp4\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/awbUhmOTsfa7NYMYC3uR4BMXswk/skBbHQ../video.mp4\"}";
    item['province'] = "Groningen";
    item['pressphoto3_url'] = "";
    item['you_are_dj'] = "0";
    item['subgenres_1'] = "Doom metal";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "27";
    item['Spotify_link'] = "https://open.spotify.com/artist/2fNlKFqDDgWuAOhNWomHbP?si=54BcbS-LTs2RtMKjUIN1Yw";
    item['password'] = "9dabff47451ce132.15928cb53d776e26fb1f04e149461b73bb8adf5365d57a9939f4051f1ce40638";
    item['30sec_video'] = "{\"mime\":\"video/mp4\",\"size\":15,\"meta\":{},\"path\":\"/vault/0ZYxcr3W/CS48bij-/amOkfQ../var_1.mp4\",\"type\":\"video\",\"name\":\"var_1.mp4\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/CS48bij-/amOkfQ../var_1.mp4\"}";
    item['Price_Range'] = "€50 - €450";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/8OoVIsV027sFTKId_OMKCDN3p4k/x3nFtQ../01GDBTCC75XZAV2C7Q4T9PH3FP.jpeg";
    item['tag_2'] = "Trip hop";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "400";
    item['country'] = "";
    item['fans'] = "";
    item['created_at'] = "1663622220948";
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['audio_3'] = "{\"mime\":\"audio/mpeg\",\"size\":15,\"meta\":{},\"path\":\"/vault/0ZYxcr3W/XLddi6dE_hqPDyXIN-MQ8YXGv7o/eCKJrw../var_4.mp3.mp3.mp3\",\"type\":\"audio\",\"name\":\".mp3.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/XLddi6dE_hqPDyXIN-MQ8YXGv7o/eCKJrw../var_4.mp3.mp3.mp3\"}";
    item['whatsapp'] = "https://wa.me/";
    item['tele'] = "";
    item['profile_pic'] = datasheet_bands_row2_profile_pic;
    item['pressphoto1_url'] = "";
    item['street_number'] = "";
    item['audio_2'] = "{\"mime\":\"audio/mpeg\",\"size\":15,\"meta\":{},\"path\":\"/vault/0ZYxcr3W/TTvH2mLopwrvofukZ0j9bBZmSFc/oesxMQ../var_4.mp3.mp3.mp3\",\"type\":\"audio\",\"name\":\".mp3.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/TTvH2mLopwrvofukZ0j9bBZmSFc/oesxMQ../var_4.mp3.mp3.mp3\"}";
    item['band_name'] = "AMENGUR safa heraghi";
    item['perform_acoustic'] = "0";
    item['type'] = "Musician/Band";
    item['website'] = "https://linktr.ee/Amengur";
    item['Band_rating'] = "";
    item['bands_bio'] = "i'm a singer with a background in metal, rock, and trip hop. currently working on music (personal and session) and working as as a stage manager and event coordinator. available for serious projects.";
    item['notifications'] = "";
    item['email'] = "safa.serenity.12@gmail.com";
    item['Gigs'] = "";
    item['Main_Genre'] = "";
    item['tag_3'] = "Grunge";
    item['Insta_followers'] = "";
    item['Facebook_followers'] = "";
    item['pressphoto2_url'] = "";
    item['Instagram'] = "";
    item['tag_1'] = "Metal";
    item['audio_1'] = "{\"mime\":\"audio/mpeg\",\"size\":15,\"meta\":{},\"path\":\"/vault/0ZYxcr3W/ac74Gk9ou5QUPMDBGsF2l18JlF8/6_ESFw../var_4.mp3.mp3.mp3\",\"type\":\"audio\",\"name\":\".mp3.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/ac74Gk9ou5QUPMDBGsF2l18JlF8/6_ESFw../var_4.mp3.mp3.mp3\"}";
    item['city'] = "Rotterdam";
    item['maingenreID'] = "";
    item['members'] = "I’m a solo artist";
    item['you_are_dj'] = "0";
    item['province'] = "Zuid-Holland";
    item['pressphoto3_url'] = "";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "";
    item['Spotify_link'] = "";
    item['password'] = "667404381c45eba8.c45f96bc610083231cf8f35767e8d290b5f33eeec4dad71b2ccbf0f6a93c4d3c";
    item['tag_4'] = "";
    item['Price_Range'] = "Negotiable";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/AEQ6i6qFHrJoIhTA4r65m6v4Hu4/lwwPOg../01GF0HV7EJECC43BDE6PT4T7QT.jpeg";
    item['tag_2'] = "Pop";
    item['Post_Code'] = "";
    item['street'] = "Utrecht";
    item['id'] = "470";
    item['country'] = "";
    item['fans'] = "";
    item['created_at'] = "1665392068723";
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['whatsapp'] = "";
    item['tele'] = "";
    item['profile_pic'] = datasheet_bands_row3_profile_pic;
    item['pressphoto1_url'] = "";
    item['street_number'] = "";
    item['Band_rating'] = "";
    item['perform_acoustic'] = "0";
    item['band_name'] = "Reyck";
    item['type'] = "Musician/Band";
    item['bands_bio'] = "Dutch pop/rock band Reyck makes relatible songs. The 4 guys are in their twenties and love nothing more than making bangers.";
    item['notifications'] = "";
    item['email'] = "reyck@hotmail.com";
    item['Gigs'] = "";
    item['Main_Genre'] = "";
    item['tag_3'] = "Indie pop";
    item['Insta_followers'] = "";
    item['Facebook_followers'] = "";
    item['pressphoto2_url'] = "";
    item['Instagram'] = "";
    item['tag_1'] = "Pop rock";
    item['city'] = "Utrecht";
    item['maingenreID'] = "";
    item['members'] = "4 person group";
    item['you_are_dj'] = "0";
    item['province'] = "Utrecht";
    item['pressphoto3_url'] = "";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "";
    item['Spotify_link'] = "";
    item['password'] = "eaf473e312be5068.e03577186506bf70df7b64cbe8363ef63eadc8df9a8885c74f98a1a0a2a74e36";
    item['tag_4'] = "";
    item['Price_Range'] = "Negotiable";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "";
    item['profilepic_url'] = "";
    item['tag_2'] = "";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "515";
    item['country'] = "";
    item['fans'] = "";
    item['created_at'] = "1666791701112";
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['whatsapp'] = "";
    item['tele'] = "";
    item['pressphoto1_url'] = "";
    item['street_number'] = "";
    item['Band_rating'] = "";
    item['perform_acoustic'] = "0";
    item['band_name'] = "";
    item['type'] = "Musician/Band";
    item['bands_bio'] = "";
    item['notifications'] = "";
    item['email'] = "s.f.vergeet@gmail.com";
    item['Gigs'] = "";
    item['Main_Genre'] = "";
    item['tag_3'] = "";
    item['Insta_followers'] = "";
    item['Facebook_followers'] = "";
    item['pressphoto2_url'] = "";
    item['Instagram'] = "";
    item['tag_1'] = "";
    item['city'] = "";
    item['maingenreID'] = "";
    item['members'] = "";
    item['you_are_dj'] = "0";
    item['province'] = "";
    item['pressphoto3_url'] = "";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "";
    item['Spotify_link'] = "";
    item['password'] = "8ebb7bdcf76ec26c.271bd72e9ebff2107a2a5084c09a728f00839f3ed1c37f0439c856ad3fd6a8c6";
    item['tag_4'] = "";
    item['Price_Range'] = "";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "";
    item['profilepic_url'] = "";
    item['tag_2'] = "";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "523";
    item['country'] = "";
    item['fans'] = "";
    item['created_at'] = "1666975839657";
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['whatsapp'] = "";
    item['tele'] = "";
    item['pressphoto1_url'] = "";
    item['street_number'] = "";
    item['Band_rating'] = "";
    item['perform_acoustic'] = "0";
    item['band_name'] = "";
    item['type'] = "Musician/Band";
    item['bands_bio'] = "";
    item['notifications'] = "";
    item['email'] = "sannekluiters@gmail.com";
    item['Gigs'] = "";
    item['Main_Genre'] = "";
    item['tag_3'] = "";
    item['Insta_followers'] = "";
    item['Facebook_followers'] = "";
    item['pressphoto2_url'] = "";
    item['Instagram'] = "";
    item['tag_1'] = "";
    item['city'] = "";
    item['maingenreID'] = "";
    item['members'] = "";
    item['you_are_dj'] = "0";
    item['province'] = "";
    item['pressphoto3_url'] = "";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "";
    item['Spotify_link'] = "";
    item['password'] = "1beff5a232214fa4.56ac3cb2090728371ab9cdd9fe37e4bf8fdb25d2a8fa5be3d21ad22b4480bdba";
    item['tag_4'] = "";
    item['Price_Range'] = "";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/CheckmateZutphen/";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/OGnJdljgzzsbKmMMgGR_HEx0SHQ/pQ-pjA../01GD1BYFPB8SHM2WN2FTBFKM61.jpeg";
    item['tag_2'] = "Hardcore";
    item['Post_Code'] = "7201 HD";
    item['street'] = "Ijsselkade";
    item['id'] = "381";
    item['country'] = "";
    item['fans'] = "";
    item['created_at'] = "1663271296086";
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['whatsapp'] = "https://wa.me/+31648450520";
    item['tele'] = "+31648450520";
    item['profile_pic'] = datasheet_bands_row6_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/2fPDK9TBDodISGpZKOJph2nMnbk/SoQ-iA../01GD1CQA6F1PYZW5ZMAADHE8T8.png";
    item['street_number'] = "65";
    item['Band_rating'] = "";
    item['perform_acoustic'] = "0";
    item['band_name'] = "Checkmate";
    item['type'] = "Musician/Band";
    item['website'] = "https://youtube.com/user/CheckmateZutphen";
    item['bands_bio'] = "Going strong since 2001";
    item['notifications'] = "";
    item['email'] = "mddboer@hotmail.com";
    item['Gigs'] = "";
    item['Main_Genre'] = "";
    item['tag_3'] = "Heavy metal";
    item['Insta_followers'] = "163";
    item['Facebook_followers'] = "205";
    item['pressphoto2_url'] = "";
    item['Instagram'] = "https://instagram.com/checkmate_hardcore?igshid=YmMyMTA2M2Y=";
    item['tag_1'] = "Metal/Punk crossover";
    item['press_photo_1'] = datasheet_bands_row6_press_photo_1;
    item['city'] = "Eefde";
    item['maingenreID'] = "";
    item['members'] = "4 person group";
    item['video'] = "{\"mime\":\"video/mp4\",\"size\":61834960,\"meta\":{\"video\":{\"res\":\"640x352\",\"par\":false,\"postHeight\":352,\"codec\":\"h264 (High) (avc1 / 0x31637661)\",\"dar\":false,\"fps\":27.39,\"postWidth\":640,\"bitrate\":1175000,\"profile\":\"yuv420p(tv\"},\"duration\":344.68,\"audio\":{\"codec\":\"aac (LC) (mp4a / 0x6134706D)\",\"freq\":48000,\"bitrate\":256000}},\"path\":\"/vault/0ZYxcr3W/SXHILzTKSCLw1mYo_r6L6b6fThE/cGWKfA../video.mp4\",\"type\":\"video\",\"name\":\"video.mp4\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/SXHILzTKSCLw1mYo_r6L6b6fThE/cGWKfA../video.mp4\"}";
    item['province'] = "Gelderland";
    item['you_are_dj'] = "0";
    item['pressphoto3_url'] = "";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "1079";
    item['Spotify_link'] = "https://open.spotify.com/album/6fQxo0SNtBwK6f9zhevIDA?si=JSC5bi7hSW6KETsc6MVX2A&utm_source=whatsapp";
    item['password'] = "d322cdf459ce58a6.eded901054fa5c6bfe13fc581f1eafff20bc0bce8c51a78210f4463b10285f80";
    item['30sec_video'] = "{\"mime\":\"video/mp4\",\"size\":61834960,\"meta\":{\"video\":{\"res\":\"640x352\",\"par\":false,\"postHeight\":352,\"codec\":\"h264 (High) (avc1 / 0x31637661)\",\"dar\":false,\"fps\":27.39,\"postWidth\":640,\"bitrate\":1175000,\"profile\":\"yuv420p(tv\"},\"duration\":344.68,\"audio\":{\"codec\":\"aac (LC) (mp4a / 0x6134706D)\",\"freq\":48000,\"bitrate\":256000}},\"path\":\"/vault/0ZYxcr3W/kOyNO01IouK0VIuZHMkbAgkow-U/W0SSPQ../video.mp4\",\"type\":\"video\",\"name\":\"video.mp4\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/kOyNO01IouK0VIuZHMkbAgkow-U/W0SSPQ../video.mp4\"}";
    item['tag_4'] = "";
    item['Price_Range'] = "€50 - €450";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['audio_2'] = "{\"mime\":\"audio/mpeg\",\"size\":4858144,\"meta\":{\"audio\":{\"codec\":\"mp3\",\"freq\":44100,\"bitrate\":201000},\"duration\":193.28},\"path\":\"/vault/0ZYxcr3W/rrhpsRzzdG6exm1uqWLc7I2PvqU/YM_u6A../Blasphemy.mp3\",\"type\":\"audio\",\"name\":\"Blasphemy.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/rrhpsRzzdG6exm1uqWLc7I2PvqU/YM_u6A../Blasphemy.mp3\"}";
    item['video'] = "{\"mime\":\"video/mp4\",\"size\":6169102,\"meta\":{\"video\":{\"res\":\"640x352\",\"par\":false,\"postHeight\":352,\"codec\":\"h264 (Baseline) (avc1 / 0x31637661)\",\"dar\":false,\"fps\":30.06,\"postWidth\":640,\"bitrate\":1989000,\"profile\":\"yuv420p(tv\"},\"duration\":21.38,\"audio\":{\"codec\":\"aac (LC) (mp4a / 0x6134706D)\",\"freq\":48000,\"bitrate\":320000}},\"path\":\"/vault/0ZYxcr3W/_0ljz9LrWtkhGBOCjGQKVdeZ3bU/B5aPXA../video.mp4\",\"type\":\"video\",\"name\":\"video.mp4\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/_0ljz9LrWtkhGBOCjGQKVdeZ3bU/B5aPXA../video.mp4\"}";
    item['audio_3'] = "{\"mime\":\"audio/mpeg\",\"size\":4963809,\"meta\":{\"audio\":{\"codec\":\"mp3\",\"freq\":44100,\"bitrate\":197000},\"duration\":200.57},\"path\":\"/vault/0ZYxcr3W/Gb9iOSRkf8Xqlbf0GbVTNuR_UIg/j8on2A../Realm+of+Death.mp3\",\"type\":\"audio\",\"name\":\"Realm of Death.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Gb9iOSRkf8Xqlbf0GbVTNuR_UIg/j8on2A../Realm+of+Death.mp3\"}";
    item['tag_1'] = "Death metal";
    item['street'] = "";
    item['Spotify_link'] = "https://open.spotify.com/artist/13fOTVDit2D6wF46VVJmI0?si=GHIs0EkxRgShbmf1CAwj9g";
    item['id'] = "408";
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/bWjBvj3qeTUM1Cqua824FrQEVb4/Hx76dw../01GEPG8V0CDPZGM7YHVCKSDCY4.jpeg";
    item['Band_rating'] = "";
    item['band_name'] = "Impure";
    item['province'] = "Noord-Brabant";
    item['maingenreID'] = "";
    item['bands_bio'] = "Impure is a Dutch black/death metal band based in Breda, Formed in 2021.\nThe band consists of five members: Jesse Jansen on vocals, Enzo van der Sterren on \nlead guitar, Tim Posthumus on rhythm guitar, Abe Stadhouders on drums and Percy \nJoosen on bass. We are inspired by lots of old school black and death metal bands \n(like Marduk, Dissection, Asphyx, Dismember) and combine that with our own sound.\nWith the creative storytelling guitar solos, memorable riffs, harsh vocals, \ndevastating bass and loud fast paced drums, we create Impure. We make our music \nunique.";
    item['password'] = "cd192d92e604ce3b.8a92ba545ce31456389c821cf54c11db73eeee0b2cb88c8da76f88809f9ac935";
    item['subgenres_1'] = "";
    item['tag_2'] = "Metal";
    item['Instagram'] = "https://www.instagram.com/impure_band/";
    item['weddings_and_parties'] = "0";
    item['type'] = "Musician/Band";
    item['whatsapp'] = "https://wa.me/+31614792068";
    item['Price_Range'] = "€50 - €450";
    item['Main_Genre'] = "";
    item['press_photo_2'] = datasheet_bands_row7_press_photo_2;
    item['30sec_video'] = "{\"mime\":\"video/mp4\",\"size\":6169102,\"meta\":{\"video\":{\"res\":\"640x352\",\"par\":false,\"postHeight\":352,\"codec\":\"h264 (Baseline) (avc1 / 0x31637661)\",\"dar\":false,\"fps\":30.06,\"postWidth\":640,\"bitrate\":1989000,\"profile\":\"yuv420p(tv\"},\"duration\":21.38,\"audio\":{\"codec\":\"aac (LC) (mp4a / 0x6134706D)\",\"freq\":48000,\"bitrate\":320000}},\"path\":\"/vault/0ZYxcr3W/UAI2gi9sqgvAbKKQ2aDGSIAX43s/GciCuQ../video.mp4\",\"type\":\"video\",\"name\":\"video.mp4\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/UAI2gi9sqgvAbKKQ2aDGSIAX43s/GciCuQ../video.mp4\"}";
    item['nav_type'] = "band";
    item['FB_link'] = "https://m.facebook.com/profile.php?id=100078822462153&eav=Afbi9HPoZmZTU-EnGwFCpDwQlzw05q0rSWUeb2rN6lzGk9XfBuqbwfcbN_FS1yor8zw&tsid=0.4762347015440742&source=result&paipv=0&_rdr";
    item['country'] = "";
    item['tag_3'] = "";
    item['members'] = "5 person group";
    item['profile_pic'] = datasheet_bands_row7_profile_pic;
    item['email'] = "jesse2002jansen@live.nl";
    item['tele'] = "0614792068";
    item['created_at'] = "1664176745123";
    item['Gigs'] = "";
    item['Insta_followers'] = "351";
    item['you_are_dj'] = "0";
    item['perform_acoustic'] = "0";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/bYHtGqa5pJqA1I-kdrb9yARIZ_c/VtOYfQ../01GEPGXVS0VNEX2KBA55Z29VTQ.jpeg";
    item['Post_Code'] = "";
    item['tag_4'] = "";
    item['notifications'] = "";
    item['Facebook_followers'] = "52";
    item['fans'] = "";
    item['city'] = "Breda";
    item['pressphoto3_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/W1J8-VyLf0-uemVekcQJ4C4z8_0/6Ne3Pg../01GEPGAWZMP09WTHMCVK6FHYSW.jpeg";
    item['tribute_cover_band'] = "0";
    item['street_number'] = "";
    item['Spotify_followers'] = "39";
    item['press_photo_1'] = datasheet_bands_row7_press_photo_1;
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['press_photo_3'] = datasheet_bands_row7_press_photo_3;
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/zN3Lff8sNc5-j9H32TqEVVTNAjg/TVfJxA../01GEPGAMN8PK178G0RVH8BCYSB.jpeg";
    item['website'] = "https://linktr.ee/impure_band";
    item['audio_1'] = "{\"mime\":\"audio/mpeg\",\"size\":6032714,\"meta\":{\"audio\":{\"codec\":\"mp3\",\"freq\":44100,\"bitrate\":198000},\"duration\":242.99},\"path\":\"/vault/0ZYxcr3W/rBh5JT8nK9cDpYJc48b5IV18Uk0/8kuMfg../Eternal+Darkness.mp3\",\"type\":\"audio\",\"name\":\"Eternal Darkness.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/rBh5JT8nK9cDpYJc48b5IV18Uk0/8kuMfg../Eternal+Darkness.mp3\"}";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['audio_2'] = "{\"mime\":\"application/octet-stream\",\"size\":9520327,\"meta\":{},\"path\":\"/vault/0ZYxcr3W/Bx6PgC3i0u0KIbgtp7ixisJAA7E/DDXfZw../01GF0PQ52GM8X05W6G5E64P4BN.mp3.mp3\",\"type\":\"\",\"name\":\"Lucky Ones.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Bx6PgC3i0u0KIbgtp7ixisJAA7E/DDXfZw../01GF0PQ52GM8X05W6G5E64P4BN.mp3.mp3\"}";
    item['audio_2_genre'] = "Grunge";
    item['audio_3'] = "{\"mime\":\"application/octet-stream\",\"size\":10435915,\"meta\":{},\"path\":\"/vault/0ZYxcr3W/9wK_h_v1LLbOxUBkCvUSZsq-VzA/qLLZSA../01GF127J9A2CMD2BCK2WXNCPR3.mp3.mp3\",\"type\":\"\",\"name\":\"Beautiful Secret.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/9wK_h_v1LLbOxUBkCvUSZsq-VzA/qLLZSA../01GF127J9A2CMD2BCK2WXNCPR3.mp3.mp3\"}";
    item['tag_1'] = "Progressive";
    item['street'] = "Bergsingel";
    item['audio_1'] = "{\"mime\":\"audio/mpeg\",\"size\":15,\"meta\":{},\"path\":\"/vault/0ZYxcr3W/umoQiEdh3NqH8UpMgJFDLt2h7zs/KSRVlA../var_4.mp3.mp3.mp3\",\"type\":\"audio\",\"name\":\"Mary’s Song.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/umoQiEdh3NqH8UpMgJFDLt2h7zs/KSRVlA../var_4.mp3.mp3.mp3\"}";
    item['id'] = "1";
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/QO4fvknOmfYvquroXfhQlNDNeYI/3j3oBw../01GF0NSCCN9TPWF6YP2F1NNGJQ.jpeg";
    item['Band_rating'] = "";
    item['band_name'] = "My Own Army";
    item['province'] = "Zuid-Holland";
    item['maingenreID'] = "2";
    item['bands_bio'] = "Inspired by bands like Oceansize, Soundgarden, Elbow and Tool, My Own Army is a band that continually looks to surpass their boundaries both musically and lyrically. The band gives an energetic and dynamic live performance of approximately one hour, mixing a bombastic bass-and-drums-driven rock sound, with melodic and atmospheric vocals, backing vocals, electronic beats and wailing lead guitars.";
    item['password'] = "a91494555baca869.c439638ec09c22025fd0ac54caaacbe17c88b946bfb6faa3dbaf535ae0919b37";
    item['subgenres_1'] = "Guitar rock";
    item['tag_2'] = "Grunge";
    item['Instagram'] = "https://instagram.com/myownarmyband";
    item['weddings_and_parties'] = "0";
    item['type'] = "Musician/Band";
    item['audio_2_photo'] = datasheet_bands_row8_audio_2_photo;
    item['whatsapp'] = "https://wa.me/+31641240770";
    item['subgenres_2'] = "Grunge";
    item['Price_Range'] = "€50 - €450";
    item['audio_3_genre'] = "Progressive";
    item['Main_Genre'] = "Pop/Rock";
    item['press_photo_2'] = datasheet_bands_row8_press_photo_2;
    item['30sec_video'] = "{\"mime\":\"video/mp4\",\"size\":6850158,\"meta\":{\"video\":{\"res\":\"640x352\",\"par\":false,\"postHeight\":352,\"codec\":\"h264 (Baseline) (avc1 / 0x31637661)\",\"dar\":false,\"fps\":30.02,\"postWidth\":640,\"bitrate\":1977000,\"profile\":\"yuv420p(progressive)\"},\"duration\":24.49,\"audio\":{\"codec\":\"aac (LC) (mp4a / 0x6134706D)\",\"freq\":48000,\"bitrate\":256000}},\"path\":\"/vault/0ZYxcr3W/PAQVSdMpNKUUvINBNwh06lytA6w/9xgqyA../video.mp4\",\"type\":\"video\",\"name\":\"video.mp4\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/PAQVSdMpNKUUvINBNwh06lytA6w/9xgqyA../video.mp4\"}";
    item['nav_type'] = "band";
    item['FB_link'] = "https://m.facebook.com/myownarmy";
    item['subgenres_3'] = "Guitar pop";
    item['country'] = "NL";
    item['tag_3'] = "Guitar rock";
    item['members'] = "4 person group";
    item['profile_pic'] = datasheet_bands_row8_profile_pic;
    item['email'] = "helpdesk@tapsody.app";
    item['audio_1_genre'] = "Progressive";
    item['tele'] = "+31641240770";
    item['created_at'] = "1638894847750";
    item['Gigs'] = "";
    item['Insta_followers'] = "161";
    item['you_are_dj'] = "0";
    item['perform_acoustic'] = "1";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/4D3R3LQ3az2E0ZHeaKKEWal1IiI/YK1SlA../01GFAZDFZR0QK087JPQ7BAN237.jpeg";
    item['audio_3_photo'] = datasheet_bands_row8_audio_3_photo;
    item['Post_Code'] = "3037";
    item['Facebook_followers'] = "507";
    item['fans'] = "";
    item['city'] = "Rotterdam";
    item['pressphoto3_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/TnZFcsA6Um6eZGp_gx4ONTYO0J4/bp_Ouw../01GF0NTM2190V1K824FM7Q870P.jpeg";
    item['tribute_cover_band'] = "0";
    item['street_number'] = "212";
    item['images'] = "[]";
    item['Spotify_followers'] = "22";
    item['press_photo_1'] = datasheet_bands_row8_press_photo_1;
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['press_photo_3'] = datasheet_bands_row8_press_photo_3;
    item['audio_1_photo'] = datasheet_bands_row8_audio_1_photo;
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/DDJxdN2RlsDW_031KX6uycnQSeE/PiiZzQ../01GF0NT45QP36N9S20Z2TZN92B.jpeg";
    item['Spotify_link'] = "https://open.spotify.com/artist/1YFDCmaN3x4eIH3N4RzRie";
    item['website'] = "Https://www.myownarmy.nl";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/MokerslagBand";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/HHTaxFEpYQ_v_0N5tacM2tEK9WY/acfh8w../01GFBH0GHHXEEC2P1MRE8FH2NW.jpeg";
    item['tag_2'] = "Industrial";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "494";
    item['country'] = "";
    item['fans'] = "";
    item['created_at'] = "1665757517716";
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['whatsapp'] = "";
    item['tele'] = "";
    item['profile_pic'] = datasheet_bands_row9_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/I7SG69DxE31_5nfunZHoWzoAoLk/FW43KQ../01GFBG6KPVKMF2T5Z4KVSJ35QT.jpeg";
    item['street_number'] = "";
    item['Band_rating'] = "";
    item['perform_acoustic'] = "0";
    item['band_name'] = "Mokerslag";
    item['type'] = "Musician/Band";
    item['website'] = "https://www.facebook.com/MokerslagBand";
    item['bands_bio'] = "We are a 5-man dutch singing metalband which provides you with rythmic drums, tight guitar and bass rhytms and dutch lyrics with underlying meaning. We provide a groovy/metal vibe where people can go nuts to.";
    item['press_photo_3'] = datasheet_bands_row9_press_photo_3;
    item['notifications'] = "";
    item['email'] = "mokerslagmetal@gmail.com";
    item['Gigs'] = "";
    item['Main_Genre'] = "";
    item['tag_3'] = "Metal";
    item['Insta_followers'] = "207";
    item['Facebook_followers'] = "536";
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/tTlrxIfClQ2yH0EWsyUO_2xC6AU/WQ-CSg../01GFBGCGVQ63MQYE8NGQB588X5.jpeg";
    item['Instagram'] = "https://www.instagram.com/mokerslagmetal/";
    item['tag_1'] = "Heavy metal";
    item['press_photo_1'] = datasheet_bands_row9_press_photo_1;
    item['city'] = "Zaandam";
    item['maingenreID'] = "";
    item['members'] = "5 person group";
    item['you_are_dj'] = "0";
    item['province'] = "Noord-Holland";
    item['pressphoto3_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/SqO4hN4OF7MnoydpmzRkYbbUWi0/rE7AqA../01GFBGCV4BSHJZM39X2421H2V9.jpeg";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['audio_1_genre'] = "Metal";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "23";
    item['Spotify_link'] = "https://open.spotify.com/artist/6vcTIxVEaxZdVXdbgKpDCi?si=V3edNRiCRsOfKsJMWkGSEA";
    item['password'] = "ac93adb6ca3ae6d7.f23bcb78adeeebd86768e46969d7d20b171215df77ef27367f5c842e4ef29857";
    item['press_photo_2'] = datasheet_bands_row9_press_photo_2;
    item['30sec_video'] = "{\"mime\":\"video/mp4\",\"size\":9387286,\"meta\":{\"video\":{\"res\":\"640x352\",\"par\":false,\"postHeight\":352,\"codec\":\"h264 (Main) (avc1 / 0x31637661)\",\"dar\":1.818181818181818,\"fps\":30,\"postWidth\":640,\"bitrate\":446000,\"profile\":\"yuv420p(progressive)\"},\"duration\":129.92,\"audio\":{\"codec\":\"aac (LC) (mp4a / 0x6134706D)\",\"freq\":44100,\"bitrate\":127000}},\"path\":\"/vault/0ZYxcr3W/LQj1cthmCG6ZkeS1W1um3J6JZ3E/Ky9RqQ../video.mp4\",\"type\":\"video\",\"name\":\"video.mp4\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/LQj1cthmCG6ZkeS1W1um3J6JZ3E/Ky9RqQ../video.mp4\"}";
    item['tag_4'] = "";
    item['Price_Range'] = "Negotiable";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/toffehaan";
    item['profilepic_url'] = "";
    item['fans'] = "";
    item['id'] = "299";
    item['created_at'] = "1649685183171";
    item['country'] = "NL";
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/a6v9wAqGX12nbyW0_ip9sEyqDhU/ZmQGKw../photo1%2B%255Bcomponent_input-image%255D.png";
    item['Band_rating'] = "";
    item['perform_acoustic'] = "1";
    item['band_name'] = "Toffe Haan";
    item['type'] = "Musician/Band";
    item['bands_bio'] = "";
    item['press_photo_3'] = datasheet_bands_row10_press_photo_3;
    item['email'] = "bart@toffehaan.nl";
    item['Gigs'] = "";
    item['Main_Genre'] = "Pop/Rock";
    item['Insta_followers'] = "";
    item['Facebook_followers'] = "";
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/uVNmI9gTvAcEb9lsA5MsIu0NeBQ/CcHODA../photo1%2B%255Bcomponent_input-image%255D.png";
    item['Instagram'] = "@toffehaan";
    item['subgenres_3'] = "";
    item['press_photo_1'] = datasheet_bands_row10_press_photo_1;
    item['city'] = "Rotterdam";
    item['subgenres_2'] = "";
    item['maingenreID'] = "2";
    item['members'] = "5 person group";
    item['video'] = "{\"mime\":\"video/mp4\",\"size\":7116,\"meta\":{\"video\":{\"res\":\"1020x600\",\"par\":false,\"postHeight\":600,\"codec\":\"png\",\"dar\":1.7,\"fps\":25,\"postWidth\":1020,\"bitrate\":0,\"profile\":\"rgba(pc)\"}},\"path\":\"/vault/0ZYxcr3W/ZTIH8ib0RSDRWsQZOziCy0qxqfM/cK-ecQ../video.mp4\",\"type\":\"video\",\"name\":\"video.mp4\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/ZTIH8ib0RSDRWsQZOziCy0qxqfM/cK-ecQ../video.mp4\"}";
    item['province'] = "";
    item['pressphoto3_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/TkbxcLfA6ofY3Tgo9q1PZIggCfc/U14C8w../photo1%2B%255Bcomponent_input-image%255D.png";
    item['you_are_dj'] = "0";
    item['subgenres_1'] = "Guitar rock";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "";
    item['Spotify_link'] = "";
    item['password'] = "5599f914822ca02f.1e5435ffa3b0e18afc35b1fdb1da4bb086cf09123abd1f11d24e6b2d1006335c";
    item['press_photo_2'] = datasheet_bands_row10_press_photo_2;
    item['30sec_video'] = "<NSImage 0x6000024bd5e0 Size={1020, 600} RepProvider=<NSImageArrayRepProvider: 0x60000562c580, reps:(\n    \"NSBitmapImageRep 0x600013e587e0 Size={1020, 600} ColorSpace=(not yet loaded) BPS=8 BPP=(not yet loaded) Pixels=1020x600 Alpha=YES Planar=NO Format=(not yet loaded) CurrentBacking=nil (faulting) CGImageSource=0x6000010f9c00\"\n)>>";
    item['Price_Range'] = "€50 - €450";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/TeenageIconBand/";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Gm3hPM-0XYyTbaBJlu-4eDEdoxY/gjV-5Q../01GE1B64B4NGW1E16WE3HPKBF4.jpeg";
    item['tag_2'] = "Rock 'n Roll";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "439";
    item['country'] = "";
    item['fans'] = "";
    item['created_at'] = "1664343623120";
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['audio_3'] = "{\"mime\":\"audio/mpeg\",\"size\":15,\"meta\":{},\"path\":\"/vault/0ZYxcr3W/Z--9gBX0RBBef0zsvN0kO7tGjSU/KXhq4g../var_4.mp3.mp3.mp3\",\"type\":\"audio\",\"name\":\".mp3.mp3.mp3.mp3.mp3.mp3.mp3.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Z--9gBX0RBBef0zsvN0kO7tGjSU/KXhq4g../var_4.mp3.mp3.mp3\"}";
    item['whatsapp'] = "https://wa.me/+31611847496";
    item['tele'] = "+31611847496";
    item['profile_pic'] = datasheet_bands_row11_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/VjzfUzOxG5lRP2sFycJ4WGQF5iE/JNuGAA../01GE1BJDF1HMPKHN9T4JYWBQ6F.jpeg";
    item['street_number'] = "";
    item['audio_2'] = "{\"mime\":\"audio/mpeg\",\"size\":15,\"meta\":{},\"path\":\"/vault/0ZYxcr3W/V3EA0r1v3GWzgMttb82XgEPHssc/tRkNCw../var_4.mp3.mp3.mp3.mp3\",\"type\":\"audio\",\"name\":\"X-ray Eyes.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/V3EA0r1v3GWzgMttb82XgEPHssc/tRkNCw../var_4.mp3.mp3.mp3.mp3\"}";
    item['band_name'] = "Teenage Icon";
    item['perform_acoustic'] = "0";
    item['type'] = "Musician/Band";
    item['website'] = "https://www.teenageicon.nl/";
    item['Band_rating'] = "";
    item['bands_bio'] = "Teenage Icon is a garage rock band formed in Utrecht, The Netherlands in 2016. The band consists of songwriter, guitarist and vocalist Ben van Oosterom, percussionist Gijs van der Leeden, bass guitarist Jorian Pronk and lead guitarist Bas van der Leeden. In December 2021 they released their debut single ‘X-Ray Eyes’ and in the summer of 2022 their second singel Monkey fever was released. During the coming months they will release a 3rd single and their EP \"Remember Forever\". Their music is inspired by the vibes of the 1950’s and 1960’s, from California sunsets to bluesy mornings in Louisiana. They get inspired by listening to bands like: The Animals, The Kinks, The Last Shadow Puppets, Franz Ferdinand, The Beach Boys, The Turtles,  Allah Las and Creedence Clearwater Revival.";
    item['notifications'] = "";
    item['press_photo_3'] = datasheet_bands_row11_press_photo_3;
    item['email'] = "teenageiconbookings@gmail.com";
    item['Gigs'] = "";
    item['Main_Genre'] = "";
    item['tag_3'] = "Guitar rock";
    item['Insta_followers'] = "264";
    item['Facebook_followers'] = "255";
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/tLiHEXgYQn7wj_-ikPkvhOiyHZ4/bttJAQ../01GE1BMN4RWW111AFT98P560MJ.jpeg";
    item['Instagram'] = "https://www.instagram.com/teenageiconband/";
    item['tag_1'] = "Garage";
    item['audio_1'] = "{\"mime\":\"audio/mpeg\",\"size\":15,\"meta\":{},\"path\":\"/vault/0ZYxcr3W/4TX4m2Yr54GHJG_KtXxE-3AfctM/p-8ynA../var_4.mp3.mp3.mp3.mp3\",\"type\":\"audio\",\"name\":\"Monkey Fever.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/4TX4m2Yr54GHJG_KtXxE-3AfctM/p-8ynA../var_4.mp3.mp3.mp3.mp3\"}";
    item['city'] = "Woerden";
    item['press_photo_1'] = datasheet_bands_row11_press_photo_1;
    item['maingenreID'] = "";
    item['members'] = "4 person group";
    item['you_are_dj'] = "0";
    item['province'] = "Utrecht";
    item['pressphoto3_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/5d40IwXZdGI7S3aEydP8zbaqqPE/TzTU9g../01GE1BW5P7M94CEAH303GN8517.jpeg";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "104";
    item['Spotify_link'] = "https://open.spotify.com/artist/2SX56HVJRNel844OblZG4g?si=N3uhflSaQUCL9CA33OrZNQ&nd=1";
    item['password'] = "7e664b68bbf590cf.26302a95329dfae40cd79f008d0e5c4e02610c7373a5e24131856cc341cc013c";
    item['press_photo_2'] = datasheet_bands_row11_press_photo_2;
    item['tag_4'] = "";
    item['Price_Range'] = "";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['audio_2'] = "{\"mime\":\"application/octet-stream\",\"size\":3605337,\"meta\":{},\"path\":\"/vault/0ZYxcr3W/DGZ9MIwpS0uRUgNXr8XHZTRPlRo/yEGNHw../01GF64184FEBZ2T45SQ8XZYRPY.mp3.mp3\",\"type\":\"\",\"name\":\"Geen eigen huis.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/DGZ9MIwpS0uRUgNXr8XHZTRPlRo/yEGNHw../01GF64184FEBZ2T45SQ8XZYRPY.mp3.mp3\"}";
    item['audio_2_genre'] = "Punkrock";
    item['audio_3'] = "{\"mime\":\"application/octet-stream\",\"size\":4295492,\"meta\":{},\"path\":\"/vault/0ZYxcr3W/7s9nO933WDCjvBS0f2HJYAByLBw/9dxakw../01GF645AM8KRTFVHD3GHHHCT6V.mp3.mp3\",\"type\":\"\",\"name\":\"Het Ja-woord.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/7s9nO933WDCjvBS0f2HJYAByLBw/9dxakw../01GF645AM8KRTFVHD3GHHHCT6V.mp3.mp3\"}";
    item['tag_1'] = "Guitar rock";
    item['street'] = "";
    item['Spotify_link'] = "";
    item['id'] = "486";
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/FNe6185zVQLSmL_x_Qxenef9ni8/6Cx2wA../01GF636Z3G1C09Z7XRC8MYFT1A.jpeg";
    item['Band_rating'] = "";
    item['band_name'] = "Rauwkost";
    item['province'] = "";
    item['maingenreID'] = "";
    item['bands_bio'] = "Soms moet je het leven (en jezelf) niet té serieus nemen. Dat is het levensmotto van de Haagse band Rauwkost. Dit Nederlandstalige-rocktrio weet met energieke muziek en rake teksten, rauw op de dak te vallen. Hierbij laten ze zich inspireren door bands als Hangyouth, de Dijk, Doe Maar en The Scene. Met teksten als ‘geen eigen huis, geen plek onder de zon’ en ‘waarom is die troep zo duur, voor een paar kilometer per uur?’ kan iedereen gegarandeerd meezingen en een gezonde dosis frustratie afreageren. Rauwkost wordt in augustus 2022 opgericht in het Westland en bestaat uit: bassist en zanger Karel van der Eijk, drummer Vincent Groen en gitarist Micha Koster. Deze mannen hebben al een bak ervaring en speelden o.a. in Callkoen, Tommy Brown, Pat Smith en The 16A. Volg de band op insta voor meer informatie over optredens en kijk op Soundcloud als je wil weten wat je te wachten staat live.";
    item['password'] = "c003002a0f8ff18d.cc6912a1fefdc3f8ccdf72a655e50288866f63eef6e438d7dd4049f5a3c4000a";
    item['subgenres_1'] = "";
    item['tag_2'] = "-";
    item['Instagram'] = "Https://www.instagram.com/rauwkostmusic";
    item['weddings_and_parties'] = "0";
    item['type'] = "Musician/Band";
    item['whatsapp'] = "";
    item['Price_Range'] = "Negotiable";
    item['audio_3_genre'] = "Rock 'n Roll";
    item['Main_Genre'] = "";
    item['press_photo_2'] = datasheet_bands_row12_press_photo_2;
    item['nav_type'] = "band";
    item['FB_link'] = "";
    item['country'] = "";
    item['tag_3'] = "-";
    item['members'] = "3 person group";
    item['profile_pic'] = datasheet_bands_row12_profile_pic;
    item['email'] = "karelvdeijk@gmail.com";
    item['audio_1_genre'] = "Rock 'n Roll";
    item['tele'] = "";
    item['created_at'] = "1665575580952";
    item['Gigs'] = "";
    item['Insta_followers'] = "41";
    item['you_are_dj'] = "0";
    item['perform_acoustic'] = "0";
    item['profilepic_url'] = "";
    item['Post_Code'] = "";
    item['tag_4'] = "";
    item['notifications'] = "";
    item['Facebook_followers'] = "";
    item['fans'] = "";
    item['city'] = "";
    item['pressphoto3_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/BQ1ADrfUAsbDGRwZtSve2yV39qg/iqEx6Q../01GF63QM3TADTFZH04GW5FCDD1.jpeg";
    item['tribute_cover_band'] = "0";
    item['street_number'] = "";
    item['Spotify_followers'] = "";
    item['press_photo_1'] = datasheet_bands_row12_press_photo_1;
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['press_photo_3'] = datasheet_bands_row12_press_photo_3;
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/psjj-WQ2NMaO_G02O_4wNmihrSA/LEHszQ../01GF63H8896QQ8989V07HYBJJX.jpeg";
    item['website'] = "https://soundcloud.com/rauwkost-990021432";
    item['audio_1'] = "{\"mime\":\"application/octet-stream\",\"size\":4990349,\"meta\":{},\"path\":\"/vault/0ZYxcr3W/d2_0yPQ1WOOuKMu7DEsFPkkbPB0/GMT_nw../01GF63Y1KGQ1VKF4GXASX4ZC26.mp3.mp3\",\"type\":\"\",\"name\":\"Er is geen Race.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/d2_0yPQ1WOOuKMu7DEsFPkkbPB0/GMT_nw../01GF63Y1KGQ1VKF4GXASX4ZC26.mp3.mp3\"}";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "";
    item['profilepic_url'] = "";
    item['tag_2'] = "";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "502";
    item['country'] = "";
    item['fans'] = "";
    item['created_at'] = "1666553939602";
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['whatsapp'] = "";
    item['tele'] = "";
    item['pressphoto1_url'] = "";
    item['street_number'] = "";
    item['Band_rating'] = "";
    item['perform_acoustic'] = "0";
    item['band_name'] = "";
    item['type'] = "Musician/Band";
    item['bands_bio'] = "";
    item['notifications'] = "";
    item['email'] = "momsnamemusic@gmail.com";
    item['Gigs'] = "";
    item['Main_Genre'] = "";
    item['tag_3'] = "";
    item['Insta_followers'] = "";
    item['Facebook_followers'] = "";
    item['pressphoto2_url'] = "";
    item['Instagram'] = "";
    item['tag_1'] = "";
    item['city'] = "";
    item['maingenreID'] = "";
    item['members'] = "";
    item['you_are_dj'] = "0";
    item['province'] = "";
    item['pressphoto3_url'] = "";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "";
    item['Spotify_link'] = "";
    item['password'] = "8b5de0f2215dbe6e.7e449f6dbe1d3b2aba1eb18b188092d7968ee3a7ec9eb2786b8dc12545d7d09a";
    item['tag_4'] = "";
    item['Price_Range'] = "";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['audio_2'] = "{\"mime\":\"audio/mpeg\",\"size\":12016803,\"meta\":{\"audio\":{\"codec\":\"mp3\",\"freq\":44100,\"bitrate\":259000},\"duration\":370.08},\"path\":\"/vault/0ZYxcr3W/Xa0Y9tLT75p9Cd8V_niNuzN8Fuo/FniXug../Living+Hell.mp3\",\"type\":\"audio\",\"name\":\"Living Hell.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Xa0Y9tLT75p9Cd8V_niNuzN8Fuo/FniXug../Living+Hell.mp3\"}";
    item['audio_2_genre'] = "Metal";
    item['audio_3'] = "{\"mime\":\"audio/mpeg\",\"size\":10198580,\"meta\":{\"audio\":{\"codec\":\"mp3\",\"freq\":44100,\"bitrate\":258000},\"duration\":315.82},\"path\":\"/vault/0ZYxcr3W/SNj7a5CwnVoOiKQozJSvgY9sxT4/1e_6fw../The+Reaper.mp3\",\"type\":\"audio\",\"name\":\"The Reaper.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/SNj7a5CwnVoOiKQozJSvgY9sxT4/1e_6fw../The+Reaper.mp3\"}";
    item['tag_1'] = "Metal";
    item['street'] = "";
    item['video'] = "{\"mime\":\"video/mp4\",\"size\":9982594,\"meta\":{\"video\":{\"res\":\"1280x720\",\"par\":false,\"postHeight\":720,\"codec\":\"h264 (Main) (avc1 / 0x31637661)\",\"dar\":1.777777777777778,\"fps\":30,\"postWidth\":1280,\"bitrate\":2325000,\"profile\":\"yuv420p(tv\"},\"duration\":30.06,\"audio\":{\"codec\":\"aac (LC) (mp4a / 0x6134706D)\",\"freq\":48000,\"bitrate\":317000}},\"path\":\"/vault/0ZYxcr3W/bnTtSAus_VQz4WUT4J77CjXrLoc/m_5vFg../Demonic+Blood.mp4\",\"type\":\"video\",\"name\":\"Demonic Blood.mp4\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/bnTtSAus_VQz4WUT4J77CjXrLoc/m_5vFg../Demonic+Blood.mp4\"}";
    item['id'] = "379";
    item['Spotify_link'] = "https://open.spotify.com/artist/4i4NfgCtPXjT3M8X9FGAGO?si=m5FY4KRcT0CS4mL_-JUHQQ";
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/RhHEJtt2Zl1GrU6SgVxJL0YUVm0/nG6Kfg../01GD0VJDSG1DRM16PDQZ3EQJ2X.jpeg";
    item['Band_rating'] = "";
    item['band_name'] = "Demonic Blood";
    item['province'] = "Zuid-Holland";
    item['maingenreID'] = "";
    item['bands_bio'] = "Demonic Blood is a 5 man formation with Reeuwijk as home base. The band members come from the areas of Woerden, Gouda and Rotterdam. The band was formed in 2016 and they only make their own work in the Symphonic Dark Metal genre. On 06-05-2022 the EP called Living Hell was released on all known online streaming services and on 17-09-2022 the physical EP Living Hell was released. Here you can listen to the band's most famous songs:\n- The Ripper\n- Living Hell\n- The Kraken\n- Set Me Free\nBand Formation:\nDrums: Alex Schuilenburg\nBass: Kevin Nieuwland\nVocals: Kim Sangers\nKeys: Michael Timmers\nGuitar: Niels Wakker";
    item['password'] = "7c829ee8c954b09f.128114251256af709d5b275457d2524d6edf01064c1510a4bb9868646ab6561c";
    item['subgenres_1'] = "";
    item['tag_2'] = "Death metal";
    item['Instagram'] = "https://instagram.com/demonicbloodofficial?igshid=YmMyMTA2M2Y=";
    item['weddings_and_parties'] = "0";
    item['type'] = "Musician/Band";
    item['whatsapp'] = "https://wa.me/+31653857148";
    item['Price_Range'] = "€50 - €450";
    item['Main_Genre'] = "";
    item['press_photo_2'] = datasheet_bands_row14_press_photo_2;
    item['nav_type'] = "band";
    item['FB_link'] = "https://www.facebook.com/demonicbloodofficial";
    item['country'] = "";
    item['tag_3'] = "Symphonic rock";
    item['members'] = "5 person group";
    item['profile_pic'] = datasheet_bands_row14_profile_pic;
    item['email'] = "alexanderschuilenburg@gmail.com";
    item['tele'] = "(06) 53 85 71 48";
    item['created_at'] = "1663250133962";
    item['Gigs'] = "";
    item['Insta_followers'] = "57";
    item['you_are_dj'] = "0";
    item['perform_acoustic'] = "0";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/xosx6VpXdjmI1w-k5cJ8yLYNOEk/eSqMtw../01GD0VD89B14YSJPPXXDK44E9B.jpeg";
    item['Post_Code'] = "";
    item['tag_4'] = "";
    item['notifications'] = "";
    item['Facebook_followers'] = "87";
    item['fans'] = "";
    item['city'] = "Reeuwijk";
    item['pressphoto3_url'] = "";
    item['tribute_cover_band'] = "0";
    item['street_number'] = "";
    item['Spotify_followers'] = "37";
    item['press_photo_1'] = datasheet_bands_row14_press_photo_1;
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Vr7mYtEHjdtxlbAgPqsE_B3aPdE/9P2s5g../01GD0VJV3SMWA3TMJ6Z7MA3JEP.jpeg";
    item['website'] = "";
    item['audio_1'] = "{\"mime\":\"audio/mpeg\",\"size\":7641012,\"meta\":{\"audio\":{\"codec\":\"mp3\",\"freq\":44100,\"bitrate\":259000},\"duration\":235.57},\"path\":\"/vault/0ZYxcr3W/c_YeAizl4W9VPz3jdqYiRVGCLH0/u2Ki1Q../Set+Me+Free.mp3\",\"type\":\"audio\",\"name\":\"Set Me Free.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/c_YeAizl4W9VPz3jdqYiRVGCLH0/u2Ki1Q../Set+Me+Free.mp3\"}";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "";
    item['profilepic_url'] = "";
    item['tag_2'] = "Power metal";
    item['Post_Code'] = "";
    item['street'] = "";
    item['id'] = "532";
    item['country'] = "";
    item['fans'] = "";
    item['created_at'] = "1667374710447";
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['whatsapp'] = "";
    item['tele'] = "";
    item['pressphoto1_url'] = "";
    item['street_number'] = "";
    item['Band_rating'] = "";
    item['perform_acoustic'] = "0";
    item['band_name'] = "Dance with Dragons";
    item['type'] = "Musician/Band";
    item['bands_bio'] = "";
    item['notifications'] = "";
    item['email'] = "dekruyk30@aol.com";
    item['Gigs'] = "";
    item['Main_Genre'] = "";
    item['tag_3'] = "";
    item['Insta_followers'] = "";
    item['Facebook_followers'] = "";
    item['pressphoto2_url'] = "";
    item['Instagram'] = "";
    item['tag_1'] = "Metal";
    item['city'] = "Rotterdam";
    item['maingenreID'] = "";
    item['members'] = "6 person group";
    item['you_are_dj'] = "0";
    item['province'] = "Zuid-Holland";
    item['pressphoto3_url'] = "";
    item['subgenres_1'] = "";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "0";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "";
    item['Spotify_link'] = "";
    item['password'] = "d7466e6358f41b35.2a79e8e00b3573a1a44953751c2f7cc0ae40308043b4cccc213a5ed74b759ece";
    item['tag_4'] = "";
    item['Price_Range'] = "";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['audio_2'] = "{\"mime\":\"audio/mpeg\",\"size\":6632561,\"meta\":{\"audio\":{\"codec\":\"mp3\",\"freq\":48000,\"bitrate\":260000},\"duration\":203.35},\"path\":\"/vault/0ZYxcr3W/xl9qhMhjzie2SSZd-qWi74F-Ikg/r7fQ1g../Inside+Out.mp3\",\"type\":\"audio\",\"name\":\"Inside Out.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/xl9qhMhjzie2SSZd-qWi74F-Ikg/r7fQ1g../Inside+Out.mp3\"}";
    item['Spotify_link'] = "https://open.spotify.com/artist/34SHNtSFHIS4GRSiussaSW";
    item['audio_3'] = "{\"mime\":\"audio/mpeg\",\"size\":6378302,\"meta\":{\"audio\":{\"codec\":\"mp3\",\"freq\":48000,\"bitrate\":253000},\"duration\":201.22},\"path\":\"/vault/0ZYxcr3W/Y11Uyo1ID7P4lf2JMNSyrQj-OI8/j4_QsQ../Hamstay.mp3\",\"type\":\"audio\",\"name\":\"Hamstay.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Y11Uyo1ID7P4lf2JMNSyrQj-OI8/j4_QsQ../Hamstay.mp3\"}";
    item['tag_1'] = "Grunge";
    item['street'] = "oost voorgors";
    item['id'] = "443";
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/rjDn3zq3iQYsvyWpwMsYJwa0eww/I2e34w../01GE2SP2VNXVQ0BCE8BBX9VAWT.jpeg";
    item['Band_rating'] = "";
    item['band_name'] = "Baneham";
    item['province'] = "Zuid-Holland";
    item['maingenreID'] = "";
    item['bands_bio'] = "Baneham is in staat om het vlees van je bot te blazen met hun muur van geluid. \nMet hun grungy teksten, diepe breakdowns en punky ritmes vervullen zij elk \nverlangen naar ruig en hard. De afwisseling met catchy melodieën brengt je van \nde regen in de drup naar psychedelische uitstapjes in andere muziekgenres. \nBaneham bestaat uit drie leden en is afkomstig uit de regio’s Goeree-Overflakkee \nen Zeeland\nPepijn (zang en bass), Jeroen (drums) en Bas (gitaar) zijn samengekomen om \nhun muzikale invloeden uit de rock, metal, funk en grunge samen te voegen tot \neen moderne vorm van grunge.";
    item['password'] = "729e113b3ce21ec6.d585aacc27e7069c02ad12a0d7b3ae6b0911a961ce51195f5460ae7f61b0db73";
    item['subgenres_1'] = "";
    item['tag_2'] = "Metal";
    item['Instagram'] = "https://instagram.com/banehamband?igshid=YmMyMTA2M2Y=";
    item['weddings_and_parties'] = "0";
    item['type'] = "Musician/Band";
    item['audio_2_photo'] = datasheet_bands_row16_audio_2_photo;
    item['whatsapp'] = "https://wa.me/+31634485791";
    item['Price_Range'] = "Negotiable";
    item['Main_Genre'] = "";
    item['press_photo_2'] = datasheet_bands_row16_press_photo_2;
    item['30sec_video'] = "{\"mime\":\"video/mp4\",\"size\":93961116,\"meta\":{\"video\":{\"res\":\"1920x1080\",\"par\":false,\"postHeight\":1080,\"codec\":\"h264 (Main) (avc1 / 0x31637661)\",\"dar\":1.777777777777778,\"fps\":30.01,\"postWidth\":1920,\"bitrate\":13533000,\"profile\":\"yuv420p(progressive)\"},\"duration\":52.25,\"audio\":{\"codec\":\"aac (LC) (mp4a / 0x6134706D)\",\"freq\":48000,\"bitrate\":195000}},\"path\":\"/vault/0ZYxcr3W/mJBX_HD_z7j4DVsB5Gu1023uTr8/F7Ledw../video.mp4\",\"type\":\"video\",\"name\":\"video.mp4\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/mJBX_HD_z7j4DVsB5Gu1023uTr8/F7Ledw../video.mp4\"}";
    item['nav_type'] = "band";
    item['FB_link'] = "https://www.facebook.com/Baneham/";
    item['country'] = "";
    item['tag_3'] = "Rock crossover";
    item['members'] = "3 person group";
    item['profile_pic'] = datasheet_bands_row16_profile_pic;
    item['email'] = "banehamband@gmail.com";
    item['audio_1_genre'] = "Metal";
    item['tele'] = "0634485791";
    item['created_at'] = "1664393337507";
    item['Gigs'] = "";
    item['Insta_followers'] = "121";
    item['you_are_dj'] = "0";
    item['perform_acoustic'] = "0";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Wa1Yfxr8UvvwrFEu24VcM0f4NUo/ZZ0Eng../242526812_1718570235015948_5301261479557904797_n.jpg";
    item['audio_3_photo'] = datasheet_bands_row16_audio_3_photo;
    item['Post_Code'] = "3241kd";
    item['tag_4'] = "";
    item['notifications'] = "";
    item['Facebook_followers'] = "107";
    item['fans'] = "";
    item['city'] = "Middelharnis";
    item['pressphoto3_url'] = "";
    item['tribute_cover_band'] = "0";
    item['street_number'] = "17";
    item['Spotify_followers'] = "21";
    item['press_photo_1'] = datasheet_bands_row16_press_photo_1;
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['audio_1_photo'] = datasheet_bands_row16_audio_1_photo;
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/wMdk_bq3IcfHwrC-RnLZjXuDnzM/S9JN2Q../01GE2SPMAW1CEF0ZAVJ14FH4CJ.jpeg";
    item['website'] = "https://linktr.ee/baneham";
    item['audio_1'] = "{\"mime\":\"audio/mpeg\",\"size\":9950294,\"meta\":{\"audio\":{\"codec\":\"mp3\",\"freq\":48000,\"bitrate\":266000},\"duration\":298.32},\"path\":\"/vault/0ZYxcr3W/6GNDcNGGTPA2uNsGjtJvZE_PVIo/vaM0Dg../Cowboy.mp3\",\"type\":\"audio\",\"name\":\"Cowboy.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/6GNDcNGGTPA2uNsGjtJvZE_PVIo/vaM0Dg../Cowboy.mp3\"}";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['FB_link'] = "https://www.facebook.com/denpurls";
    item['profilepic_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/F6mvltrj/HMSmAw../01FYRGJDCJW765B4XEEC9CN0DG.jpeg";
    item['fans'] = "";
    item['id'] = "278";
    item['created_at'] = "1647939027788";
    item['country'] = "NL";
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['profile_pic'] = datasheet_bands_row17_profile_pic;
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/4cBOYtGB/je2OSw../DenPurls-band-1.jpg";
    item['Band_rating'] = "";
    item['perform_acoustic'] = "1";
    item['band_name'] = "Den Purls";
    item['type'] = "Musician/Band";
    item['bands_bio'] = "Den Purls’ music could be labeled as pop-rock… but not quite. With elements of hard rock, blues, prog rock and more, the foursome has developed a unique sound that is \nboth complex and easy to listen to. The lyrics stem from experiences that many of \nyou might identify with. With several major gigs at Breda Barst, Mezz and the Breda’s Most Wanted project under their belt, the group is fired up to conquer the world with hits Like Miss Stardust and Something Bad. Raw and layered vocals, surprising drumbeats, inspired bass \ntracks and solid guitar riffs define the music of Den Purls.";
    item['email'] = "info@denpurls.com";
    item['Gigs'] = "";
    item['Main_Genre'] = "Pop/Rock";
    item['Insta_followers'] = "219";
    item['Facebook_followers'] = "656";
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/84fcLKBP/C1HJ1Q../DenPurls-band-2.jpg";
    item['Instagram'] = "https://www.intagram.com/den_purls";
    item['subgenres_3'] = "Guitar rock";
    item['press_photo_1'] = datasheet_bands_row17_press_photo_1;
    item['city'] = "Breda";
    item['subgenres_2'] = "Indie rock";
    item['maingenreID'] = "2";
    item['members'] = "4 person group";
    item['pressphoto3_url'] = "";
    item['province'] = "Noord-Brabant";
    item['you_are_dj'] = "0";
    item['subgenres_1'] = "Symphonic rock";
    item['nav_type'] = "band";
    item['weddings_and_parties'] = "1";
    item['tribute_cover_band'] = "0";
    item['Spotify_followers'] = "47";
    item['Spotify_link'] = "https://open.spotify.com/artist/7ba5CIAhUAcv26erGoN2rv?si=Z-JF0VwnSqWnOT9BCn-8Qw&utm_source=copy-link";
    item['password'] = "ec492cdc5bcc373e.f2ce0f5b754c1a02a3e73b40a517a8b6181dbce006b07c09cdbb4962f521cb18";
    item['press_photo_2'] = datasheet_bands_row17_press_photo_2;
    item['Price_Range'] = "Negotiable";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['audio_2'] = "{\"mime\":\"application/octet-stream\",\"size\":12205304,\"meta\":{},\"path\":\"/vault/0ZYxcr3W/GWwUVvYtrkRTM94li8Y-v6HPTOU/4RcFtw../01GGEYK63J6ZQMYSAT2BFFE735.mp3\",\"type\":\"\",\"name\":\"The Time.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/GWwUVvYtrkRTM94li8Y-v6HPTOU/4RcFtw../01GGEYK63J6ZQMYSAT2BFFE735.mp3\"}";
    item['audio_2_genre'] = "Pop rock";
    item['tag_1'] = "Pop rock";
    item['Spotify_link'] = "https://open.spotify.com/artist/5pw50WDAOevrHDU6DMxgHR?si=Y_VJM9eZRH-S5n7Zn5Facg";
    item['street'] = "";
    item['id'] = "509";
    item['pressphoto1_url'] = "";
    item['Band_rating'] = "";
    item['band_name'] = "Jone";
    item['province'] = "Noord-Brabant";
    item['maingenreID'] = "";
    item['bands_bio'] = "Jone set out with covering their idols B-sides. They were deadly serious about not performing as a ‘cliche’ cover band and they wanted to let the audience hear what else is out there. These self proclaimed rebels found each other musically and as friends. Performing at their own schools open mic the band members knew they were onto something good! The adrenaline and the applause got them eager for more. \n\n\nIt was time to take their own ideas and forge them into songs. The cocktail contained influences of all band members individually and it would best be described as nu-punk-pop-rock, or so they decided. Throw some K’s Choice, Nirvana, Wheatus and Red Hot Chili Peppers in a blender to get an idea. \n\n\nThey gigged a fair amount at local venues and travelled around the Netherlands to appear. Experimenting with different influences the songs became more mature and it could no longer be said that there was no cohesion. During the years they recorded different EP’s and each showed an evolution of the bands material. \n\n\nAfter over a decade of writing music and gigging life happened. The group never lost contact however and after another decade the itch became too great to be left unscratched. As soon as they got together to jam it out, they set something in motion that would result in a sweet reunion. \n\n\nNow Jone is back with the original ingredients and as soon as the blend was poured all fell back into place. Now the year was 2020 and it was time to embark on another roll.";
    item['password'] = "f8e606bffa006818.d21f040abcf96a04a7cbe17809159f621272631c914ccd06dffc1b600c78665b";
    item['subgenres_1'] = "";
    item['tag_2'] = "Funky pop";
    item['Instagram'] = "https://www.instagram.com/J1ROCKS";
    item['weddings_and_parties'] = "0";
    item['type'] = "Musician/Band";
    item['whatsapp'] = "https://wa.me/(06) 10 96 13 61";
    item['Price_Range'] = "Negotiable";
    item['Main_Genre'] = "";
    item['press_photo_2'] = datasheet_bands_row18_press_photo_2;
    item['30sec_video'] = "{\"mime\":\"video/mp4\",\"size\":5422397,\"meta\":{\"video\":{\"res\":\"848x480\",\"par\":false,\"postHeight\":480,\"codec\":\"h264 (Baseline) (avc1 / 0x31637661)\",\"dar\":false,\"fps\":30,\"postWidth\":848,\"bitrate\":1606000,\"profile\":\"yuv420p(tv\"},\"duration\":25.94,\"audio\":{\"codec\":\"aac (LC) (mp4a / 0x6134706D)\",\"freq\":44100,\"bitrate\":62000}},\"path\":\"/vault/0ZYxcr3W/fPnVd9pNQa3d8FkvqJOkaa7zOXk/Pq6eLw../video.mp4\",\"type\":\"video\",\"name\":\"video.mp4\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/fPnVd9pNQa3d8FkvqJOkaa7zOXk/Pq6eLw../video.mp4\"}";
    item['nav_type'] = "band";
    item['FB_link'] = "https://www.facebook.com/profile.php?id=100085339970978";
    item['country'] = "";
    item['tag_3'] = "Rock crossover";
    item['members'] = "4 person group";
    item['profile_pic'] = datasheet_bands_row18_profile_pic;
    item['email'] = "j1akajone@gmail.com";
    item['audio_1_genre'] = "Pop rock";
    item['tele'] = "(06) 10 96 13 61";
    item['created_at'] = "1666629340529";
    item['Gigs'] = "";
    item['Insta_followers'] = "12";
    item['you_are_dj'] = "0";
    item['perform_acoustic'] = "0";
    item['profilepic_url'] = "";
    item['Post_Code'] = "";
    item['tag_4'] = "";
    item['notifications'] = "";
    item['Facebook_followers'] = "11";
    item['fans'] = "";
    item['city'] = "Mill";
    item['pressphoto3_url'] = "";
    item['tribute_cover_band'] = "0";
    item['street_number'] = "";
    item['Spotify_followers'] = "6";
    item['press_photo_1'] = datasheet_bands_row18_press_photo_1;
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['press_photo_3'] = datasheet_bands_row18_press_photo_3;
    item['pressphoto2_url'] = "";
    item['website'] = "https://www.facebook.com/profile.php?id=100085339970978";
    item['audio_1'] = "{\"mime\":\"application/octet-stream\",\"size\":11480768,\"meta\":{},\"path\":\"/vault/0ZYxcr3W/yDKWC2WAuERpUA7ZipYalVSSXBc/dFAh4g../01GGEYH31BD446CQ2SQSP7812D.mp3\",\"type\":\"\",\"name\":\"IGLO.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/yDKWC2WAuERpUA7ZipYalVSSXBc/dFAh4g../01GGEYH31BD446CQ2SQSP7812D.mp3\"}";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['audio_2'] = "{\"mime\":\"audio/mpeg\",\"size\":6986866,\"meta\":{\"audio\":{\"codec\":\"mp3\",\"freq\":44100,\"bitrate\":192000},\"duration\":291.03},\"path\":\"/vault/0ZYxcr3W/8IXIXZeLoiv7ZzvD4lESajhMq8Q/oSrwvg../Tight+on+the+Leash.mp3\",\"type\":\"audio\",\"name\":\"Tight on the Leash.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/8IXIXZeLoiv7ZzvD4lESajhMq8Q/oSrwvg../Tight+on+the+Leash.mp3\"}";
    item['audio_2_genre'] = "Guitar rock";
    item['audio_3'] = "{\"mime\":\"audio/mpeg\",\"size\":8688380,\"meta\":{\"audio\":{\"codec\":\"mp3\",\"freq\":44100,\"bitrate\":192000},\"duration\":361.93},\"path\":\"/vault/0ZYxcr3W/DvMnKAelFsIAeQT-QVkd-xhMeTg/fvxiFQ../Now+I+Know.mp3\",\"type\":\"audio\",\"name\":\"Now I Know.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/DvMnKAelFsIAeQT-QVkd-xhMeTg/fvxiFQ../Now+I+Know.mp3\"}";
    item['tag_1'] = "Guitar rock";
    item['street'] = "Ruivendwarsstraat";
    item['video'] = "{\"mime\":\"video/mp4\",\"size\":57324503,\"meta\":{\"video\":{\"res\":\"352x640\",\"par\":false,\"postHeight\":640,\"codec\":\"h264 (Baseline) (avc1 / 0x31637661)\",\"dar\":false,\"fps\":30,\"postWidth\":352,\"bitrate\":1170000,\"profile\":\"yuv420p(tv\"},\"duration\":335.06,\"audio\":{\"codec\":\"aac (LC) (mp4a / 0x6134706D)\",\"freq\":44100,\"bitrate\":194000}},\"path\":\"/vault/0ZYxcr3W/rlv_Q-HXgHO_FFn-Wssl1eU3Q5E/kq9ohQ../video.mp4\",\"type\":\"video\",\"name\":\"video.mp4\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/rlv_Q-HXgHO_FFn-Wssl1eU3Q5E/kq9ohQ../video.mp4\"}";
    item['id'] = "483";
    item['audio_1'] = "{\"mime\":\"audio/mpeg\",\"size\":13411327,\"meta\":{\"audio\":{\"codec\":\"mp3\",\"freq\":44100,\"bitrate\":320000},\"duration\":335.23},\"path\":\"/vault/0ZYxcr3W/i_mCuFIUq1jZJ88Njk07Mceb4Bk/gfvnjw../Little+Secrets+Dirty+Lies.mp3\",\"type\":\"audio\",\"name\":\"Little Secrets Dirty Lies.mp3\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/i_mCuFIUq1jZJ88Njk07Mceb4Bk/gfvnjw../Little+Secrets+Dirty+Lies.mp3\"}";
    item['pressphoto1_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/T8sJ2NOmcZ-L_OlR2uxojFH9yMI/TUaDGg../01GF4F0PTKG42W8A2WBR93KHYF.jpeg";
    item['Band_rating'] = "";
    item['band_name'] = "FoulSons";
    item['province'] = "Zuid-Holland";
    item['maingenreID'] = "";
    item['bands_bio'] = "Uplifting psychedelic rock with grunge influences, FoulSons takes you on a journey in which dreamy atmospheric rhythm parts are punished with hard pounding riffs. The driving basslines and a charismatic frontman makes the picture completely… foul!";
    item['password'] = "f9d281da8f155b79.9b6f2f464c971fa8e4382e44a606e783c2ff01df52de9f6168c9c8cbc00ef6cc";
    item['subgenres_1'] = "";
    item['tag_2'] = "Grunge";
    item['Instagram'] = "https://www.instagram.com/foulsons";
    item['weddings_and_parties'] = "0";
    item['type'] = "Musician/Band";
    item['audio_2_photo'] = datasheet_bands_row19_audio_2_photo;
    item['whatsapp'] = "https://wa.me/+31624130502";
    item['Price_Range'] = "Negotiable";
    item['audio_3_genre'] = "Guitar rock";
    item['Main_Genre'] = "";
    item['press_photo_2'] = datasheet_bands_row19_press_photo_2;
    item['30sec_video'] = "{\"mime\":\"video/mp4\",\"size\":57324503,\"meta\":{\"video\":{\"res\":\"352x640\",\"par\":false,\"postHeight\":640,\"codec\":\"h264 (Baseline) (avc1 / 0x31637661)\",\"dar\":false,\"fps\":30,\"postWidth\":352,\"bitrate\":1170000,\"profile\":\"yuv420p(tv\"},\"duration\":335.06,\"audio\":{\"codec\":\"aac (LC) (mp4a / 0x6134706D)\",\"freq\":44100,\"bitrate\":194000}},\"path\":\"/vault/0ZYxcr3W/r_xBFg2Wxm_I1SHv-bHxTgpGWfE/lq_j0g../video.mp4\",\"type\":\"video\",\"name\":\"video.mp4\",\"url\":\"https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/r_xBFg2Wxm_I1SHv-bHxTgpGWfE/lq_j0g../video.mp4\"}";
    item['nav_type'] = "band";
    item['FB_link'] = "https://www.facebook.com/FoulSons";
    item['country'] = "";
    item['tag_3'] = "Progressive";
    item['members'] = "5 person group";
    item['profile_pic'] = datasheet_bands_row19_profile_pic;
    item['email'] = "foulsons@gmail.com";
    item['audio_1_genre'] = "Guitar rock";
    item['tele'] = "0624130502";
    item['created_at'] = "1665523240154";
    item['Gigs'] = "";
    item['Insta_followers'] = "318";
    item['you_are_dj'] = "0";
    item['perform_acoustic'] = "0";
    item['profilepic_url'] = "";
    item['audio_3_photo'] = datasheet_bands_row19_audio_3_photo;
    item['Post_Code'] = "3036DA";
    item['tag_4'] = "";
    item['notifications'] = "";
    item['Facebook_followers'] = "418";
    item['fans'] = "";
    item['city'] = "Rotterdam";
    item['pressphoto3_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/FUy2mKLJ_eXwEnbFZwLmMVXEJdI/5jvToA../01GF4F2DHBD5G0XZ5YR1FQHP5Z.jpeg";
    item['tribute_cover_band'] = "0";
    item['street_number'] = "11";
    item['Spotify_followers'] = "77";
    item['press_photo_1'] = datasheet_bands_row19_press_photo_1;
    item['facebook_oauth'] = "{\"id\":0,\"email\":\"\",\"name\":\"\"}";
    item['press_photo_3'] = datasheet_bands_row19_press_photo_3;
    item['audio_1_photo'] = datasheet_bands_row19_audio_1_photo;
    item['pressphoto2_url'] = "https://xaf3-miy8-pkoo.f2.xano.io/vault/0ZYxcr3W/Q4W8Z_O6SLb7kf7LWFEF91zPRBs/J2um9w../01GF4F1RAQ7ENQ21NHWMKWGJZX.jpeg";
    item['Spotify_link'] = "https://open.spotify.com/artist/68yoMbONO3e03L2wBkdcgN?si=t6Ho3DWWQNOGtN_OcJQvTw&utm_source=copy-link";
    item['website'] = "http://foulsons.nl/";
    item.key = key++;
  }

  
  urlFromOptions(options) {
    const baseUrl = "https://xaf3-miy8-pkoo.f2.xano.io/api:lnQlUfUs";
    
    let path = options.servicePath || '';
    if (path.length > 0 && path.substr(0, 1) !== '/' && baseUrl.substr(baseUrl.length - 1, 1) !== '/')
      path = '/'+path;
  
    let query = options.query || '';
    if (query.length > 0) {
      let dataSlots = options.dataSlots || {};
      query = "?" + this.expandSlotTemplateString(query, dataSlots);
    }
  
    return baseUrl + path + query; 
  }
  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  addItem(item, options) {
    super.addItem(item, options);
    
    const url = this.urlFromOptions(options);
    
    let headers = {};
    headers['Content-Type'] = 'application/json';
    let authToken = localStorage.getItem('xano_authToken');
    if (authToken != null) {
      headers['Authorization'] = 'Bearer ' + authToken;
    }
    
    const fetchOpts = {
      method: 'POST',
    	headers: headers,
    	body: JSON.stringify(item),
    };
  
    return fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          console.log("** Error sending %s to %s, response: ", fetchOpts.method, url, response);
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        this._fetchComplete(null, options);
      })
      .catch((exc) => {
        this._fetchComplete(exc, options);
      });  
  }
  
  removeItem(item, options) {
    super.removeItem(item, options);
    
    let url = this.urlFromOptions(options);
    let itemPathComp = "/" + item.id;
    if ( !url.endsWith(itemPathComp)) {
      url += itemPathComp;
    }
  
    let headers = {};
    headers['Content-Type'] = 'application/json';
    let authToken = localStorage.getItem('xano_authToken');
    if (authToken != null) {
      headers['Authorization'] = 'Bearer ' + authToken;
    }
  
    const fetchOpts = {
      method: 'DELETE',
    	headers: headers,
    	body: JSON.stringify(item),
    };
  
    return fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          console.log("** Error sending %s to %s, response: ", fetchOpts.method, url, response);
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        this._fetchComplete(null, options);
      })
      .catch((exc) => {
        this._fetchComplete(exc, options);
      });  
  }
  
  replaceItemByRowIndex(idx, item, options) {
    super.replaceItemByRowIndex(idx, item, options);
    
    let url = this.urlFromOptions(options);
    let itemPathComp = "/" + item.id;
    if ( !url.endsWith(itemPathComp)) {
      url += itemPathComp;
    }
    
    let headers = {};
    headers['Content-Type'] = 'application/json';
    let authToken = localStorage.getItem('xano_authToken');
    if (authToken != null) {
      headers['Authorization'] = 'Bearer ' + authToken;
    }
  
    const fetchOpts = {
      method: 'POST',
    	headers: headers,
    	body: JSON.stringify(item),
    };
  
    return fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          console.log("** Error sending %s to %s, response: ", fetchOpts.method, url, response);
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        this._fetchComplete(null, options);
      })
      .catch((exc) => {
        this._fetchComplete(exc, options);
      });  
  }
  
  loadFromJson(json) {
    super.loadFromJson(json);
  
    /*for (let row of this.items) {
      for (let key of Object.keys(row)) {
        let val = row[key];
        if (val instanceof Object && val.type === 'image') {
          row[key] = "https://xaf3-miy8-pkoo.f2.xano.io" + val.path;
        }
      }
    }*/
  }
}
