// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_gig_application extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Xano'.
    
    item = {};
    this.items.push(item);
    item['phone'] = "0629008799";
    item['booker_name'] = "Bartosz Urban";
    item['id'] = "4";
    item['created_at'] = "1662329181423";
    item['gigs_id'] = "2";
    item['user_type'] = "band";
    item['email'] = "bartek@heykaren.net";
    item['user_id'] = "370";
    item['artist_name'] = "Hey Karen";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0681269734";
    item['booker_name'] = "Tom Mourik";
    item['id'] = "5";
    item['created_at'] = "1662365617430";
    item['gigs_id'] = "2";
    item['user_type'] = "band";
    item['email'] = "cervustheband@gmail.com";
    item['user_id'] = "337";
    item['artist_name'] = "Cervus";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0619935371";
    item['booker_name'] = "Mariska Lie A Ling";
    item['id'] = "14";
    item['created_at'] = "1662397433725";
    item['gigs_id'] = "2";
    item['user_type'] = "band";
    item['email'] = "mliealing@kpnmail.nl";
    item['user_id'] = "372";
    item['artist_name'] = "Von Veh";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0653857148";
    item['booker_name'] = "Alexander Schuilenburg";
    item['id'] = "25";
    item['created_at'] = "1663250206969";
    item['gigs_id'] = "2";
    item['user_type'] = "band";
    item['email'] = "Alexanderschuilenburg@gmail.com";
    item['user_id'] = "379";
    item['artist_name'] = "Demonic Blood";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0648450520";
    item['booker_name'] = "Maarten de Boer";
    item['id'] = "27";
    item['created_at'] = "1663271409193";
    item['gigs_id'] = "2";
    item['user_type'] = "band";
    item['email'] = "Mddboer@hotmail.com";
    item['user_id'] = "381";
    item['artist_name'] = "CHECKMATE";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0654623123";
    item['booker_name'] = "Robin van der Zon";
    item['id'] = "28";
    item['created_at'] = "1663321569988";
    item['gigs_id'] = "2";
    item['user_type'] = "band";
    item['email'] = "robinvanderzon@hotmail.com";
    item['user_id'] = "380";
    item['artist_name'] = "SuffiX";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0685890996";
    item['booker_name'] = "Carmelo Leone";
    item['id'] = "29";
    item['created_at'] = "1663322596869";
    item['gigs_id'] = "2";
    item['user_type'] = "band";
    item['email'] = "7blacksides@libero.it";
    item['user_id'] = "375";
    item['artist_name'] = "7 Black Sides";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0628457555";
    item['booker_name'] = "Pascal Molenaar";
    item['id'] = "34";
    item['created_at'] = "1663367765473";
    item['gigs_id'] = "2";
    item['user_type'] = "band";
    item['email'] = "bandungboysince1942@gmail.com";
    item['user_id'] = "385";
    item['artist_name'] = "The Deaf Dollars";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0628457555";
    item['booker_name'] = "Pascal Molenaar";
    item['id'] = "35";
    item['created_at'] = "1663367832360";
    item['gigs_id'] = "1";
    item['user_type'] = "band";
    item['email'] = "bandungboysince1942@gmail.com";
    item['user_id'] = "385";
    item['artist_name'] = "The Deaf Dollars";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0682019868";
    item['booker_name'] = "Maxime Haan";
    item['id'] = "38";
    item['created_at'] = "1663411245248";
    item['gigs_id'] = "2";
    item['user_type'] = "band";
    item['email'] = "chasinghills@gmail.com";
    item['user_id'] = "382";
    item['artist_name'] = "Chasing Hills";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0682019868";
    item['booker_name'] = "Maxime Haan";
    item['id'] = "39";
    item['created_at'] = "1663411317392";
    item['gigs_id'] = "1";
    item['user_type'] = "band";
    item['email'] = "chasinghills@gmail.com";
    item['user_id'] = "382";
    item['artist_name'] = "Chasing Hills";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0620718168";
    item['booker_name'] = "Dianthe Schroor";
    item['id'] = "42";
    item['created_at'] = "1663506617049";
    item['gigs_id'] = "2";
    item['user_type'] = "band";
    item['email'] = "Dianthe24@outlook.com";
    item['user_id'] = "384";
    item['artist_name'] = "Edsattic";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0639837229";
    item['booker_name'] = "Buks Kemp";
    item['id'] = "43";
    item['created_at'] = "1663513319778";
    item['gigs_id'] = "2";
    item['user_type'] = "band";
    item['email'] = "buks.kemp@gmail.com";
    item['user_id'] = "393";
    item['artist_name'] = "Black Garden";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0647544160";
    item['booker_name'] = "Wouter Stam";
    item['id'] = "44";
    item['created_at'] = "1663536373046";
    item['gigs_id'] = "2";
    item['user_type'] = "band";
    item['email'] = "dita.official.band@gmail.com";
    item['user_id'] = "368";
    item['artist_name'] = "Death In The Afternoon";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0611864816";
    item['booker_name'] = "Emma";
    item['id'] = "45";
    item['created_at'] = "1663570830178";
    item['gigs_id'] = "2";
    item['user_type'] = "band";
    item['email'] = "graftakband@gmail.com";
    item['user_id'] = "394";
    item['artist_name'] = "Graftak";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0486032660";
    item['booker_name'] = "Udo Fransen";
    item['id'] = "46";
    item['created_at'] = "1663618470965";
    item['gigs_id'] = "2";
    item['user_type'] = "band";
    item['email'] = "braindeadband@hotmail.com";
    item['user_id'] = "399";
    item['artist_name'] = "Braindead band";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0646756276";
    item['booker_name'] = "Herman de Kok";
    item['id'] = "49";
    item['created_at'] = "1663696376994";
    item['gigs_id'] = "2";
    item['user_type'] = "band";
    item['email'] = "herdesmad@hotmail.com";
    item['user_id'] = "388";
    item['artist_name'] = "Herman de Kok";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0652277766";
    item['booker_name'] = "Mart Dumoulin";
    item['id'] = "50";
    item['created_at'] = "1663698827947";
    item['gigs_id'] = "2";
    item['user_type'] = "band";
    item['email'] = "eternal-aurora@hotmail.com";
    item['user_id'] = "374";
    item['artist_name'] = "Eternal Aurora";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0653857148";
    item['booker_name'] = "Alexander Schuilenburg";
    item['id'] = "51";
    item['created_at'] = "1663792765128";
    item['gigs_id'] = "2";
    item['user_type'] = "band";
    item['email'] = "Alexanderschuilenburg@gmail.com";
    item['user_id'] = "379";
    item['artist_name'] = "Demonic Blood";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['phone'] = "0646072016";
    item['booker_name'] = "Roan Grasmeijer";
    item['id'] = "55";
    item['created_at'] = "1664196377131";
    item['gigs_id'] = "2";
    item['user_type'] = "band";
    item['email'] = "Greenpolicy42o@gmail.com";
    item['user_id'] = "414";
    item['artist_name'] = "Green Policy";
    item.key = key++;
  }

  
  urlFromOptions(options) {
    const baseUrl = "https://xaf3-miy8-pkoo.f2.xano.io/api:lnQlUfUs";
    
    let path = options.servicePath || '';
    if (path.length > 0 && path.substr(0, 1) !== '/' && baseUrl.substr(baseUrl.length - 1, 1) !== '/')
      path = '/'+path;
  
    let query = options.query || '';
    if (query.length > 0) {
      let dataSlots = options.dataSlots || {};
      query = "?" + this.expandSlotTemplateString(query, dataSlots);
    }
  
    return baseUrl + path + query; 
  }
  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  addItem(item, options) {
    super.addItem(item, options);
    
    const url = this.urlFromOptions(options);
    
    let headers = {};
    headers['Content-Type'] = 'application/json';
    let authToken = localStorage.getItem('xano_authToken');
    if (authToken != null) {
      headers['Authorization'] = 'Bearer ' + authToken;
    }
    
    const fetchOpts = {
      method: 'POST',
    	headers: headers,
    	body: JSON.stringify(item),
    };
  
    return fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          console.log("** Error sending %s to %s, response: ", fetchOpts.method, url, response);
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        this._fetchComplete(null, options);
      })
      .catch((exc) => {
        this._fetchComplete(exc, options);
      });  
  }
  
  removeItem(item, options) {
    super.removeItem(item, options);
    
    let url = this.urlFromOptions(options);
    let itemPathComp = "/" + item.id;
    if ( !url.endsWith(itemPathComp)) {
      url += itemPathComp;
    }
  
    let headers = {};
    headers['Content-Type'] = 'application/json';
    let authToken = localStorage.getItem('xano_authToken');
    if (authToken != null) {
      headers['Authorization'] = 'Bearer ' + authToken;
    }
  
    const fetchOpts = {
      method: 'DELETE',
    	headers: headers,
    	body: JSON.stringify(item),
    };
  
    return fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          console.log("** Error sending %s to %s, response: ", fetchOpts.method, url, response);
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        this._fetchComplete(null, options);
      })
      .catch((exc) => {
        this._fetchComplete(exc, options);
      });  
  }
  
  replaceItemByRowIndex(idx, item, options) {
    super.replaceItemByRowIndex(idx, item, options);
    
    let url = this.urlFromOptions(options);
    let itemPathComp = "/" + item.id;
    if ( !url.endsWith(itemPathComp)) {
      url += itemPathComp;
    }
    
    let headers = {};
    headers['Content-Type'] = 'application/json';
    let authToken = localStorage.getItem('xano_authToken');
    if (authToken != null) {
      headers['Authorization'] = 'Bearer ' + authToken;
    }
  
    const fetchOpts = {
      method: 'POST',
    	headers: headers,
    	body: JSON.stringify(item),
    };
  
    return fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          console.log("** Error sending %s to %s, response: ", fetchOpts.method, url, response);
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        this._fetchComplete(null, options);
      })
      .catch((exc) => {
        this._fetchComplete(exc, options);
      });  
  }
  
  loadFromJson(json) {
    super.loadFromJson(json);
  
    /*for (let row of this.items) {
      for (let key of Object.keys(row)) {
        let val = row[key];
        if (val instanceof Object && val.type === 'image') {
          row[key] = "https://xaf3-miy8-pkoo.f2.xano.io" + val.path;
        }
      }
    }*/
  }
}
