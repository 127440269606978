import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class BioTextField extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // bands_bio

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    const value_textBlock = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.bands_bio);
    
    const style_elTextBlock = {
      fontSize: 14.0,
      color: '#555555',
      textAlign: 'left',
     };
    
    return (
      <div className="BioTextField">
        <div className="foreground">
          <div className="font-SFProTextRegular  elTextBlock" style={style_elTextBlock}>
            <div>{value_textBlock}</div>
          </div>
        </div>
      </div>
    )
  }
  
}
