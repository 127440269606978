import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class Colofon extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    const style_elTextBlock = {
      color: '#555555',
      textAlign: 'center',
     };
    
    return (
      <div className="Colofon">
        <div className="foreground">
          <div className="actionFont elTextBlock" style={style_elTextBlock}>
            <div><div dangerouslySetInnerHTML={{__html: this.context.locStrings.colofon_textblock_56078.replace(/\n/g, '<br>')}}></div></div>
          </div>
        </div>
      </div>
    )
  }
  
}
