// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_gigs_providers extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Xano'.
    
    item = {};
    this.items.push(item);
    item['password'] = "3f94edadf6b133a0.c3bd1b812b24953ef8577c2e14df4884deb556ac081963269049a9554d3a0f0a";
    item['id'] = "1";
    item['Email'] = "tijs@popradar.nl";
    item['created_at'] = "1665986071817";
    item['gigs_id'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['password'] = "eb81fcbba3527862.9efd810780f897acdc833aa1c51a623a7fcce69b115d423570c90a5d755aa301";
    item['id'] = "2";
    item['Email'] = "tapsody@tapsody.com";
    item['created_at'] = "1666633209040";
    item['gigs_id'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['password'] = "5598f5a6c290c7ea.d9e2a2a76deff53c369440308cbcbe73e0c552f474f28b29dd49550d8ac29711";
    item['id'] = "3";
    item['Email'] = "romanodavoglio@hotmail.com";
    item['created_at'] = "1666690960726";
    item['gigs_id'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['password'] = "5496aff6e6e0ca33.d33f7dac8d549695882a448c9daa885f9bb3c48b5f3b2d950b8aca677c2665eb";
    item['id'] = "4";
    item['Email'] = "Noah@kroepoekfabriek.nl";
    item['created_at'] = "1666690992604";
    item['gigs_id'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['password'] = "c91906946670e5f7.6732ae590fef48f4633784bcc814e0b4e6711f1e199aa79b720225f585c91ade";
    item['id'] = "5";
    item['Email'] = "zoe.kroezen@oefenbunker.com";
    item['created_at'] = "1666691016999";
    item['gigs_id'] = "7";
    item.key = key++;
  }

  
  urlFromOptions(options) {
    const baseUrl = "https://xaf3-miy8-pkoo.f2.xano.io/api:lnQlUfUs";
    
    let path = options.servicePath || '';
    if (path.length > 0 && path.substr(0, 1) !== '/' && baseUrl.substr(baseUrl.length - 1, 1) !== '/')
      path = '/'+path;
  
    let query = options.query || '';
    if (query.length > 0) {
      let dataSlots = options.dataSlots || {};
      query = "?" + this.expandSlotTemplateString(query, dataSlots);
    }
  
    return baseUrl + path + query; 
  }
  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  addItem(item, options) {
    super.addItem(item, options);
    
    const url = this.urlFromOptions(options);
    
    let headers = {};
    headers['Content-Type'] = 'application/json';
    let authToken = localStorage.getItem('xano_authToken');
    if (authToken != null) {
      headers['Authorization'] = 'Bearer ' + authToken;
    }
    
    const fetchOpts = {
      method: 'POST',
    	headers: headers,
    	body: JSON.stringify(item),
    };
  
    return fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          console.log("** Error sending %s to %s, response: ", fetchOpts.method, url, response);
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        this._fetchComplete(null, options);
      })
      .catch((exc) => {
        this._fetchComplete(exc, options);
      });  
  }
  
  removeItem(item, options) {
    super.removeItem(item, options);
    
    let url = this.urlFromOptions(options);
    let itemPathComp = "/" + item.id;
    if ( !url.endsWith(itemPathComp)) {
      url += itemPathComp;
    }
  
    let headers = {};
    headers['Content-Type'] = 'application/json';
    let authToken = localStorage.getItem('xano_authToken');
    if (authToken != null) {
      headers['Authorization'] = 'Bearer ' + authToken;
    }
  
    const fetchOpts = {
      method: 'DELETE',
    	headers: headers,
    	body: JSON.stringify(item),
    };
  
    return fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          console.log("** Error sending %s to %s, response: ", fetchOpts.method, url, response);
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        this._fetchComplete(null, options);
      })
      .catch((exc) => {
        this._fetchComplete(exc, options);
      });  
  }
  
  replaceItemByRowIndex(idx, item, options) {
    super.replaceItemByRowIndex(idx, item, options);
    
    let url = this.urlFromOptions(options);
    let itemPathComp = "/" + item.id;
    if ( !url.endsWith(itemPathComp)) {
      url += itemPathComp;
    }
    
    let headers = {};
    headers['Content-Type'] = 'application/json';
    let authToken = localStorage.getItem('xano_authToken');
    if (authToken != null) {
      headers['Authorization'] = 'Bearer ' + authToken;
    }
  
    const fetchOpts = {
      method: 'POST',
    	headers: headers,
    	body: JSON.stringify(item),
    };
  
    return fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          console.log("** Error sending %s to %s, response: ", fetchOpts.method, url, response);
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        this._fetchComplete(null, options);
      })
      .catch((exc) => {
        this._fetchComplete(exc, options);
      });  
  }
  
  loadFromJson(json) {
    super.loadFromJson(json);
  
    /*for (let row of this.items) {
      for (let key of Object.keys(row)) {
        let val = row[key];
        if (val instanceof Object && val.type === 'image') {
          row[key] = "https://xaf3-miy8-pkoo.f2.xano.io" + val.path;
        }
      }
    }*/
  }
}
