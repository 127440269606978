import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elVideoField from './images/BandDataCard_elVideoField_346695.jpg';
import img_elImage from './images/BandDataCard_elVideoField_346695.jpg';
import img_elImage4 from './images/BandDataCard_elVideoField_346695.jpg';
import img_elImage3 from './images/BandDataCard_elVideoField_346695.jpg';
import img_elTrack from './images/BandDataCard_elTrack_943632.png';
import img_elTrack3 from './images/BandDataCard_elTrack_943632.png';
import img_elTrack2 from './images/BandDataCard_elTrack_943632.png';
import BioTextField from './BioTextField';
import img_elProfilePhoto from './images/BandDataCard_elVideoField_346695.jpg';
import img_elLocationIcon from './images/BandDataCard_elLocationIcon_24455.png';
import img_elIconspotify from './images/BandDataCard_elIconspotify_994317.png';
import img_elIconinstagram from './images/BandDataCard_elIconinstagram_580926.png';
import img_elIconfacebook from './images/BandDataCard_elIconfacebook_852645.png';
import img_elMessageButton from './images/BandDataCard_elMessageButton_349668.png';
import img_elEmailButton from './images/BandDataCard_elEmailButton_978399.png';
import img_elFavouriteInactive from './images/BandDataCard_elFavouriteInactive_528283.png';
import img_elFavouriteActive from './images/BandDataCard_elFavouriteActive_248623.png';
import img_elAudioVector from './images/BandDataCard_elAudioVector_693534.png';
import img_elAccountsearchoutline from './images/BandDataCard_elAccountsearchoutline_814480.png';
import img_elWeb from './images/BandDataCard_elWeb_1000408.png';
import img_elPhoneoutline from './images/BandDataCard_elPhoneoutline_347074.png';
import img_elEuroIcon from './images/BandDataCard_elEuroIcon_107735.png';
import img_elTagoutline from './images/BandDataCard_elTagoutline_908093.png';
import img_elTextaccount from './images/BandDataCard_elTextaccount_180790.png';
import img_elMagnify from './images/BandDataCard_elMagnify_171737.png';
import img_elAudioVectorCopy from './images/BandDataCard_elAudioVector_693534.png';
import img_elAudioVectorCopy2 from './images/BandDataCard_elAudioVector_693534.png';
import img_elAudioVectorCopy3 from './images/BandDataCard_elAudioVector_693534.png';
import img_elTagoutlineCopy from './images/BandDataCard_elTagoutline_908093.png';
import img_elTagoutlineCopy2 from './images/BandDataCard_elTagoutline_908093.png';
import img_elMagnifyCopy from './images/BandDataCard_elMagnify_171737.png';
import img_elMagnifyCopy2 from './images/BandDataCard_elMagnify_171737.png';

export default class BandDataCard extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // profilepic_url, band_name, city, spotify_followers, insta_followers, facebook_followers, whatsapp, email, spotify_link, instagram, FB_link, pressphoto1_url, pressphoto2_url, pressphoto3_url, audio_1, audio_1_photo, profile_pic, audio_2_photo, audio_2, audio_3_photo, audio_3, video, members, tele, price_Range, tag_1, tag_2, tag_3, bands_bio, website

  constructor(props) {
    super(props);
    
    this.state = {
      elFavouriteInactive_visible: true,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elVideoField = async () => {
    window.open(this.props.video ? this.props.video.url : '', '_blank');
  
  }
  
  
  onClick_elImage = async () => {
    window.open(this.props.pressphoto1_url, '_blank');
  
  }
  
  
  onClick_elImage4 = async () => {
    window.open(this.props.pressphoto3_url, '_blank');
  
  }
  
  
  onClick_elImage3 = async () => {
    window.open(this.props.pressphoto2_url, '_blank');
  
  }
  
  
  onClick_elTrack = async () => {
    window.open(this.props.audio_1 ? this.props.audio_1.url : '', '_blank');
  
  }
  
  
  onClick_elTrack3 = async () => {
    window.open(this.props.audio_3 ? this.props.audio_3.url : '', '_blank');
  
  }
  
  
  onClick_elTrack2 = async () => {
    window.open(this.props.audio_2 ? this.props.audio_2.url : '', '_blank');
  
  }
  
  
  onClick_elTextBlockCopy6 = async () => {
    window.open(this.props.website, '_blank');
  
  }
  
  
  onClick_elProfilePhoto = async () => {
    window.open(this.props.profilepic_url, '_blank');
  
  }
  
  
  onClick_elIconspotify = async () => {
    window.open(this.props.Spotify_link, '_blank');
  
  }
  
  
  onClick_elIconinstagram = async () => {
    window.open(this.props.Instagram, '_blank');
  
  }
  
  
  onClick_elIconfacebook = async () => {
    window.open(this.props.FB_link, '_blank');
  
  }
  
  
  onClick_elMessageButton = async () => {
    window.open(this.props.whatsapp, '_blank');
  
  }
  
  
  onClick_elEmailButton = async () => {
    window.open(this.props.email, '_blank');
  
  }
  
  
  onClick_elFavouriteInactive = async () => {
    this.setState({elFavouriteInactive_visible: !this.state.elFavouriteInactive_visible});
  
  }
  
  
  onClick_elFavouriteActive = async () => {
    this.setState({elFavouriteInactive_visible: !this.state.elFavouriteInactive_visible});
  
  }
  
  
  onClick_elAudioVector = async () => {
    window.open(this.props.audio_1 ? this.props.audio_1.url : '', '_blank');
  
  }
  
  
  onClick_elAudioVectorCopy = async () => {
    window.open(this.props.audio_2 ? this.props.audio_2.url : '', '_blank');
  
  }
  
  
  onClick_elAudioVectorCopy2 = async () => {
    window.open(this.props.audio_3 ? this.props.audio_3.url : '', '_blank');
  
  }
  
  
  onClick_elAudioVectorCopy3 = async () => {
    window.open(this.props.video ? this.props.video.url : '', '_blank');
  
  }
  
  
  render() {
    
    const style_elRect = {
      background: 'rgba(255, 255, 255, 1.000)',
      borderRadius: '30.0px',
     };
    const style_elVideoField = {
      backgroundImage: 'url('+img_elVideoField+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
      borderRadius: '16.0px',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elVideoField_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#a5a5a5',
      borderRadius: '16.0px',
     };
    const style_elImage = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp(this.props.pressphoto1_url)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
      borderRadius: '16.0px',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elImage_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#a5a5a5',
      borderRadius: '16.0px',
     };
    const style_elImage4 = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp(this.props.pressphoto3_url)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
      borderRadius: '16.0px',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elImage4_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#a5a5a5',
      borderRadius: '16.0px',
     };
    const style_elImage3 = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp(this.props.pressphoto2_url)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
      borderRadius: '16.0px',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elImage3_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#a5a5a5',
      borderRadius: '16.0px',
     };
    const style_elTrack = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp(this.props.audio_1_photo)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
      borderRadius: '180.0px',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTrack_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#a5a5a5',
      borderRadius: '180.0px',
     };
    const style_elTrack3 = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp(this.props.audio_3_photo)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
      borderRadius: '180.0px',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTrack3_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#a5a5a5',
      borderRadius: '180.0px',
     };
    const style_elTrack2 = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp(this.props.audio_2_photo)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
      borderRadius: '180.0px',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTrack2_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#a5a5a5',
      borderRadius: '180.0px',
     };
    const style_elFansText2 = {
      fontSize: 14.0,
      color: '#555555',
      textAlign: 'left',
     };
    const style_elFansText = {
      fontSize: 14.0,
      color: '#555555',
      textAlign: 'left',
     };
    const style_elFansText3 = {
      fontSize: 14.0,
      color: '#555555',
      textAlign: 'left',
     };
    
    const value_textBlockCopy3 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.members);
    
    const style_elTextBlockCopy3 = {
      fontSize: 14.0,
      color: '#555555',
      textAlign: 'left',
     };
    const style_elTextBlockCopy5 = {
      fontSize: 18.0,
      color: 'black',
      textAlign: 'left',
     };
    
    const value_textBlockCopy6 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.website);
    
    const style_elTextBlockCopy6 = {
      fontSize: 14.0,
      color: '#555555',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_textBlockCopy7 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.tele);
    
    const style_elTextBlockCopy7 = {
      fontSize: 14.0,
      color: '#555555',
      textAlign: 'left',
     };
    
    const value_textBlockCopy8 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.Price_Range);
    
    const style_elTextBlockCopy8 = {
      fontSize: 14.0,
      color: '#555555',
      textAlign: 'left',
     };
    
    const value_tag3 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.tag_3);
    
    const style_elTag3 = {
      fontSize: 14.0,
      color: '#555555',
      textAlign: 'left',
     };
    
    const value_tag2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.tag_2);
    
    const style_elTag2 = {
      fontSize: 14.0,
      color: '#555555',
      textAlign: 'left',
     };
    const style_elTagsTitle = {
      fontSize: 18.0,
      color: 'black',
      textAlign: 'left',
     };
    
    const value_tag4 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.tag_1);
    
    const style_elTag4 = {
      fontSize: 14.0,
      color: '#555555',
      textAlign: 'left',
     };
    
    const value_biography = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.bands_bio);
    
    const style_elBiography = {
      fontSize: 14.0,
      color: '#555555',
      textAlign: 'left',
     };
    const style_elBioTextField = {
      display: 'none',
     };
    const style_elDescriptionTitle = {
      fontSize: 18.0,
      color: 'black',
      textAlign: 'left',
     };
    
    const value_textBlock = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.bands_bio);
    
    const style_elTextBlock = {
      fontSize: 14.0,
      color: '#555555',
      textAlign: 'left',
      display: 'none',
     };
    // eslint-disable-next-line no-unused-vars
    const style_elTextBlock_inner = {
      textAlign: 'left',
     };
    
    const value_noOfSpotifyFans = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.Spotify_followers);
    
    const style_elNoOfSpotifyFans = {
      fontSize: 14.0,
      color: 'black',
      textAlign: 'center',
     };
    
    const value_noOfInstaFans = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.Insta_followers);
    
    const style_elNoOfInstaFans = {
      fontSize: 14.0,
      color: 'black',
      textAlign: 'center',
     };
    
    const value_noOfFacebookFans = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.Facebook_followers);
    
    const style_elNoOfFacebookFans = {
      fontSize: 14.0,
      color: 'black',
      textAlign: 'center',
     };
    
    const value_artistLocation = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.city);
    
    const style_elArtistLocation = {
      fontSize: 16.0,
      color: '#555555',
      textAlign: 'left',
     };
    
    const value_trackTitle2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.audio_1 ? this.props.audio_1.name : '');
    
    const style_elTrackTitle2 = {
      fontSize: 11.0,
      color: '#555555',
      textAlign: 'center',
     };
    const style_elTrackTitle = {
      fontSize: 11.0,
      color: '#555555',
      textAlign: 'center',
     };
    const style_elTrackTitle3 = {
      fontSize: 11.0,
      color: '#555555',
      textAlign: 'center',
     };
    const style_elProfilePhoto = {
      backgroundImage: 'url('+this.context.appActions.createImageUrlFromProp(this.props.profile_pic)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      borderRadius: '12.0px',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_artistName = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.band_name);
    
    const style_elArtistName = {
      fontSize: 24.0,
      color: 'black',
      textAlign: 'left',
     };
    const style_elLocationIcon = {
      backgroundImage: 'url('+img_elLocationIcon+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    const style_elRect2 = {
      borderTop: '1px solid rgba(0, 0, 0, 0.3819)',
     };
    const style_elIconspotify = {
      backgroundImage: 'url('+img_elIconspotify+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elIconinstagram = {
      backgroundImage: 'url('+img_elIconinstagram+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elIconfacebook = {
      backgroundImage: 'url('+img_elIconfacebook+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elMessageButton = {
      backgroundImage: 'url('+img_elMessageButton+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elEmailButton = {
      backgroundImage: 'url('+img_elEmailButton+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elFavouriteInactive = {
      backgroundImage: 'url('+img_elFavouriteInactive+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elFavouriteInactive = this.state.elFavouriteInactive_visible ? (
      <div className="elFavouriteInactive" style={style_elFavouriteInactive} onClick={this.onClick_elFavouriteInactive}  />
     ) : null;
    const style_elFavouriteActive = {
      backgroundImage: 'url('+img_elFavouriteActive+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elAudioVector = {
      backgroundImage: 'url('+img_elAudioVector+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elAccountsearchoutline = {
      backgroundImage: 'url('+img_elAccountsearchoutline+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elWeb = {
      backgroundImage: 'url('+img_elWeb+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elPhoneoutline = {
      backgroundImage: 'url('+img_elPhoneoutline+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elEuroIcon = {
      backgroundImage: 'url('+img_elEuroIcon+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elTagoutline = {
      backgroundImage: 'url('+img_elTagoutline+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elTextaccount = {
      backgroundImage: 'url('+img_elTextaccount+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elMagnify = {
      backgroundImage: 'url('+img_elMagnify+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elAudioVectorCopy = {
      backgroundImage: 'url('+img_elAudioVectorCopy+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elAudioVectorCopy2 = {
      backgroundImage: 'url('+img_elAudioVectorCopy2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elAudioVectorCopy3 = {
      backgroundImage: 'url('+img_elAudioVectorCopy3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTagoutlineCopy = {
      backgroundImage: 'url('+img_elTagoutlineCopy+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elTagoutlineCopy2 = {
      backgroundImage: 'url('+img_elTagoutlineCopy2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elMagnifyCopy = {
      backgroundImage: 'url('+img_elMagnifyCopy+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elMagnifyCopy2 = {
      backgroundImage: 'url('+img_elMagnifyCopy2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    return (
      <div className="BandDataCard">
        <div className="foreground">
          <div className="elRect" style={style_elRect} />
          <div className="elVideoField" style={style_elVideoField_outer}>
            <div style={style_elVideoField} onClick={this.onClick_elVideoField}  />
          </div>
          
          <div className="elImage" style={style_elImage_outer}>
            <div style={style_elImage} onClick={this.onClick_elImage}  />
          </div>
          
          <div className="elImage4" style={style_elImage4_outer}>
            <div style={style_elImage4} onClick={this.onClick_elImage4}  />
          </div>
          
          <div className="elImage3" style={style_elImage3_outer}>
            <div style={style_elImage3} onClick={this.onClick_elImage3}  />
          </div>
          
          <div className="elTrack" style={style_elTrack_outer}>
            <div style={style_elTrack} onClick={this.onClick_elTrack}  />
          </div>
          
          <div className="elTrack3" style={style_elTrack3_outer}>
            <div style={style_elTrack3} onClick={this.onClick_elTrack3}  />
          </div>
          
          <div className="elTrack2" style={style_elTrack2_outer}>
            <div style={style_elTrack2} onClick={this.onClick_elTrack2}  />
          </div>
          
          <div className="font-SFProTextRegular  elFansText2" style={style_elFansText2}>
            <div>{this.context.locStrings.bandcard_textblock_899303}</div>
          </div>
          <div className="font-SFProTextRegular  elFansText" style={style_elFansText}>
            <div>{this.context.locStrings.bandcard_textblockcopy_329166}</div>
          </div>
          <div className="font-SFProTextRegular  elFansText3" style={style_elFansText3}>
            <div>{this.context.locStrings.bandcard_textblockcopy2_157789}</div>
          </div>
          <div className="font-SFProTextRegular  elTextBlockCopy3" style={style_elTextBlockCopy3}>
            <div>{value_textBlockCopy3}</div>
          </div>
          <div className="font-SFProTextSemibold  elTextBlockCopy5" style={style_elTextBlockCopy5}>
            <div>{this.context.locStrings.bandcard_textblockcopy5_898312}</div>
          </div>
          <div className="font-SFProTextRegular  elTextBlockCopy6" style={style_elTextBlockCopy6} onClick={this.onClick_elTextBlockCopy6} >
            <div>{value_textBlockCopy6}</div>
          </div>
          <div className="font-SFProTextRegular  elTextBlockCopy7" style={style_elTextBlockCopy7}>
            <div>{value_textBlockCopy7}</div>
          </div>
          <div className="font-SFProTextRegular  elTextBlockCopy8" style={style_elTextBlockCopy8}>
            <div>{value_textBlockCopy8}</div>
          </div>
          <div className="font-SFProTextRegular  elTag3" style={style_elTag3}>
            <div>{value_tag3}</div>
          </div>
          <div className="font-SFProTextRegular  elTag2" style={style_elTag2}>
            <div>{value_tag2}</div>
          </div>
          <div className="font-SFProTextSemibold  elTagsTitle" style={style_elTagsTitle}>
            <div>{this.context.locStrings.bandcard_textblockcopy11_281248}</div>
          </div>
          <div className="font-SFProTextRegular  elTag4" style={style_elTag4}>
            <div>{value_tag4}</div>
          </div>
          <div className="font-SFProTextRegular  elBiography" style={style_elBiography}>
            <div>{value_biography}</div>
          </div>
          <div className="hasNestedComps elBioTextField" style={style_elBioTextField}>
            <BioTextField ref={(el)=> this._elBioTextField = el} />
          </div>
          <div className="font-SFProTextSemibold  elDescriptionTitle" style={style_elDescriptionTitle}>
            <div>{this.context.locStrings.bandcard_textblockcopy14_494465}</div>
          </div>
          <div className="font-SFProTextRegular  elTextBlock" style={style_elTextBlock}>
            <div className="bottomAlignedContent">{value_textBlock}</div>
          </div>
          <div className="font-SFProTextRegular  elNoOfSpotifyFans" style={style_elNoOfSpotifyFans}>
            <div>{value_noOfSpotifyFans}</div>
          </div>
          <div className="font-SFProTextRegular  elNoOfInstaFans" style={style_elNoOfInstaFans}>
            <div>{value_noOfInstaFans}</div>
          </div>
          <div className="font-SFProTextRegular  elNoOfFacebookFans" style={style_elNoOfFacebookFans}>
            <div>{value_noOfFacebookFans}</div>
          </div>
          <div className="font-SFProTextRegular  elArtistLocation" style={style_elArtistLocation}>
            <div>{value_artistLocation}</div>
          </div>
          <div className="font-SFProTextRegular  elTrackTitle2" style={style_elTrackTitle2}>
            <div>{value_trackTitle2}</div>
          </div>
          <div className="font-SFProTextRegular  elTrackTitle" style={style_elTrackTitle}>
            <div>{this.context.locStrings.bandcard_textblockcopy21_151022}</div>
          </div>
          <div className="font-SFProTextRegular  elTrackTitle3" style={style_elTrackTitle3}>
            <div>{this.context.locStrings.bandcard_textblockcopy22_843702}</div>
          </div>
          <div className="elProfilePhoto" style={style_elProfilePhoto} onClick={this.onClick_elProfilePhoto}  />
          <div className="font-SFProTextSemibold  elArtistName" style={style_elArtistName}>
            <div>{value_artistName}</div>
          </div>
          <div className="elLocationIcon" style={style_elLocationIcon} />
          <div className="elRect2" style={style_elRect2} />
          <div className="elIconspotify" style={style_elIconspotify} onClick={this.onClick_elIconspotify}  />
          <div className="elIconinstagram" style={style_elIconinstagram} onClick={this.onClick_elIconinstagram}  />
          <div className="elIconfacebook" style={style_elIconfacebook} onClick={this.onClick_elIconfacebook}  />
          <div className="elMessageButton" style={style_elMessageButton} onClick={this.onClick_elMessageButton}  />
          <div className="elEmailButton" style={style_elEmailButton} onClick={this.onClick_elEmailButton}  />
          { elFavouriteInactive }
          <div className="elFavouriteActive" style={style_elFavouriteActive} onClick={this.onClick_elFavouriteActive}  />
          <div className="elAudioVector" style={style_elAudioVector} onClick={this.onClick_elAudioVector}  />
          <div className="elAccountsearchoutline" style={style_elAccountsearchoutline} />
          <div className="elWeb" style={style_elWeb} />
          <div className="elPhoneoutline" style={style_elPhoneoutline} />
          <div className="elEuroIcon" style={style_elEuroIcon} />
          <div className="elTagoutline" style={style_elTagoutline} />
          <div className="elTextaccount" style={style_elTextaccount} />
          <div className="elMagnify" style={style_elMagnify} />
          <div className="elAudioVectorCopy" style={style_elAudioVectorCopy} onClick={this.onClick_elAudioVectorCopy}  />
          <div className="elAudioVectorCopy2" style={style_elAudioVectorCopy2} onClick={this.onClick_elAudioVectorCopy2}  />
          <div className="elAudioVectorCopy3" style={style_elAudioVectorCopy3} onClick={this.onClick_elAudioVectorCopy3}  />
          <div className="elTagoutlineCopy" style={style_elTagoutlineCopy} />
          <div className="elTagoutlineCopy2" style={style_elTagoutlineCopy2} />
          <div className="elMagnifyCopy" style={style_elMagnifyCopy} />
          <div className="elMagnifyCopy2" style={style_elMagnifyCopy2} />
        </div>
      </div>
    )
  }
  
}
