// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_localizationSheet extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['key'] = "start_button_186351";
    item['en'] = "Log out";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_textblock_890520";
    item['en'] = "Welcome!";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_textblockcopy_257792";
    item['en'] = "Your gig information";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_button_846620";
    item['en'] = "Send email";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblock_899303";
    item['en'] = "Fans";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy_329166";
    item['en'] = "Fans";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy2_157789";
    item['en'] = "Fans";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_text_40741";
    item['en'] = "##";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy3_692560";
    item['en'] = "Profile type";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy4_939444";
    item['en'] = "Artist name";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy5_898312";
    item['en'] = "Profile";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy6_347947";
    item['en'] = "Website";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy7_109055";
    item['en'] = "Phone";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy8_563839";
    item['en'] = "Fee";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy9_136211";
    item['en'] = "Tag 3";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy10_56057";
    item['en'] = "Tag 2";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy11_281248";
    item['en'] = "Tags";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy12_840184";
    item['en'] = "Tag 1";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy13_574094";
    item['en'] = "Bio";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy14_494465";
    item['en'] = "Description";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy15_582345";
    item['en'] = "Stats";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy16_240969";
    item['en'] = "##";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy17_878838";
    item['en'] = "##";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy18_722218";
    item['en'] = "##";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy19_341946";
    item['en'] = "Location";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy20_799716";
    item['en'] = "Track name";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy21_151022";
    item['en'] = "Track name";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard_textblockcopy22_843702";
    item['en'] = "Track name";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy22_403440";
    item['en'] = "Track name";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy21_40825";
    item['en'] = "Track name";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy20_544926";
    item['en'] = "Track name";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy19_584972";
    item['en'] = "Location";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy18_714931";
    item['en'] = "##";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy17_486072";
    item['en'] = "##";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy16_301985";
    item['en'] = "##";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy14_111732";
    item['en'] = "Description";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy13_983328";
    item['en'] = "Bio";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy12_135949";
    item['en'] = "Tag 1";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy11_173961";
    item['en'] = "Tags";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy10_551625";
    item['en'] = "Tag 2";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy9_708973";
    item['en'] = "Tag 3";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy8_274760";
    item['en'] = "Fee";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy7_638428";
    item['en'] = "Phone";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy6_449002";
    item['en'] = "Website";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy5_592998";
    item['en'] = "Profile";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy4_572738";
    item['en'] = "Artist name";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy3_972243";
    item['en'] = "Profile type";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy2_913229";
    item['en'] = "Fans";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblockcopy_195642";
    item['en'] = "Fans";
    
    item = {};
    this.items.push(item);
    item['key'] = "bandcard2_textblock_580642";
    item['en'] = "Fans";
    
    item = {};
    this.items.push(item);
    item['key'] = "gigdata_textblock_976041";
    item['en'] = "Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "gigdata_textblock2_1017483";
    item['en'] = "Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "gigdata_textblockcopy_712990";
    item['en'] = "Time";
    
    item = {};
    this.items.push(item);
    item['key'] = "gigdata_textblockcopy2_156327";
    item['en'] = "Location";
    
    item = {};
    this.items.push(item);
    item['key'] = "gigdata_textblockcopy3_915376";
    item['en'] = "Fee";
    
    item = {};
    this.items.push(item);
    item['key'] = "gigdata_textblockcopy4_519548";
    item['en'] = "Genre or service";
    
    item = {};
    this.items.push(item);
    item['key'] = "Log out";
    item['en'] = "Log out";
    
    item = {};
    this.items.push(item);
    item['key'] = "log_out";
    item['en'] = "Log out";
    
    item = {};
    this.items.push(item);
    item['key'] = "logincard_textblock_477572";
    item['en'] = "Please log in";
    
    item = {};
    this.items.push(item);
    item['key'] = "logincard_field_443974";
    item['en'] = "Your email";
    
    item = {};
    this.items.push(item);
    item['key'] = "logincard_emailinputfieldcopy_766048";
    item['en'] = "Your password";
    
    item = {};
    this.items.push(item);
    item['key'] = "logincard_checkbox_766875";
    item['en'] = "Remember me";
    
    item = {};
    this.items.push(item);
    item['key'] = "colofon_textblock_56078";
    item['en'] = "Powered by BAND-ADE B.V.\nChamber of Commerce no.: 82084874\nRotterdam, The Netherlands. All content © 2022 BAND-ADE B.V.";
    
    item = {};
    this.items.push(item);
    item['key'] = "logincard_textblock_17166";
    item['en'] = "Forgot password?";
    
    item = {};
    this.items.push(item);
    item['key'] = "gigdatacard_field_207797";
    item['en'] = "Search";
    
    item = {};
    this.items.push(item);
    item['key'] = "gigapplicants_yourgiginformationtextcopy_834696";
    item['en'] = "Your gig applicants";
    
    item = {};
    this.items.push(item);
    item['key'] = "logingate_passwordinputfield_817478";
    item['en'] = "Your password";
    
    item = {};
    this.items.push(item);
    item['key'] = "logingate_emailinputfield_8276";
    item['en'] = "Your email";
    
    item = {};
    this.items.push(item);
    item['key'] = "logingate_textblock_941242";
    item['en'] = "Forgot password?";
    
    item = {};
    this.items.push(item);
    item['key'] = "logingate_pleaselogintitle_223606";
    item['en'] = "Please log in";
    
    item = {};
    this.items.push(item);
    item['key'] = "logingate_checkbox_146863";
    item['en'] = "Remember me";
    
    item = {};
    this.items.push(item);
    item['key'] = "biotextfield_biography_1018178";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "biotextfield_textblock_902051";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "banddatacard_textblock_389913";
    item['en'] = " ";
    
    let storedItems = localStorage.getItem(this.id);
    if (storedItems != null) {
      this.items = JSON.parse(storedItems);
    }
  }

  addItem(item, options) {
    super.addItem(item, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  removeItem(item, options) {
    super.removeItem(item, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  replaceItemByRowIndex(idx, newItem, options) {
    super.replaceItemByRowIndex(idx, newItem, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  getStringsByLanguage = () => {
    let stringsByLang = {};
    for (let row of this.items) {
      const locKey = row.key;
      for (let key in row) {
        if (key === 'key')
          continue;
        let langObj = stringsByLang[key] || {};
        langObj[locKey] = row[key];
        stringsByLang[key] = langObj;
      }
    }
    return stringsByLang;
  }

}
