import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
// eslint-disable-next-line
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
// eslint-disable-next-line
import * as util from 'util';
import './App.css';
import ScreenContext from './ScreenContext.js';
import GigApplicantsScreen from './GigApplicantsScreen.js';
import LoginGateScreen from './LoginGateScreen.js';
import DataSheet_localizationSheet from './DataSheet_localizationSheet.js';
import DataSheet_bands from './DataSheet_bands.js';
import DataSheet_gig_application from './DataSheet_gig_application.js';
import DataSheet_gigs from './DataSheet_gigs.js';
import DataSheet_gigs_providers from './DataSheet_gigs_providers.js';
import DataSheet_sheet5 from './DataSheet_sheet5.js';
import DataSheet_sheet6 from './DataSheet_sheet6.js';


class App extends Component {
  constructor(props) {
    super(props);

    this.dataSheets = {};
    this.dataSheets['localizationSheet'] = new DataSheet_localizationSheet('localizationSheet', this.dataSheetDidUpdate);
    this.dataSheets['bands'] = new DataSheet_bands('bands', this.dataSheetDidUpdate);
    this.dataSheets['gig_application'] = new DataSheet_gig_application('gig_application', this.dataSheetDidUpdate);
    this.dataSheets['gigs'] = new DataSheet_gigs('gigs', this.dataSheetDidUpdate);
    this.dataSheets['gigs_providers'] = new DataSheet_gigs_providers('gigs_providers', this.dataSheetDidUpdate);
    this.dataSheets['sheet5'] = new DataSheet_sheet5('sheet5', this.dataSheetDidUpdate);
    this.dataSheets['sheet6'] = new DataSheet_sheet6('sheet6', this.dataSheetDidUpdate);
    this.dataSheetLoaded = {};

    this.dataSlots = {};
    this.dataSlots['ds_activeLang'] = "en";
    this.dataSlots['ds_SlotUserID'] = "2";
    this.dataSlots['ds_Slot 3'] = "";
    this.dataSlots['ds_Email'] = "";
    this.dataSlots['ds_password'] = "";

    this.updateLocalizationFromDataSheet(this.dataSheets['localizationSheet']);


    this.serviceOptions_bands = {
      dataSlots: this.dataSlots,
      servicePath: "/bands",
      query: "",
    };
    this.dataSheets['bands'].appActions = this;
    this.serviceOptions_gig_application = {
      dataSlots: this.dataSlots,
      servicePath: "/gig_application",
      query: "",
    };
    this.dataSheets['gig_application'].appActions = this;
    this.serviceOptions_gigs = {
      dataSlots: this.dataSlots,
      servicePath: "/gigs",
      query: "",
    };
    this.dataSheets['gigs'].appActions = this;
    this.serviceOptions_gigs_providers = {
      dataSlots: this.dataSlots,
      servicePath: "/gigs_providers",
      query: "",
    };
    this.dataSheets['gigs_providers'].appActions = this;
    this.serviceOptions_sheet5 = {
      dataSlots: this.dataSlots,
      servicePath: "/gig_applicants_list/$slot('ds_SlotUserID')",
      query: "",
    };
    this.dataSheets['sheet5'].appActions = this;
    this.serviceOptions_sheet6 = {
      dataSlots: this.dataSlots,
      servicePath: "/gigs/$slot('ds_SlotUserID')",
      query: "",
    };
    this.dataSheets['sheet6'].appActions = this;
    // Initialize web service plugin 'xano 1'
    
    
    let xano_userId = localStorage.getItem('xano_userId');
    if (xano_userId) {
      this.dataSlots['ds_SlotUserID'] = xano_userId;
    }
    
    this.xano_isLoggedIn = () => {
      let time = (new Date()).getTime();
      let prevTime = localStorage.getItem('xano_loginTimestamp');
      if (prevTime) {
        let diff = time - prevTime;
        if (diff < 1000*60*60*24) {
          let authToken = localStorage.getItem('xano_authToken');
          return authToken != null;
        }
      }
      return false;
    }
    

    this.state = {
      screenTransitionForward: true,
    }

  }

  windowDidResize = () => {
    let w = window.innerWidth;
    let formatId;
    if (w < 576) formatId = 'narrow-phone';
    else if (w < 768) formatId = 'wide-phone';
    else if (w < 1024) formatId = 'narrow-tablet';
    else formatId = 'wide-tablet';
    if (formatId !== this.state.screenFormatId) {
      this.setState({screenFormatId: formatId});
    }
  }

  componentDidMount() {
    this.windowDidResize();
    window.addEventListener('resize', this.windowDidResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowDidResize);
  }

  isLoading() {
    return this.state.loading;
  }

  goToScreen = (screenId, baseScreenId, props) => {
    // Implemented with React Router's 'history' object.
    // 'baseScreenId' is set when inside a container such as a tab bar.
    let path = '/'+screenId;
    if (baseScreenId && baseScreenId.length > 0) {
      path = '/'+baseScreenId+path;
      props = {};
    }
    this.props.history.push(path, {...props});
    window.scrollTo(0, 0);
  }

  goBack = () => {
    // Implemented with React Router's 'history' object.
    this.props.history.goBack();
  }

  getDataSheet = (sheetId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    return this.dataSheets[sheetId];
  }

  addToDataSheet = (sheetId, newRow, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    let sheet = this.dataSheets[sheetId];
    if (sheet && newRow) {
      let promise = sheet.addItem(newRow, this['serviceOptions_'+sheetId] || {});
      this.setState({});
      return promise;
    }
  }

  updateInDataSheet = (sheetId, row, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    let sheet = this.dataSheets[sheetId];
    if (sheet && row) {
      let promise = sheet.replaceItemByKey(row.key, row, this['serviceOptions_'+sheetId] || {});
      this.setState({});
      return promise;
    }
  }

  removeFromDataSheet = (sheetId, row) => {
    let sheet = this.dataSheets[sheetId];
    if (sheet && row) {
      let promise = sheet.removeItem(row, this['serviceOptions_'+sheetId] || {});
      this.setState({});
      return promise;
    }
  }

  updateDataSlot = (slotId, value, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    if (value === this.dataSlots[slotId])
      return;

    this.dataSlots[slotId] = value;

    if (slotId === 'ds_activeLang') {
      this.locStrings.setLanguage(value);
    }

    {
      let usedSlots = [];
      let servicePath = this.dataSheets['bands'].expandSlotTemplateString("/bands", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_bands.servicePath = servicePath;
        this.loadData_xano1(this.dataSheets['bands'], this.serviceOptions_bands, false);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['gig_application'].expandSlotTemplateString("/gig_application", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_gig_application.servicePath = servicePath;
        this.loadData_xano1(this.dataSheets['gig_application'], this.serviceOptions_gig_application, false);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['gigs'].expandSlotTemplateString("/gigs", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_gigs.servicePath = servicePath;
        this.loadData_xano1(this.dataSheets['gigs'], this.serviceOptions_gigs, false);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['gigs_providers'].expandSlotTemplateString("/gigs_providers", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_gigs_providers.servicePath = servicePath;
        this.loadData_xano1(this.dataSheets['gigs_providers'], this.serviceOptions_gigs_providers, false);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['sheet5'].expandSlotTemplateString("/gig_applicants_list/$slot('ds_SlotUserID')", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_sheet5.servicePath = servicePath;
        this.loadData_xano1(this.dataSheets['sheet5'], this.serviceOptions_sheet5, false);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['sheet6'].expandSlotTemplateString("/gigs/$slot('ds_SlotUserID')", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_sheet6.servicePath = servicePath;
        this.loadData_xano1(this.dataSheets['sheet6'], this.serviceOptions_sheet6, false);
      }
    }
    this.setState({});
  }

  dataSheetDidUpdate = (dataSheet) => {
    // This method is the default implementation and could be customized by a state management plugin.
    this.setState({});
  }

  updateLocalizationFromDataSheet = (dataSheet) => {
    const stringsObj = dataSheet.getStringsByLanguage();
    if (stringsObj && Object.keys(stringsObj).length > 0) {
      this.locStrings = new LocalizedStrings(stringsObj);
    } else {
      this.locStrings = new LocalizedStrings({en: {}});
    }
    this.locStrings.setLanguage(this.dataSlots['ds_activeLang']);
  }

  loadData_xano1 = (dataSheet, options, firstLoad) => {
    // This method was written by data plugin 'Xano'.
    this.setState({loading: true});
    
    // clear any placeholder data before load
    if (firstLoad) {
    	dataSheet.items = [];
    }
    
    const fetchComplete = (err) => {
      if (err) {
        // This error handling comes from React Studio
        // and currently doesn't do anything useful.
        console.error('** Web service load failed: ', err);
      } else {
      }
      this.setState({loading: false});
    }
    
    const url = dataSheet.urlFromOptions(options);  // this method was written by the web service plugin
    
    let headers = {};
    let authToken = localStorage.getItem('xano_authToken');
    if (authToken != null) {
      headers['Authorization'] = 'Bearer ' + authToken;
    }
    
    const fetchOpts = {
      method: 'GET',
      headers: headers,
    };
    
    return fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        dataSheet.loadFromJson(json);
        fetchComplete(null, options);
      })
      .catch((exc) => {
        fetchComplete(exc, options);
      });
  }

  createImageUrlFromProp = (prop) => {
    if (prop instanceof Object) {
      if (prop.type != null && prop.type === 'image' && prop.path != null) {
        return "https://xaf3-miy8-pkoo.f2.xano.io"+prop.path;
      }
    }
    return prop;
  }

  render() {
    const makeScreen = (screenId, baseProps, atTop, forward, subpathId) => {
      let screenCtxProps = {
        ...baseProps,
        atTopOfScreenStack: atTop,
        transitionForward: forward,
        appActions: this,
        dataSheets: this.dataSheets,
        locStrings: this.locStrings,
        deviceInfo: {
          screenFormatId: this.state.screenFormatId
        },
        'ds_activeLang': this.dataSlots['ds_activeLang'],
        'ds_SlotUserID': this.dataSlots['ds_SlotUserID'],
        'ds_Slot 3': this.dataSlots['ds_Slot 3'],
        'ds_Email': this.dataSlots['ds_Email'],
        'ds_password': this.dataSlots['ds_password'],
      };
      let screen;
      switch (screenId) {
        case 'gigApplicants':
          screen = <GigApplicantsScreen {...baseProps} />;
          break;
        case 'loginGate':
          screen = <LoginGateScreen {...baseProps} />;
          break;
        default:
          screen = null;
      }
      if (screen) {
        return <ScreenContext.Provider value={screenCtxProps}>{screen}</ScreenContext.Provider>;
      }
    }

    return (
      <div className="App">
        <Switch>
          <Route path="/" render={(props) => {
            return (this.xano_isLoggedIn()) ? <Redirect to="/gigApplicants" /> : <Redirect to="/loginGate" />;
          }} exact />
          <Route path="/gigApplicants" render={(props) => {
            return makeScreen('gigApplicants', props.location.state, true, true);
          }} />
          <Route path="/loginGate" render={(props) => {
            return makeScreen('loginGate', props.location.state, true, true);
          }} />
          <Route render={(props) => makeScreen('gigApplicants', props.location.state, true, true)} />
        </Switch>
      </div>
    );
  }
}
export default withRouter(App)
